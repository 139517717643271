import * as React from 'react';
import { CardContainer } from './ExpenseCard.styled';
import { IExpenseCardModel } from '../Models/IExpenseCardModel';
import { connect } from 'react-redux';
import {
    updateMyRequest,
    requestMyDetails,
    submitExpenseDetails,
    submitExpenseDetailsFailed,
} from '../../ExpenseDetails/Details.actions';
import { updatePanelState } from '../../../Shared/Store/Expense.actions';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { IReduxContext } from '@micro-frontend-react/redux/lib/IReduxContext';
import { detailsReducerName, detailsReducer, detailsInitialState } from '../../ExpenseDetails/Details.reducer';
import { detailsSagas } from '../../ExpenseDetails/Details.sagas';
import * as dayjs from 'dayjs';
import { mapExpenseStatusIcon } from '../../../Helpers/sharedHelpers';
import { IDetailsAppState, IDetailsState } from '../../ExpenseDetails/Details.types';
import { ExpenseCard } from './ExpenseCard';

type IExpenseCardProps = {
    expenseData: any;
    updateMyRequest: any;
    updatePanelState: any;
    requestMyDetails: any;
    expenseReportId: any;
    submitExpenseDetails: any;
    submitExpenseDetailsFailed: any;
    stateCommonProperties: any;
    componentContext: any;
};

type IExpenseCardState = {};

class ExpenseCards extends React.Component<IExpenseCardProps, IExpenseCardState> {
    public static contextType: React.Context<IReduxContext> = Context;
    public context!: React.ContextType<typeof Context>;

    constructor(props: IExpenseCardProps) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        const { reducerRegistry, runSaga } = this.context;

        if (!reducerRegistry.exists(detailsReducerName)) {
            reducerRegistry.registerDynamic(detailsReducerName, detailsReducer);
            runSaga(detailsSagas);
        }
    }

    public render(): JSX.Element {
        const summaryList = this._mapSummarytoCard(this.props.expenseData);

        return (
            <CardContainer>
                {summaryList &&
                    summaryList.map((item, index) => (
                        <ExpenseCard
                            key={index}
                            cardInfo={item}
                        />
                    ))}
            </CardContainer>
        );
    }

    private _mapSummarytoCard(expenseData: any): IExpenseCardModel[] {
        const summaryList = expenseData.map(
            (val: any) =>
            ({
                cardRef: val.reportId, // this is using for id of the div
                SubmitterAlias: val.submitter?.alias,
                SubmitterName: val.submitter?.name,
                ReportName: val.reportName,
                StatusImg: mapExpenseStatusIcon(val.expenseStatus),
                ExpenseStatus: val.expenseStatus.replace(/\w+/g, function (currExpStatus: any) {
                    return currExpStatus[0].toUpperCase() + currExpStatus.slice(1).toLowerCase();
                }),
                UnitValue: val.totalAmount.toString(),
                UnitofMeasure: val.transactionCurrency,
                ReportNumber: val.reportId,
                SubmittedDate: dayjs(val.dateSubmitted).format('MMM DD, YYYY')
            } as IExpenseCardModel)
        );
        return [...summaryList];
    }
}

const mapStateToProps = (state: IDetailsAppState): IDetailsState => {
    if (state.dynamic[detailsReducerName]) {
        return state.dynamic[detailsReducerName];
    } else {
        return detailsInitialState;
    }
};

const connected = withContext(
    connect(mapStateToProps, {
        submitExpenseDetailsFailed,
        submitExpenseDetails,
        updateMyRequest,
        updatePanelState,
        requestMyDetails,
    })(ExpenseCards)
);
export { connected as ExpenseCards };
