import * as React from 'react';
import * as Styled from './DetailsMessageBarsStyling';
import { Stack } from '@fluentui/react/lib/Stack';
import { MessageBar, MessageBarType, Link } from '@fluentui/react';
import { feedbackLink } from '../../SharedConstants';

interface BetaInfoViewBarProps {
    closeAction?: any;
}

const BetaInfoViewBar = ({ closeAction = null }: BetaInfoViewBarProps): JSX.Element => {
    let first = 'To access the old Expense@Microsoft website, please click ';
    return (
        <MessageBar
            messageBarType={MessageBarType.warning}
            isMultiline={true}
            aria-label={'This website is in beta mode'}
            onDismiss={closeAction}
            dismissButtonAriaLabel="Close beta mode banner"
        >
            <Stack tokens={Styled.OtherViewsStackTokensGap}>
                <Stack.Item>
                    <Styled.DetailsMessageBarTitle>This website is in beta mode</Styled.DetailsMessageBarTitle>
                </Stack.Item>
                <Stack.Item>
                    {first}
                    <Link
                        href="https://oneexpense.azurewebsites.net/"
                        aria-label="Click here to access the old Expense at Microsoft website"
                        target='_blank'
                        style={{ color: '#003C94', textDecoration: 'underline' }}

                    >
                        here
                    </Link>
                    . To provide us feedback, please click
                    <Link
                        href={feedbackLink}
                        aria-label="Click here to provide feedback"
                        target='_blank'
                        style={{ color: '#003C94', textDecoration: 'underline' }}
                    >
                        here
                    </Link>
                    .
                </Stack.Item>
            </Stack>
        </MessageBar>
    );
};

export default BetaInfoViewBar;
