import * as React from 'react';
import { Reducer } from 'redux';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import * as Styled from './CardHelpers/CardStyling.styled';
import * as SharedLayout from '../../../../Shared/Styles/SharedLayout.styled';
import * as CardHelpers from './CardHelpers/CardHelpers';
import { DefaultButton, Spinner } from '@fluentui/react';
import {
    receiptBankReducerName,
    receiptBankReducer,
    receiptBankInitialState,
} from '../ReceiptUpload/ReceiptUploadStore/ReceiptUpload.reducer';
import { receiptBankSagas } from '../ReceiptUpload/ReceiptUploadStore/ReceiptUpload.sagas';
import { IReceiptBankState } from '../ReceiptUpload/ReceiptUploadStore/ReceiptUpload.types';
import { updateReceiptUploadPanelState } from '../ReceiptUpload/ReceiptUploadStore/ReceiptUpload.actions';
import { ReceiptUploadPanel } from '../ReceiptUpload/ReceiptUploadPanel/ReceiptUploadPanel';
import { IExpenseAppState } from '../../../../Shared/Store/Expense.types';
import { requestMyProfile } from '../../../../Shared/Store/Expense.actions';
import { expenseReducerName, expenseInitialState } from '../../../../Shared/Store/Expense.reducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export function ReceiptUploadCard(): React.ReactElement {
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { profile, isLoadingProfile } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );
    useDynamicReducer(receiptBankReducerName, receiptBankReducer as Reducer, [receiptBankSagas], false);

    const { isPanelOpen } = useSelector(
        (state: IReceiptBankState) => state.dynamic?.[receiptBankReducerName] || receiptBankInitialState
    );

    React.useEffect(() => {
        if (!profile && !isLoadingProfile) {
            dispatch(requestMyProfile('Dashboard'));
        }
        dispatch(updateReceiptUploadPanelState(false));
    }, [dispatch]);

    const updatePanel = () => {
        const panelState = !isPanelOpen;
        dispatch(updateReceiptUploadPanelState(panelState));
        return;
    };

    React.useEffect(() => {
        const expenseTool = profile ? profile.expenseTool : '';
        if (expenseTool === 'MYEXPENSE') {
            setShowReceiptUpload(true);
        } else {
            setShowReceiptUpload(false);
        }
    }, [profile]);

    const [showReceiptUpload, setShowReceiptUpload] = React.useState(false);

    return (
        <Styled.SmallCard windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
            {CardHelpers.getHeader('Receipt Upload')}
            <SharedLayout.Divider />
            <Styled.CardBody windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
                {isLoadingProfile && (
                    <SharedLayout.SpinnerContainer>
                        <Spinner label="Loading" />
                    </SharedLayout.SpinnerContainer>
                )}
                {!isLoadingProfile && (
                    <Styled.CountBanner>
                        <Styled.CountRow>
                            <Styled.CountData>
                                {showReceiptUpload && (
                                    <Styled.SemiboldText>Upload a new receipt to MyExpense</Styled.SemiboldText>
                                )}
                                {!showReceiptUpload && (
                                    <Styled.SemiboldText>
                                        Receipt upload is only available for MyExpense users
                                    </Styled.SemiboldText>
                                )}
                            </Styled.CountData>
                            <Styled.CountImage imageUrl="/images/upload-receipt.svg" />
                        </Styled.CountRow>
                    </Styled.CountBanner>
                )}
                <Styled.CardFooter>
                    {!isLoadingProfile && (
                        <DefaultButton
                            title="Upload new receipt"
                            text="Upload new receipt"
                            onClick={updatePanel}
                            disabled={!showReceiptUpload}
                        />
                    )}
                </Styled.CardFooter>
            </Styled.CardBody>
            <ReceiptUploadPanel />
        </Styled.SmallCard>
    );
}
