export const MyExpenseLinks = [
    {
        text: "Creating a new Expense Report",
        link: "https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EY_VVo5PWVVCrRQj0cs1hbQBndIEXVwsMj7eh6_HJEslaw?e=gwUvV8",
        target: "_blank"
    },
    {
        text: "Adding expense submitter and approver delegates",
        link: "https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ET0-LlSiJxdPveFNNKkxXE8BgREnCw1M5Qz5gXX7O6-ZIg?e=AuUQqB",
        target: "_blank"
    },
    {
        text: "Adding an interim approver",
        link: "https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EWDYtqTR2FxLo6LUrqWfWakBJRqnfJJIsy_qGj7uE7nsNg?e=8pcv2i",
        target: "_blank"
    },
    {
        text: "Changing a final approver",
        link: "https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ETfZD4_FEZ5CmeECSuzGwZwBQ3SWmG6n5yRdnxwhafeYOQ?e=i2pvaF",
        target: "_blank"
    },
    {
        text: "Changing an IO or Cost Center",
        link: "https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EW01NBJ2N4RFsFGRRBXxoi0BK3kYniaAaa7U_H2hIo_HYQ?e=5yzXCO",
        target: "_blank"
    }
]