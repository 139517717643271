import * as React from 'react';
import { Reducer } from 'redux';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { DefaultButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import * as ReceiptStyling from './ReceiptPage.styled';
import { updateReceiptUploadPanelState } from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.actions';
import { ReceiptUploadPanel } from '../MainDashboard/ReceiptUpload/ReceiptUploadPanel/ReceiptUploadPanel';
import { IReceiptBankState } from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.types';
import {
    receiptBankReducerName,
    receiptBankReducer,
    receiptBankInitialState
} from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.reducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { receiptBankSagas } from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.sagas';
import { updateShowSecondaryHeader } from '../../../Shared/Store/Expense.actions';

function ReceiptPage(): React.ReactElement {
    usePageTitle(`Receipt Bank - ${__APP_NAME__}`);
 
    useDynamicReducer(receiptBankReducerName, receiptBankReducer as Reducer, [receiptBankSagas], false);

    const { useSelector, telemetryClient: telemetryHook, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const {
        isPanelOpen,
    } = useSelector(
        (state: IReceiptBankState) => state.dynamic?.[receiptBankReducerName] || receiptBankInitialState
    );

    React.useEffect(() => {
        dispatch(updateReceiptUploadPanelState(false));
        dispatch(updateShowSecondaryHeader(false));
    }, []);

    const updatePanel = () => {
        const panelState = !isPanelOpen;
        dispatch(updateReceiptUploadPanelState(panelState));
        return;
    }

    return (
        <ReceiptStyling.Container>
           <Stack>
                <Stack.Item>
                    <DefaultButton
                        text={'Open Receipt Upload Panel'}
                        onClick={updatePanel}
                    />
                    {/* <ReceiptTable/> */}
                    <ReceiptUploadPanel/>
                </Stack.Item>
            </Stack> 
        </ReceiptStyling.Container>
        
    );
}

const connected = withContext(ReceiptPage);
export { connected as ReceiptPage };