export const navConfig = [
    {
        key: 'Main',
        links: [
            {
                key: 1,
                name: 'Home',
                href: '/',
                icon: 'Home',
            }
        ],
    },
];

export const authorizedConfig = [
    {
        key: 'Main',
        links: [
            {
                key: 'Home',
                name: 'Home',
                href: '/',
                icon: 'Home',
                ariaLabel: 'Home',
                isSelected:
                    window.location.href ===
                    window.location.protocol + 
                    '//' + 
                    window.location.host
                    ? true
                    : false
            },
            {
                key: 'Expenses',
                name: 'Expenses',
                href: '/ExpenseDashboard',
                icon: 'customExpenseDashboard',
                ariaLabel: 'Expenses',
                isSelected:
                    window.location.href ===
                        window.location.protocol +
                        '//' +
                        window.location.host +
                        '/ExpenseDashboard'
                        ? true
                        : false
            },
            {
                key: 'Training',
                name: 'Training',
                icon: 'customTraining',
                href: '/Training',
                ariaLabel: 'Training',
            },
            {
                key: 'ManageDelegates',
                name: 'Manage Delegates',
                icon: 'EditContact',
                href: '/ManageDelegates',
                ariaLabel: 'Manage Delegates',
            },
        ],
    },
];
