import { IAuthClient } from '@micro-frontend-react/employee-experience/lib/IAuthClient';
import { ITelemetryClient } from '@micro-frontend-react/employee-experience/lib/ITelemetryClient';

export enum TrackingEventId {
    LoadExpenseDataSuccess = 110000, //--
    LoadExpenseDataFailure, //--
    LoadDraftExpenseSuccess,
    LoadDraftExpenseFailure,
    LoadUserProfileFromGraphSuccess, //--
    LoadUserProfileFromGraphFailure, //--
    ExpenseDetailsLoadInitiate,
    LoadExpenseDetailsSuccess, //--
    LoadExpenseDetailsFailure, //--
    LoadExpenseReceiptDocumentSuccess, //--
    LoadExpenseReceiptDocumentFailure, //--
    ExpenseDraftSubmitInitiateFromSummary,
    ExpenseDraftSubmitInitiate, //-
    SubmitDraftExpenseSuccess, //-
    SubmitDraftExpenseFailure, //-
    PreviewAttachments,
    DownloadReceipt,
    CloseAttachmentPreview,
    ExpenseDraftEditView,
    ExpenseCardClicked,
    CloseDetailCard,
    CancelAutomatedExpense,

    AllLoadExpenseReceiptDownloadSuccess,
    AllLoadExpenseReceiptDownloadFailure,
    LoadExpenseReceiptDownloadSuccess,
    LoadExpenseReceiptDownloadFailure,
    AttachmentPreviewLoadSuccess,
    AttachmentPreviewLoadFailure,
    DetailsLoadInitiate,
    DetailsLoadSuccess,
    DetailsLoadFailure,
    HeaderLoadSuccess,
    HeaderLoadFailure,
    LoadUserImageSuccess,
    LoadUserImageFailure,
    DownloadAttachment,
    DownloadAllAttachments,
    GroupBySubmitter,
    GroupByDate,
    SummaryCardClicked,
    RenderPrimaryActionButtonsFailure,
    RenderSecondaryActionButtonsFailure,
    LoadUsersFromMicrosoftGraphSuccess,
    LoadUsersFromMicrosoftGraphFailure,

    LoadStatFormSuccess,
    LoadStatFormFailure,

    SaveUserPreferenceSuccess,
    SaveUserPreferenceFailure,
    GetUserPreferenceSuccess,
    GetUserPreferenceFailure,
    GetDelegatesListSuccess,
    GetDelegatesListFailure,
    ModifyDelegateSuccess,
    ModifyDelegateFailure,
    ParseActionErrorResponseFailure,

    LoadExpenseStatisticsSuccess,
    LoadExpenseStatisticsFailure,

    ReceiptUploadSuccess,
    ReceiptUploadFailure,
    ReceiptUploadConvertToBase64Failure,
}

export const getContextCommonTelemetryProperties = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    appAction: string,
    eventName: string,
    eventId: number
): any => {
    const occurenceTime = new Date();
    const correlationId = telemetryClient.getCorrelationId();
    const logData = {
        AppAction: appAction,
        EventOccurenceTime: occurenceTime,
        SessionId: correlationId,
        EventId: eventId,
        EventName: eventName,
        ComponentType: 'Web',
    };
    return logData;
};

export const trackBusinessProcessEvent = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    businessProcessName: string,
    appAction: string,
    eventId: number,
    stateCommonProperties: any,
    additionalProperties: any = {},
    featureName?: string
): void => {
    featureName = featureName ? featureName + ' - ' : stateCommonProperties?.ExpenseReportId ? 'Report Details - ' : '';
    const businessProcessFullName = 'OneExpense Web Portal - ' + featureName + businessProcessName;
    try {
        const businessProcessProperties = {
            BusinessProcessName: businessProcessFullName,
            EventType: 'BusinessProcessEvent',
        };
        const contextCommonProperties = getContextCommonTelemetryProperties(
            authClient,
            telemetryClient,
            appAction,
            businessProcessFullName,
            eventId
        );
        const telemetryProperties = Object.assign(
            businessProcessProperties,
            stateCommonProperties,
            contextCommonProperties,
            additionalProperties
        );
        telemetryClient.trackEvent({ name: businessProcessFullName }, telemetryProperties);
    } catch (error) {}
};

export const trackFeatureUsageEvent = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    eventName: string,
    appAction: string,
    eventId: number,
    stateCommonProperties: any,
    additionalProperties: any = {}
): void => {
    try {
        const featureUsageProperties = {
            EventType: 'FeatureUsageEvent',
        };
        const contextCommonProperties = getContextCommonTelemetryProperties(
            authClient,
            telemetryClient,
            appAction,
            eventName,
            eventId
        );
        const telemetryProperties = Object.assign(
            featureUsageProperties,
            stateCommonProperties,
            contextCommonProperties,
            additionalProperties
        );
        telemetryClient.trackEvent({ name: eventName }, telemetryProperties);
    } catch (error) {}
};

export const trackException = (
    authClient: IAuthClient,
    telemetryClient: ITelemetryClient,
    eventName: string,
    appAction: string,
    eventId: number,
    stateCommonProperties: any,
    exception: Error,
    additionalProperties: any = {}
): void => {
    try {
        const contextCommonProperties = getContextCommonTelemetryProperties(
            authClient,
            telemetryClient,
            appAction,
            eventName,
            eventId
        );
        const telemetryProperties = Object.assign(stateCommonProperties, contextCommonProperties, additionalProperties);
        telemetryClient.trackException({ exception: exception, properties: telemetryProperties });
    } catch (error) {}
};
