import * as React from 'react';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import { Armenia } from './Countries/Armenia';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { requestStatFormExpenseDetails } from '../ExpenseDetails/Details.actions';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

const stackTokens: IStackTokens = { childrenGap: 20, padding: 20 };

const countries: IDropdownOption[] = [
    { key: 'Armenia', text: 'Armenia' },
    { key: 'Azerbaijan', text: 'Azerbaijan' },
    { key: 'Belarus', text: 'Belarus' },
    { key: 'Bosnia', text: 'Bosnia' },
    { key: 'Kazakhstan', text: 'Kazakhstan' },
    { key: 'Russia', text: 'Russia' },
    { key: 'Serbia', text: 'Serbia' },
    { key: 'Ukraine', text: 'Ukraine' },
];

export function StatFormsPage(): React.ReactElement {
    usePageTitle(`Stat Forms - ${__APP_NAME__}`);

    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [expenseReportId, setExpenseReportId] = React.useState(null);

    const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    function submitStatForm() {
        dispatch(requestStatFormExpenseDetails(expenseReportId, selectedCountry));

        // const ArmeniaCountry = document.getElementById('ArmeniaCountry');

        // var newWindow = window.open();
        // newWindow.document.write(ArmeniaCountry.innerHTML);
    }

    return (
        <div>
            <Armenia></Armenia>

            <Stack tokens={stackTokens} style={{ width: '50%' }}>
                <Stack.Item>
                    <h3>Print the statutory form for the CEE countries from here!!!</h3>
                </Stack.Item>
                <Stack.Item>
                    <Dropdown
                        placeholder="Select a country"
                        label="Select a country"
                        options={countries}
                        onChange={(event, value) => {
                            console.log('value>>', value);
                            setSelectedCountry(value.key);
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <TextField
                        label="Report Number"
                        onChange={(event, value) => {
                            setExpenseReportId(value);
                        }}
                    />
                </Stack.Item>
                <Stack.Item>
                    <PrimaryButton text="Submit" onClick={submitStatForm} />
                </Stack.Item>
            </Stack>
        </div>
    );
}
