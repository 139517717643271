import { Context } from '@micro-frontend-react/core/lib/Context';
import * as React from 'react';
import { detailsInitialState, detailsReducerName } from '../../ExpenseDetails/Details.reducer';
import { IDetailsAppState } from '../../ExpenseDetails/Details.types';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export function Armenia(): React.ReactElement {

    const { useSelector, telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const { statFormReportCountry, statFormReportData } = useSelector(
        (state: IDetailsAppState) => state.dynamic?.[detailsReducerName] || detailsInitialState
    );

    React.useEffect(() => {
        if (statFormReportData) {
            const ArmeniaCountry = document.getElementById('ArmeniaCountry');

            var newWindow = window.open();
            newWindow.document.write(ArmeniaCountry.innerHTML);
        }
    }, [statFormReportCountry, statFormReportData]);

    return (
        <div id='ArmeniaCountry' style={{ display: 'none' }}>
            <table border={0} cellPadding={0} cellSpacing={0} width={640} style={{ borderCollapse: 'collapse', tableLayout: 'fixed', width: '480pt', marginLeft: 'auto', marginRight: 'auto' }}>
                <colgroup><col className="colBYstyle" width={64} span={10} style={{ width: '48pt' }} />
                </colgroup><tbody><tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                    <td height={20} className="xl66" colSpan={9} width={576} style={{ height: '15.0pt', msoIgnore: 'colspan', width: '432pt' }}>
                        Иностранное
                        общество с
                        ограниченной
                        ответственностью
                        "Майкрософт
                        Софтвер Бел"
      </td>
                    <td className="xl67" width={64} style={{ width: '48pt' }} />
                </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl68" colSpan={3} style={{ height: '15.0pt', msoIgnore: 'colspan' }}>
                            Подразделение:
        <strong>@Model.EmployeeDepartment</strong>
                        </td>
                        <td className="xl69" />
                        <td className="xl69" />
                        <td className="xl69" colSpan={2} style={{ msoIgnore: 'colspan' }}>
                            Должность:
                            @Model.EmployeeTitle
      </td>
                        <td className="xl69" />
                        <td className="xl69" />
                        <td className="xl69" />
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl69" style={{ height: '15.0pt' }}>
                            Фамилия,
        и,<span style={{ display: 'none' }}>о.</span>
                        </td>
                        <td colSpan={4} className="xl70"><strong>@Model.EmployeeName</strong></td>
                        <td className="xl71">&nbsp;</td>
                        <td className="xl71">&nbsp;</td>
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td colSpan={7} height={20} className="xl72" style={{ height: '15.0pt' }}>
                            АВАНСОВЫЙ
                            ОТЧЕТ №&nbsp;@Model.ReportNumber от @Model.SubmittedDate
      </td>
                        <td className="xl73">
                            Проводка <span style={{ display: 'none' }}>№</span>
                        </td>
                        <td className="xl71"> @Model.ReportNumber&nbsp;</td>
                        <td className="xl71">&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" style={{ height: '15.0pt' }}>
                            Назначение
        <span style={{ display: 'none' }}>аванса</span>
                        </td>
                        <td className="xl70" colSpan={5} style={{ msoIgnore: 'colspan' }}>@Model.ReportName</td>
                        <td className="xl71">&nbsp;</td>
                        <td className="xl74">&nbsp;</td>
                        <td className="xl67" />
                        <td className="xl67" />
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" style={{ height: '15.0pt' }} />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl75">от</td>
                        <td className="xl71">@Model.SubmittedDate</td>
                        <td className="xl71">&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" style={{ height: '15.0pt' }} />
                        <td className="xl67" />
                        <td colSpan={2} className="xl76">Сумма</td>
                        <td colSpan={3} className="xl77" style={{ borderLeft: 'none' }}>
                            Отчет
                            проверен
      </td>
                        <td colSpan={3} className="xl78" align="center" style={{ msoIgnore: 'colspan' }}>Дебет</td>
                    </tr>
                    <tr className="trBYstyle" height={21} style={{ height: '15.75pt' }}>
                        <td height={21} className="xl80" style={{ height: '15.75pt' }}>Остаток</td>
                        <td className="xl67" />
                        <td colSpan={2} rowSpan={2} className="xl76" />
                        <td colSpan={2} className="xl81" width={128} style={{ borderLeft: 'none', width: '96pt' }}>Раздел_____$_____ст._____</td>
                        <td className="xl82">&nbsp;</td>
                        <td className="xl83" style={{ borderTop: 'none' }}>Счет</td>
                        <td className="xl84" style={{ borderTop: 'none', borderLeft: 'none' }}>карт.</td>
                        <td className="xl84" style={{ borderTop: 'none', borderLeft: 'none' }}>сумма</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" colSpan={2} style={{ height: '15.0pt', msoIgnore: 'colspan' }}>Перерасход</td>
                        <td className="xl85" style={{ borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl86" colSpan={2} style={{ msoIgnore: 'colspan', borderRight: '.5pt solid black' }}>
                            К
                            утверждению:
      </td>
                        <td className="xl87" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl89" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" colSpan={2} style={{ height: '15.0pt', msoIgnore: 'colspan' }}>@Model.CashAdvAmount):</td>
                        <td colSpan={2} rowSpan={2} className="xl76">@Model.CashAdvAmount</td>
                        <td className="xl85" colSpan={2} style={{ msoIgnore: 'colspan' }}>&nbsp;</td>
                        <td className="xl82">&nbsp;</td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl89" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td colSpan={2} height={20} className="xl91" width={128} style={{ height: '15.0pt', width: '96pt' }}>
                            1.
                            карт-счет 10.04.2015
      </td>
                        <td className="xl92" style={{ borderLeft: 'none' }}>Бухгалтер</td>
                        <td className="xl67" />
                        <td className="xl82">&nbsp;</td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl93" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td colSpan={2} rowSpan={2} height={40} className="xl94" width={128} style={{ height: '30.0pt', width: '96pt' }}>
                            2.<span style={{ msoSpacerun: 'yes' }}>&nbsp;</span>
                        </td>
                        <td colSpan={2} rowSpan={2} className="xl76">TotalCardSpendings</td>
                        <td className="xl92" colSpan={3} style={{ msoIgnore: 'colspan', borderRight: '.5pt solid black' }}>
                            "___"
                            ____________ 2015 г.
      </td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl93" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl95" style={{ height: '15.0pt', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl71">&nbsp;</td>
                        <td className="xl96">&nbsp;</td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl93" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td colSpan={2} rowSpan={2} height={40} className="xl69" style={{ height: '30.0pt' }}>
                            Итого
                            получено:
      </td>
                        <td colSpan={2} rowSpan={2} className="xl97">@Model.TotalReceived</td>
                        <td className="xl98" colSpan={3} style={{ msoIgnore: 'colspan', borderRight: '.5pt solid black' }}>
                            Отчет
                            утверждаю в
                            сумме:
      </td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl93" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl101" style={{ height: '15.0pt', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl67" />
                        <td className="xl102">&nbsp;</td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl93" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td colSpan={2} rowSpan={2} height={40} className="xl69" style={{ height: '30.0pt' }}>Израсходовано:</td>
                        <td colSpan={2} rowSpan={2} className="xl76">
                        </td>
                        <td colSpan={3} rowSpan={4} className="xl103" width={192} style={{ width: '144pt' }}>
                            @Model.TotalAmountInWords
      </td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl93" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} colSpan={3} className="xl104" align="center" style={{ height: '15.0pt', msoIgnore: 'colspan', borderRight: '.5pt solid black' }}>
                            Кредит
      </td>
                    </tr>
                    <tr className="trBYstyle" height={21} style={{ height: '15.75pt' }}>
                        <td height={21} className="xl80" style={{ height: '15.75pt' }}>Остаток</td>
                        <td className="xl67" />
                        <td colSpan={2} rowSpan={2} className="xl106">&nbsp;</td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl93" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" colSpan={2} style={{ height: '15.0pt', msoIgnore: 'colspan' }}>Перерасход</td>
                        <td className="xl90" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td className="xl88" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                        <td className="xl89" style={{ borderTop: 'none', borderLeft: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" style={{ height: '15.0pt' }} />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl107">Подпись</td>
                        <td className="xl67" />
                        <td className="xl67" />
                        <td colSpan={3} rowSpan={3} className="xl108">Бухгалтер</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" colSpan={3} style={{ height: '15.0pt', msoIgnore: 'colspan' }}>
                            Приложение
                            10 документов
      </td>
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                    </tr>
                    <tr className="trBYstyle" height={21} style={{ height: '15.75pt' }}>
                        <td height={21} className="xl67" style={{ height: '15.75pt' }} />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" colSpan={3} style={{ msoIgnore: 'colspan' }}>
                            "___"
                            __________ 2015 г.
      </td>
                    </tr>
                    <tr className="trBYstyle" height={21} style={{ height: '15.75pt' }}>
                        <td rowSpan={2} height={42} className="xl109" style={{ height: '31.5pt' }}>Дата</td>
                        <td rowSpan={2} className="xl109">Пор. №</td>
                        <td colSpan={5} rowSpan={2} className="xl109">
                            Кому,
                            за что и по
                            какому
                            документу
                            уплачено
      </td>
                        <td rowSpan={2} className="xl109">Сумма</td>
                        <td colSpan={2} className="xl110" style={{ borderLeft: 'none' }}>Дебет</td>
                    </tr>
                    <tr className="trBYstyle" height={21} style={{ height: '15.75pt' }}>
                        <td height={21} className="xl111" style={{ height: '15.75pt' }}>счет</td>
                        <td className="xl111" style={{ borderLeft: 'none' }}>карт</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td rowSpan={2} height={40} className="xl76" style={{ height: '30.0pt' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl112">1</td>
                        <td colSpan={5} rowSpan={2} className="xl113" width={320} style={{ width: '240pt' }}>Суточные</td>
                        <td rowSpan={2} className="xl114">@Model.PerDeimExpenseAmount.ToString("#,##0.00") @Model.Currency</td>
                        <td rowSpan={2} className="xl112" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl88" style={{ borderTop: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }} />
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td rowSpan={2} height={40} className="xl76" style={{ height: '30.0pt', borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl112" style={{ borderTop: 'none' }}>2</td>
                        <td colSpan={5} rowSpan={2} className="xl113" width={320} style={{ width: '240pt' }}>
                            Проживание
                            по норме,
                            Счет б/н от 18.04.2015
                            г.
      </td>
                        <td rowSpan={2} className="xl114" style={{ borderTop: 'none' }}>@Model.AccomdationAmount @Model.Currency</td>
                        <td rowSpan={2} className="xl112" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl88" style={{ borderTop: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }} />
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td rowSpan={2} height={40} className="xl76" style={{ height: '30.0pt', borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl112" style={{ borderTop: 'none' }}>3</td>
                        <td colSpan={5} rowSpan={2} className="xl113" width={320} style={{ width: '240pt' }}>
                            Проживание
                            сверх нормы,
                            Счет б/н от 18.04.2015
                            г.
      </td>
                        <td rowSpan={2} className="xl115" style={{ borderTop: 'none' }}>@Model.HotelAccomdationExpenseAmount.ToString("#,##0.00") @Model.Currency</td>
                        <td rowSpan={2} className="xl76" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl88" style={{ borderTop: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }} />
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td rowSpan={2} height={40} className="xl76" style={{ height: '30.0pt', borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl112" style={{ borderTop: 'none' }}>4</td>
                        <td colSpan={5} rowSpan={2} className="xl113" width={320} style={{ width: '240pt' }}>
                            Расходы
                            на такси,
                            Квитанция 0872/16361
                            от 12.04.2015 г.
      </td>
                        <td rowSpan={2} className="xl116" style={{ borderTop: 'none' }}>@Model.ChargesForTaxi @Model.Currency</td>
                        <td rowSpan={2} className="xl76" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl88" style={{ borderTop: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }} />
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td rowSpan={2} height={40} className="xl76" style={{ height: '30.0pt', borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl112" style={{ borderTop: 'none' }}>5</td>
                        <td colSpan={5} rowSpan={2} className="xl113" width={320} style={{ width: '240pt' }}>
                            Расходы
                            на такси,
                            Квитанция 000003
                            от 18.04.2015 г.
      </td>
                        <td rowSpan={2} className="xl115" style={{ borderTop: 'none' }}>@Model.TravelExpenseAmount @Model.Currency</td>
                        <td rowSpan={2} className="xl76" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl88" style={{ borderTop: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }} />
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td rowSpan={2} height={41} className="xl76" style={{ height: '30.75pt', borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl112" style={{ borderTop: 'none' }}>6</td>
                        <td colSpan={5} rowSpan={2} className="xl113" width={320} style={{ width: '240pt' }}>
                            Расходы
                            на такси,
                            Квитанция 000003
                            от 18.04.2015 г.
      </td>
                        <td rowSpan={2} className="xl115" style={{ borderTop: 'none' }}>@Model.TelecommunicationsExpenseAmount.ToString("#,##0.00")  @Model.Currency</td>
                        <td rowSpan={2} className="xl76" style={{ borderTop: 'none' }}>&nbsp;</td>
                        <td rowSpan={2} className="xl88" style={{ borderTop: 'none' }}>&nbsp;</td>
                    </tr>
                    <tr className="trBYstyle" height={21} style={{ height: '15.75pt' }} />
                    <tr className="trBYstyle" height={21} style={{ height: '15.75pt' }}>
                        <td colSpan={7} height={21} className="xl117" style={{ height: '15.75pt' }}>Итого</td>
                        <td className="xl118" align="center" width={64} style={{ borderLeft: 'none', width: '48pt' }} />
                        <td colSpan={2} className="xl119" width={128} style={{ borderLeft: 'none', width: '96pt' }}>@Model.TotalExpenseReportAmount.ToString("#,##0.00") @Model.Currency</td>
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" style={{ height: '15.0pt' }} />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl120">&nbsp;</td>
                        <td className="xl67" />
                        <td className="xl67" />
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" style={{ height: '15.0pt' }} />
                        <td className="xl67" />
                        <td className="xl71">&nbsp;</td>
                        <td className="xl71">&nbsp;</td>
                        <td className="xl71">&nbsp;</td>
                        <td className="xl71">&nbsp;</td>
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                    </tr>
                    <tr className="trBYstyle" height={20} style={{ height: '15.0pt' }}>
                        <td height={20} className="xl67" style={{ height: '15.0pt' }} />
                        <td className="xl67" />
                        <td colSpan={4} className="xl121">
                            Подпись
                            подотчетного
                            лица
      </td>
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                        <td className="xl67" />
                    </tr>
                    <tr className="trBYstyle" height={0} style={{ display: 'none' }}>
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                        <td width={64} style={{ width: '48pt' }} />
                    </tr>
                </tbody></table>

        </div>
    );
}
