export const ExpensePageMainContainer = styled.div<{winHeight: any}>`
    height: ${(props) => props.winHeight - 130}px;
    overflow-y: auto;
    overflow-x: hidden; 
`;

export const DetailCardContainer = styled.div<{winHeight: any}>`
    height: ${(props) => props.winHeight - 130}px;
    overflow-y: auto;
    background: #ffffff;
`;