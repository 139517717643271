import { IFAQList } from '../TrainingPage';

export const GettingStarted: Array<IFAQList> = [
    {
        title: 'Concur Overview and FAQ',
        text: 'Logging into Concur, setting up user preferences, activating user emails, Concur FAQs',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-21fb-f1ed7ce74fff?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:b:/t/concurTraining/EV6gjn2SDL9LvlImKlm-1LgBY6fvewzpi4wOulNQYP_V1w?e=1iw5yj',
    },
    {
        title: 'Around Concur Expense',
        text: 'Get accurate and on-time reports with expense',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-37f8-f1ed7ce82db2?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
]; 

export const Delegation: Array<IFAQList> = [
    {
        title: 'Delegation',
        text: 'Setting up expense delegates, delegate expense creation',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-37f5-f1ed7ce82db2?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'http://www.concurtraining.com/tip-sheet/ts_acting_as_a_delegate',
    }
]; 

export const ExpenseCreation: Array<IFAQList> = [
    {
        title: 'Expense Creation',
        text: 'Creating an expense report',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-34eb-f1ed7ce72a63?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ReconcilingExpensesFTE: Array<IFAQList> = [
    {
        title: 'Reconciling Expenses General',
        text: 
            'Topics include: reconciling Amex credit card transactions, reconciling out-of-pocket/cash transactions, ' +
            'receipt management (Uploading, Attaching etc. at the line level), ' + 
            'reconciling project expenses, ' + 
            'reconciling non-project expenses, ' + 
            'itemization of hotel expenses, ' + 
            'reconciling personal expense, ' + 
            'managing guests/attendee details',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-03b2-f1ed7ce764e2?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    },
    {
        title: 'Changing Fx Rate',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'http://www.concurtraining.com/tip-sheet/ts_converting_foreign_currency',
    },
    {
        title: 'Bulk Edit Expense Transactions in Concur',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EcdouT6ooKBPrIaP6WEHl30B9NfdK-AvcBXSWveGZXyBXg?e=uZvZKQ',
    },
    {
        title: 'Delegate Expense Report Creation and Previewer Functionality',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EWXekXUbL8dMq345Iy7sEr8ByrO9DKBFVtPuKxNCBgjHGw?e=NUdsrc',
    },
    {
        title: 'Deleting Expense Lines, Deleting Expense Reports',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EcBYWkwLltBBqJ5RllZEiRMBPZpjdL2teNX4sar0XtPRsA?e=DTBIcq',
    },
    {
        title: '​Itemizing Hotel Expenses With Different Rates',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EcpiRfZRp3VAozgjsRLfxBMBU7vc6UBsPhq5M_UtKfns1A?e=2WMTER',
    },
    {
        title: '​Printing Receipts Cover Page',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/ERp2B4bTT6ZJgFG7yijyR10Bgl_xqiB0ZVgl6eyBgPNdLQ?e=hnI4qN',
    },
    {
        title: '​Reconciling Mileage Expense',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EaiMdWSQLchLkimiy0uru8gBfG_oEgKacb34QAj488E5DA?e=4OTUdp',
    },
    {
        title: 'Reconciling Per Diem',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EXHnrCnSQytJjJe7QcYynUwBExE_9KxoP3BIcw7fPzQBBg?e=kBJfcC',
    },
    {
        title: 'Splitting Credit Card Transactions',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/Ecivf1nKhKVBkKq4WV79LIQBEZtLBp0TaZO3bE2UAvyl0A?e=qH3Paj',
    },
    {
        title: '​Viewing Approved Expense Report History on MS Invoice',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/ESFpOjoxJt5KnMFfsVdcnPgB-OJq-dBng2g-ALa3yvHLIw?e=1SeskR',
    },
    {
        title: '​Viewing Historical Expense Reports on MSE 2.0',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EVCp8-s9l6lLr7dIbVfgVwIB8YFT6Bp39-1PHBTI5f9UlQ?e=pesVKi',
    },
    {
        title: '​Mapping of MS Expense Categories to Concur Expense Types',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:x:/t/concurTraining/EdJ3Yz-0kphAhhNiLx0HjiQBfcVrzkMVZ5pQyGszq0hudQ?e=I3mEz7',
    },
    {
        title: 'Missing Receipt Affidavit',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/Ec-H4aT_KL5BuqVhTKVFtpQBDBXuF2SBEASJUOkupNeWmQ?e=LXRz97',
    },
    {
        title: '​​Expense Digital Transformation',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/ETeKItAeNUxAib-3DhUEkrIBwQ7yJ_U-9tX3HmXBHMX7wQ?e=6lb9hd',
    },
    {
        title: 'New Expense Categories in Concur',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EQ9vjYTGDXhCsSVXQ903_xwBpNQQ9lyn5gNxJe7dpc1k_A?e=P2qFsx',
    },
    {
        title: 'Expense Reconciliation in Concur Turkey',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/ETPUOYz90BlElyknMU-Kd7wB6qZ7zRwSwvhGz7RmhEZMPw?e=j6IvBc',
    },
    {
        title: 'Logging Airfare booked on Business Travel Account',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/ES9j2SKwzGtMrndHEA9bXCYBbaA6H2k05Tq6GqHrbbKioQ?e=AD5FN7',
    },
    {
        title: 'TAR Expenses',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EU0mMSAd89RPgqzPBDaPGAcBElDHdBTcJC_-KKqp7d4Thw?e=78bcRC',
    },
    {
        title: 'MGR Expense Only Category',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: 'https://microsoft.sharepoint.com/:b:/t/concurTraining/EWh-bD5osnlJg1B8OrRoN9sB3i0aUD2b6oknQhWwEOSPRg?e=PlPgs5',
    },

];

export const ReconcilingExpensesSubcontractors: Array<IFAQList> = [
    {
        title: 'Reconciling Expenses - Subcontractors',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-37f9-f1ed7ce82db2?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ManagingReceipts: Array<IFAQList> = [
    {
        title: 'Managing Receipts',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-3903-f1ed7ce78751?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ExpenseReportSubmission: Array<IFAQList> = [
    {
        title: 'Expense Report Submission',
        text: 'Topics include: reviewing expense reports before submission, cover pages for hard copy receipt countries, recalling expense reports',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-06f2-f1ed7ce7ca64?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const SelectingExpenseReportApprover: Array<IFAQList> = [
    {
        title: 'Selecting Expense Report Approver',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-06f1-f1ed7ce7ca64?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ApprovalInConcur: Array<IFAQList> = [
    {
        title: 'Approval in Concur',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-06f0-f1ed7ce7ca64?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ExpenseCreationSubmissionIOS: Array<IFAQList> = [
    {
        title: 'Getting Started with Concur Mobile',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a7-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    },
    {
        title: 'Creating an Expense',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a3-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    },
    {
        title: 'Adding Attendees to a Business Meal',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a1-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    },
    {
        title: 'Viewing Past Expense Reports',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a5-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ExpenseApprovalIOS: Array<IFAQList> = [
    {
        title: 'Approving an Expense Report',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a0-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ExpenseCreationSubmissionAndroid: Array<IFAQList> = [
    {
        title: 'Getting Started with Concur Mobile',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a4-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    },
    {
        title: 'Creating an Expense',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a2-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    },
    {
        title: 'Adding Attendees to a Business Meal',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-37f4-f1ed7ce82db2?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    },
    {
        title: 'Viewing Past Expense Reports',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-d0a6-f1ed7ce86635?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const ExpenseApprovalAndroid: Array<IFAQList> = [
    {
        title: 'Approving an Expense Report',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/15e30840-98dc-bf78-37f6-f1ed7ce82db2?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const CourierAuroraProjectAllocation: Array<IFAQList> = [
    {
        title: 'Approving an Expense Report',
        text: '',
        videoUrl:
            'https://msit.microsoftstream.com/embed/video/61d10840-98dc-bf78-c451-f1ed7fc4c8dd?autoplay=false&showinfo=false',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl: '',
    }
];

export const TrainingTopics = [
    { heading: 'Getting Started', subtopics: GettingStarted, id: 'ConcurGettingStarted' },
    { heading: 'Delegation', subtopics: Delegation, id: 'Delegation' },
    { heading: 'Expense Creation', subtopics: ExpenseCreation, id: 'ExpenseCreation' },
    { heading: 'Reconciling Expenses - FTE', subtopics: ReconcilingExpensesFTE, id: 'OccasionalScenarios' },
    { heading: 'Reconciling Expenses - Subcontractors', subtopics: ReconcilingExpensesSubcontractors, id: 'ReconcilingExpensesSubcontractors' },
    { heading: 'Managing Receipts', subtopics: ManagingReceipts, id: 'ManagingReceipts' },
    { heading: 'Expense Report Submission', subtopics: ExpenseReportSubmission, id: 'ExpenseReportSubmission' },
    { heading: 'Selecting Expense Report Approver', subtopics: SelectingExpenseReportApprover, id: 'SelectingExpenseReportApprover' },
    { heading: 'Approval in Concur', subtopics: ApprovalInConcur, id: 'ApprovalInConcur' },
    { heading: 'Expense Creation and Submission (iOS/Apple)', subtopics: ExpenseCreationSubmissionIOS, id: 'ExpenseCreationSubmissionIOS' },
    { heading: 'Expense Approval (iOS/Apple)', subtopics: ExpenseApprovalIOS, id: 'ExpenseApprovalIOS' },
    { heading: 'Expense Creation and Submission (Android)', subtopics: ExpenseCreationSubmissionAndroid, id: 'ExpenseCreationSubmissionAndroid' },
    { heading: 'Expense Approval (Android)', subtopics: ExpenseApprovalAndroid, id: 'ExpenseApprovalAndroid' },
    { heading: 'Courier Aurora Project Allocation', subtopics: CourierAuroraProjectAllocation, id: 'CourierAuroraProjectAllocation' },
];