import { SelectionMode, DetailsList, IColumn } from '@fluentui/react/lib/DetailsList';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { expenseReducerName, expenseInitialState, expenseReducer } from '../../../Shared/Store/Expense.reducer';
import { IExpenseAppState, IExpenseDelegateDetails } from '../../../Shared/Store/Expense.types';
import * as React from 'react';
import {
    TooltipHost,
    IDetailsListProps,
    DetailsHeader,
    IconButton,
    Dialog,
    DialogType,
    DialogFooter,
    Text,
    DefaultButton,
} from '@fluentui/react';
import { ManageDelegateModal } from './ManageDelegateModal';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import { modifyDelegate } from '../../../Shared/Store/Expense.actions';
import { OperationType } from './ManageDelegatesConstants';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

interface IManageDelegatesTableColumnsProps {
    delegateType: string;
}

function ManageDelegatesTableColumns(props: IManageDelegatesTableColumnsProps) {
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);

    const { delegatesList } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    const [sortedItems, setSortedItems] = React.useState<IExpenseDelegateDetails[]>(delegatesList);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [selectedDelegate, setSelectedDelegate] = React.useState<IExpenseDelegateDetails>(null);
    const [isUpdateDelegateModalOpen, setUpdateDelegateModalOpen] = React.useState(false);

    const dialogContentProps: { type: number; title: string; subText?: string } = {
        type: DialogType.normal,
        title: 'Delete delegate',
    };

    const createDelegateRequest = (item: IExpenseDelegateDetails) => {
        const submitRequest: IExpenseDelegateDetails = {
            delegateType: props.delegateType,
            delegateAlias: item.delegateAlias,
            userAlias: item.userAlias,
            startDate: item.startDate,
            endDate: item.endDate,
            comments: item.comments,
            recordId: item.recordId,
            companyCode: item.companyCode,
        };
        setSelectedDelegate(submitRequest);
        return submitRequest;
    };

    const deleteRow = (item: IExpenseDelegateDetails) => {
        const submitRequest = createDelegateRequest(item);
        if (!submitRequest?.delegateAlias) {
            dialogContentProps.subText = 'Are you sure you would like to delete this delegate?';
        }
        setShowDeleteDialog(true);
    };

    const editDelegate = (item: IExpenseDelegateDetails) => {
        createDelegateRequest(item);
        setUpdateDelegateModalOpen(true);
    };

    const allColumns: IColumn[] = [
        {
            key: 'delegateName',
            name: 'Delegate Name',
            fieldName: 'delegateName',
            minWidth: 100,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onSort,
        },
        {
            key: 'delegateAlias',
            name: 'Delegate Alias',
            fieldName: 'delegateAlias',
            minWidth: 100,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onSort,
        },
        {
            key: 'startDate',
            name: 'From Date',
            fieldName: 'startDate',
            minWidth: 100,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onSort,
            onRender: (item: IExpenseDelegateDetails) => {
                if (item?.startDate) {
                    return new Date(item.startDate).toDateString();
                }
            },
        },
        {
            key: 'endDate',
            name: 'To Date',
            fieldName: 'endDate',
            minWidth: 100,
            maxWidth: 250,
            isResizable: true,
            onColumnClick: onSort,
            onRender: (item: IExpenseDelegateDetails) => {
                if (item?.endDate) {
                    return new Date(item.endDate).toDateString();
                }
            },
        },
        {
            key: 'companyCode',
            name: 'Company Code',
            fieldName: 'companyCode',
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onColumnClick: onSort,
        },
        {
            key: 'edit',
            name: 'Edit',
            ariaLabel: 'Edit delegate',
            fieldName: 'edit',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IExpenseDelegateDetails) => (
                <TooltipHost content="Edit">
                    <IconButton
                        iconProps={{ iconName: 'Edit' }}
                        ariaLabel="Edit delegate"
                        title="Edit delegate"
                        onClick={(): void => {
                            editDelegate(item);
                        }}
                        styles={{ icon: { fontSize: 18 } }}
                    />
                </TooltipHost>
            ),
        },
        {
            key: 'delete',
            name: 'Delete',
            ariaLabel: 'Delete delegate',
            fieldName: 'delete',
            minWidth: 50,
            maxWidth: 50,
            onRender: (item: IExpenseDelegateDetails) => (
                <TooltipHost content="Delete">
                    <IconButton
                        iconProps={{ iconName: 'Delete' }}
                        ariaLabel="Delete delegate"
                        title="Delete delegate"
                        onClick={(): void => {
                            deleteRow(item);
                        }}
                        styles={{ icon: { fontSize: 18 } }}
                    />
                </TooltipHost>
            ),
        },
    ];
    const [columns, setColumns] = React.useState<IColumn[]>(allColumns);

    function onSort(event: React.MouseEvent<HTMLElement, MouseEvent>, column: IColumn): void {
        const newColumns: IColumn[] = allColumns.slice();
        const currColumn: IColumn = newColumns.filter((currCol) => column.key === currCol.key)[0];
        newColumns.forEach((newCol: IColumn) => {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        const newItems = _copyAndSort(sortedItems, currColumn.fieldName!, currColumn.isSortedDescending);
        setColumns(newColumns);
        setSortedItems(newItems);
    }

    function _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    const onRenderDetailsHeader: IDetailsListProps['onRenderDetailsHeader'] = (props: any) => {
        return (
            <DetailsHeader
                {...props}
                onRenderColumnHeaderTooltip={(tooltipHostProps: any) => <TooltipHost {...tooltipHostProps} />}
            />
        );
    };

    return (
        <div>
            <DetailsList
                ariaLabel="Delegates"
                columns={columns}
                items={sortedItems}
                selectionMode={SelectionMode.none}
                onRenderDetailsHeader={onRenderDetailsHeader as any}
            />
            <Dialog
                hidden={!showDeleteDialog}
                onDismiss={() => setShowDeleteDialog(false)}
                dialogContentProps={dialogContentProps}
            >
                {selectedDelegate?.delegateAlias && (
                    <Text>
                        Are you sure you would like to delete the delegate: <b>{selectedDelegate.delegateAlias}</b>?
                    </Text>
                )}
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            dispatch(modifyDelegate(selectedDelegate, OperationType.delete));
                            setShowDeleteDialog(false);
                        }}
                        text="Delete"
                        title="Delete"
                        ariaLabel="Delete delegate"
                        styles={{ root: { backgroundColor: '#0078D4' }, rootHovered: { backgroundColor: '#0078D4' } }}
                        primary
                    />
                    <DefaultButton
                        onClick={() => setShowDeleteDialog(false)}
                        text="Cancel"
                        title="Cancel"
                        ariaLabel="Cancel"
                    />
                </DialogFooter>
            </Dialog>
            <ManageDelegateModal
                isOpen={isUpdateDelegateModalOpen}
                onDismiss={() => setUpdateDelegateModalOpen(false)}
                delegateToUpdate={selectedDelegate}
                delegateType={props.delegateType}
            />
        </div>
    );
}
const connected = withContext(ManageDelegatesTableColumns);
export { connected as ManageDelegatesTableColumns };
