import { IAttachment } from "./Details.types";

export enum DetailsActionType {
    REQUEST_MY_DETAILS = 'REQUEST_MY_DETAILS',
    RECEIVE_MY_DETAILS = 'RECEIVE_MY_DETAILS',
    FAILED_DETAILS = 'FAILED_DETAILS',
    UPDATE_REQUEST = 'UPDATE_REQUEST',
    REQUEST_DOCUMENT_PREVIEW = 'REQUEST_DOCUMENT_PREVIEW',
    RECEIVE_DOCUMENT_PREVIEW = 'RECEIVE_DOCUMENT_PREVIEW',
    CLEAR_DOCUMENT_PREVIEW = 'CLEAR_DOCUMENT_PREVIEW',
    CLOSE_DOCUMENT_PREVIEW = 'CLOSE_DOCUMENT_PREVIEW',
    TOGGLE_DETAIL_PANEL_WIDTH = 'TOGGLE_DETAIL_PANEL_WIDTH',
    SUBMIT_EXPENSE_DETAILS = 'SUBMIT_EXPENSE_DETAILS',
    SUBMIT_EXPENSE_DETAILS_SUCCESS = 'SUBMIT_EXPENSE_DETAILS_SUCCESS',
    SUBMIT_EXPENSE_DETAILS_FAILED = 'SUBMIT_EXPENSE_DETAILS_FAILED',
    UPDATE_ACTION_TYPE = 'UPDATE_ACTION_TYPE',
    STAT_FORM_REQUEST_EXPENSE_DETAILS = 'STAT_FORM_REQUEST_EXPENSE_DETAILS',
    STAT_FORM_RESPONSE_EXPENSE_DETAILS = 'STAT_FORM_RESPONSE_EXPENSE_DETAILS',
    STAT_FORM_FAILED_EXPENSE_DETAILS = 'STAT_FORM_FAILED_EXPENSE_DETAILS',
    RECEIVE_USER_IMAGE = 'RECEIVE_USER_IMAGE',
    FAILED_USER_IMAGE = 'FAILED_USER_IMAGE',
    REQUEST_DOCUMENT_START = 'REQUEST_DOCUMENT_START',
    REQUEST_DOCUMENT_END = 'REQUEST_DOCUMENT_END',
    REQUEST_DOCUMENT = 'REQUEST_DOCUMENT',
    FAILED_DOCUMENT_PREVIEW = 'FAILED_DOCUMENT_PREVIEW',
    FAILED_DOCUMENT_DOWNLOAD = 'FAILED_DOCUMENT_DOWNLOAD',
    REQUEST_ALL_DOCUMENTS_START = 'REQUEST_ALL_DOCUMENTS_START',
    REQUEST_ALL_DOCUMENTS = 'REQUEST_ALL_DOCUMENTS',
    REQUEST_ALL_DOCUMENTS_END = 'REQUEST_ALL_DOCUMENTS_END',
    FAILED_ALL_DOCUMENT_DOWNLOAD = 'FAILED_ALL_DOCUMENT_DOWNLOAD',
}

export type DetailsAction =
    | IRequestDetailsAction
    | IReceiveDetailsAction
    | IFailedDetailsAction
    | IUpdateDetailsRequest
    | IRequestDocumentPreviewAction
    | IReceiveDocumentPreview
    | IClearDocumentPreview
    | ICloseDocumentPreview
    | IToggleDetailPanelWidth
    | ISubmitExpenseDetails
    | ISubmitExpenseDetailsSuccess
    | ISubmitExpenseDetailsFailed
    | IUpdateActionType
    | IRequestStatFormExpenseDetails
    | IResponseStatFormExpenseDetails
    | IFailedStatFormExpenseDetails
    | IReceiveUserImageAction
    | IFailedUserImageAction
    | IRequestDocumentStart
    | IRequestDocumentEnd
    | IRequestDocumentAction
    | IFailedDocumentPreviewAction
    | IFailedDocumentDownloadAction
    | IRequestAllDocumentsStart
    | IRequestAllDocumentsAction
    | IRequestAllDocumentsEnd
    | IFailedAllDocumentDownload;

export interface IRequestStatFormExpenseDetails {
    type: DetailsActionType.STAT_FORM_REQUEST_EXPENSE_DETAILS;
    expenseReportId: string;
    selectedCountry: string;
}

export interface IResponseStatFormExpenseDetails {
    type: DetailsActionType.STAT_FORM_RESPONSE_EXPENSE_DETAILS;
    data: any;
}

export interface IFailedStatFormExpenseDetails {
    type: DetailsActionType.STAT_FORM_FAILED_EXPENSE_DETAILS;
    message: any;
}

export interface IUpdateActionType {
    type: DetailsActionType.UPDATE_ACTION_TYPE;
    actionType: string;
}

export interface IToggleDetailPanelWidth {
    type: DetailsActionType.TOGGLE_DETAIL_PANEL_WIDTH;
}

export interface IClearDocumentPreview {
    type: DetailsActionType.CLEAR_DOCUMENT_PREVIEW;
}

export interface ICloseDocumentPreview {
    type: DetailsActionType.CLOSE_DOCUMENT_PREVIEW;
}

export interface IRequestDocumentPreviewAction {
    type: DetailsActionType.REQUEST_DOCUMENT_PREVIEW;
    attachmentId: string;
    isModal: boolean;
}

export interface IReceiveDocumentPreview {
    type: DetailsActionType.RECEIVE_DOCUMENT_PREVIEW;
    filePreview: string;
}

export interface IRequestDetailsAction {
    type: DetailsActionType.REQUEST_MY_DETAILS;
    expenseReportId: string;
    expenseStatus: string;
}

export interface IReceiveDetailsAction {
    type: DetailsActionType.RECEIVE_MY_DETAILS;
    adaptiveDetails: any;
}

export interface IFailedDetailsAction {
    type: DetailsActionType.FAILED_DETAILS;
    errorMessage: string;
}

export interface IUpdateDetailsRequest {
    type: DetailsActionType.UPDATE_REQUEST;
    expenseReportId: string;
    actionType: string;
    expenseStatus: string;
}

export interface ISubmitExpenseDetails {
    type: DetailsActionType.SUBMIT_EXPENSE_DETAILS;
    expenseDetails: IExpenseSubmissionRequest;
    isDeleteRequest?: boolean;
}

export interface ISubmitExpenseDetailsSuccess {
    type: DetailsActionType.SUBMIT_EXPENSE_DETAILS_SUCCESS;
    submitSuccessMessage: string;
}

export interface ISubmitExpenseDetailsFailed {
    type: DetailsActionType.SUBMIT_EXPENSE_DETAILS_FAILED;
    postActionErrorMessage: string;
}

export interface IExpenseSubmissionRequest {
    id: string;
    header?: IExpenseSubmissionHeader;
    lines?: IExpenseSubmissionLine[];
}
export interface IExpenseSubmissionHeader {
    submitterComments: string;
    reportName: string;
    description: string;
    interimApprovers: string;
    isAntiCorruption: boolean;
    preApprovalNumber: string;
}
export interface IExpenseSubmissionLine {
    costCenterNumber: number;
    internalOrderNumber: string;
    isPersonalTransaction: boolean;
}

export interface IReceiveUserImageAction {
    type: DetailsActionType.RECEIVE_USER_IMAGE;
    userImage: any;
}

export interface IFailedUserImageAction {
    type: DetailsActionType.FAILED_USER_IMAGE;
    userImageErrorMessage: string;
}

export interface IRequestDocumentAction {
    type: DetailsActionType.REQUEST_DOCUMENT;
    actionId: string;
    attachmentName: string;
    attachmentId?: string;
}

export interface IRequestDocumentStart {
    type: DetailsActionType.REQUEST_DOCUMENT_START;
    docName: string;
}

export interface IRequestDocumentEnd {
    type: DetailsActionType.REQUEST_DOCUMENT_END;
}

export interface IFailedDocumentPreviewAction {
    type: DetailsActionType.FAILED_DOCUMENT_PREVIEW;
    documentPreviewErrorMessage: string;
}

export interface IFailedDocumentDownloadAction {
    type: DetailsActionType.FAILED_DOCUMENT_DOWNLOAD;
    documentDownloadErrorMessage: string;
}

export interface IRequestAllDocumentsAction {
    type: DetailsActionType.REQUEST_ALL_DOCUMENTS;
    actionId: string;
    attachmentArray: IAttachment[];
}

export interface IRequestAllDocumentsStart {
    type: DetailsActionType.REQUEST_ALL_DOCUMENTS_START;
}

export interface IRequestAllDocumentsEnd {
    type: DetailsActionType.REQUEST_ALL_DOCUMENTS_END;
}

export interface IFailedAllDocumentDownload {
    type: DetailsActionType.FAILED_ALL_DOCUMENT_DOWNLOAD;
    documentDownloadErrorMessage: string;
}
