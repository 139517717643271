import * as React from 'react';
import * as Styled from '../../Shared/Styles/SharedLayout.styled';
import { IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { IconButton } from '@fluentui/react';

const stackTokens: IStackTokens = { childrenGap: 20, padding: 20 };

const chinaTraningStackToken: IStackTokens = { childrenGap: 100, padding: 10 };




export function TransitionToMSExpensePage(): React.ReactElement {
    usePageTitle(`Stat Forms - ${__APP_NAME__}`);

    const [expandedList, setExpandedList] = React.useState([]);


    function handleToggleAccordon(key) {
        let newList = [];
        if (isKeyExistsInExpanded(key)) {
            newList = expandedList.map(l => {
                if (key === l.key) {
                    l.expanded = !l.expanded;
                }
                return l;
            })
        } else {
            newList = [...expandedList];
            newList.push({
                key,
                expanded: true,
            })
        }

        setExpandedList(newList);
    }

    function isKeyExistsInExpanded(key) {
        return expandedList.some(l => l.key == key);
    }

    function isExpanded(key) {
        return expandedList.some(l => l.key == key && l.expanded);
    }

    return (
        <div>
            <Stack tokens={stackTokens}>
                <Stack.Item>
                    <img src="https://expense.microsoft.com/Images/Wave_new.JPG" style={{ width: '80%' }} />
                </Stack.Item>
                <Stack.Item>
                    <React.Fragment key={0}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Styled.SectionTitle>
                                    <IconButton
                                        iconProps={{ iconName: isExpanded(0) ? 'ChevronDownMed' : 'ChevronRightMed' }}
                                        title={isExpanded(0) ? 'Collapse' : 'Expand'}
                                        ariaLabel={isExpanded(0) ? 'Collapse' : 'Expand'}
                                        onClick={(): void => {
                                            handleToggleAccordon(0);
                                        }}
                                    />
                                    Country Specific Training Scenarios
                                </Styled.SectionTitle>
                            </div>
                        </div>
                        {isExpanded(0) && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    this is test
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                </Stack.Item>

                <Stack.Item>
                    <React.Fragment key={1}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Styled.SectionTitle>
                                    <IconButton
                                        iconProps={{ iconName: isExpanded(1) ? 'ChevronDownMed' : 'ChevronRightMed' }}
                                        title={isExpanded(1) ? 'Collapse' : 'Expand'}
                                        ariaLabel={isExpanded(1) ? 'Collapse' : 'Expand'}
                                        onClick={(): void => {
                                            handleToggleAccordon(1);
                                        }}
                                    />
                                    China Trainings and Hyper Care Sessions
                                </Styled.SectionTitle>
                            </div>
                        </div>
                        {isExpanded(1) && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">


                                    <Stack tokens={stackTokens}>
                                        <Stack.Item>
                                            <h3>Trainings</h3>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    China Session 1
                                        </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/a339a91a-ec93-4500-98e7-07f46f8b2d0f" target="_blank" className="links">September 2nd</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    China Session 2
                                        </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/bd3fb378-fa02-4cbb-a2fa-0f5a7274cf59?list=studio" target="_blank" >September 5th</a>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <a download href="~/document/Transition to MSE 2.0 - China.pptx" target="_blank" >Download Training Deck</a>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <h3>Hypercare Sessions</h3>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    <a download href="https://msit.microsoftstream.com/video/048c93ae-6bf3-4c7b-b68b-16fe9aadb4de" target="_blank" >September 10th</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/eef4a55f-40c5-4990-9c58-f7a09a85886f" target="_blank" >September 12th</a>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                </Stack.Item>

                <Stack.Item>
                    <React.Fragment key={2}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Styled.SectionTitle>
                                    <IconButton
                                        iconProps={{ iconName: isExpanded(2) ? 'ChevronDownMed' : 'ChevronRightMed' }}
                                        title={isExpanded(2) ? 'Collapse' : 'Expand'}
                                        ariaLabel={isExpanded(2) ? 'Collapse' : 'Expand'}
                                        onClick={(): void => {
                                            handleToggleAccordon(2);
                                        }}
                                    />
                                     Wave 3 Trainings and Hyper Care Sessions
                                </Styled.SectionTitle>
                            </div>
                        </div>
                        {isExpanded(2) && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">


                                    <Stack tokens={stackTokens}>
                                        <Stack.Item>
                                            <h3>Trainings</h3>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 1
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/bac2dfb0-738d-4a9d-a29c-10cfd42f45c1" target="_blank" >September 17th</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 2
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/73d35b56-e2f2-4454-b1ad-acda3491b8d5" target="_blank" >September 19th</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 3
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/46130e1a-b583-464a-95e7-747b7024f969" target="_blank" >September 24th</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 4
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/50e67271-2db5-4948-a70e-e6dead518386" target="_blank" >September 26th</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    Russia Session
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/3b257a5d-5158-49d9-b52b-ecf46d3b312a" target="_blank" >September 25th</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    Turkey Session
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/65ca12fe-54cd-4d0a-b7b3-015d8be909fd" target="_blank" >September 19th</a>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <a download href="~/document/Transition to MSE 2.0 - Wave3.pptx" target="_blank">Download Training Deck</a>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <h3>Hypercare Sessions</h3>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    <a download href="~/Hypercare Invitations/Transition to MSE 2.0 Global Hypercare Call - Session 1 - October 3rd.vcs" target="_blank">October 3rd</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="~/Hypercare Invitations/Transition to MSE 2.0 Global Hypercare Call - Session 2 - October 8th.vcs" target="_blank" >October 8th</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="~/Hypercare Invitations/Transition to MSE 2.0 Global Hypercare Call - Session 3 - October 10th.vcs" target="_blank" >October 10th</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="~/Hypercare Invitations/Transition to MSE 2.0 Global Hypercare Call - Session 4 - October 15th.vcs" target="_blank" >October 15th</a>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                </Stack.Item>


                <Stack.Item>
                    <React.Fragment key={3}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Styled.SectionTitle>
                                    <IconButton
                                        iconProps={{ iconName: isExpanded(3) ? 'ChevronDownMed' : 'ChevronRightMed' }}
                                        title={isExpanded(3) ? 'Collapse' : 'Expand'}
                                        ariaLabel={isExpanded(3) ? 'Collapse' : 'Expand'}
                                        onClick={(): void => {
                                            handleToggleAccordon(3);
                                        }}
                                    />
                                     Wave 2 Trainings and Hyper Care Sessions
                                </Styled.SectionTitle>
                            </div>
                        </div>
                        {isExpanded(3) && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">


                                    <Stack tokens={stackTokens}>
                                        <Stack.Item>
                                            <h3>Trainings</h3>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 1
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://web.microsoftstream.com/video/91d6451d-37e0-46c1-af61-07328c06ca3d" target="_blank" >July 5th</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 2
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/8bad29e1-8ebe-4265-a60f-94b31a5edb88" target="_blank" >July 9th</a><strong><i><sup style={{ color: '#A80000' }}> View Online!</sup></i></strong>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 3
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/3d926609-3135-4a2d-87dc-776e1973ab15" target="_blank" >July 11th</a><strong><i><sup style={{ color: '#A80000' }}> View Online!</sup></i></strong>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 4
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/33e8305f-ad63-4ebd-ad6e-a142dc5e7101" target="_blank" >July 23rd</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    EMEA Session 5
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/7438d55e-de6d-4c75-ac64-38d7cf2b8448" target="_blank" >July 25th</a>
                                                </Stack.Item>
                                            </Stack>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    APAC Session
                                               </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/1edf9078-210b-4a5e-be7e-f2aed4226121" target="_blank" >July 23rd</a>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <a download href="~/document/Transition to MSE 2.0 - Wave2.pptx" target="_blank">Download Training Deck</a>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <h3>Hypercare Sessions</h3>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <Stack horizontal tokens={chinaTraningStackToken}>
                                                <Stack.Item>
                                                    <a download href="https://msit.microsoftstream.com/video/8b86e3e8-1d60-4a48-847b-64f317d31d28" target="_blank" >July 29th</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/cc526972-efef-45a4-bbf8-740b1fbc9d36" target="_blank" >August 1st</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/aa61a805-2dcc-4cc6-8a80-9bc4ffecf366" target="_blank" >August 6th</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/7ad84e73-f4ae-44e7-81ea-4da1804efa28" target="_blank" >August 8th</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="https://msit.microsoftstream.com/video/3d98d77d-1ecd-4561-aa06-016a9879a1af" target="_blank" >August 13th</a>
                                                </Stack.Item>
                                                <Stack.Item >
                                                    <a download href="~/Hypercare Invitations/Transition to MSE 2.0 Global Hypercare Call - Session 6 - August 16th .vcs" target="_blank" >August 16th</a>
                                                </Stack.Item>
                                            </Stack>
                                        </Stack.Item>
                                    </Stack>
                                </div>
                            </div>
                        )}
                    </React.Fragment>
                </Stack.Item>

                <Stack.Item>
                    <React.Fragment key={4}>
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                <Styled.SectionTitle>
                                    <IconButton
                                        iconProps={{ iconName: isExpanded(4) ? 'ChevronDownMed' : 'ChevronRightMed' }}
                                        title={isExpanded(4) ? 'Collapse' : 'Expand'}
                                        ariaLabel={isExpanded(4) ? 'Collapse' : 'Expand'}
                                        onClick={(): void => {
                                            handleToggleAccordon(4);
                                        }}
                                    />
                                   Communications
                                </Styled.SectionTitle>
                            </div>
                        </div>
                        {isExpanded(4) && (
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                    <Stack tokens={stackTokens}>
                                        <Stack.Item>
                                            <a href="https://expense.microsoft.com/Document/Expense%20Transformation%20Announcement.pdf" target="_blank">Expense Digital Transformation</a>
                                        </Stack.Item>
                                    </Stack>

                                </div>
                            </div>
                        )}
                    </React.Fragment>
                </Stack.Item>
            </Stack>
        </div >
    );
}
