import * as React from 'react';
import * as HeaderStyled from './SecondaryHeaderStyling';
import * as Styled from '../../Shared/Styles/SharedLayout.styled';
import { Stack, PersonaSize, Persona } from '@fluentui/react';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/employee-experience/lib/useDynamicReducer';
import { useGraphPhoto } from '@micro-frontend-react/employee-experience/lib/useGraphPhoto';
import { Reducer } from 'redux';
import { stackStyles, stackTokens } from '../../Shared/Styles/SharedLayout.styled';
import { IconButton } from '@fluentui/react/lib/Button';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { IContextualMenuProps } from '@fluentui/react';
import { DirectionalHint } from '@fluentui/react';
import { GroupingBy } from '../../Shared/GroupingBy';
import { IExpenseAppState } from '../../Shared/Store/Expense.types';
import { sharedExpenseSagas } from '../../Shared/Store/Expense.sagas';
import { requestExpenseStatCounts, updateGroupedSummary, updateFilterValue } from '../../Shared/Store/Expense.actions';
import { expenseInitialState, expenseReducer, expenseReducerName } from '../../Shared/Store/Expense.reducer';
import { getFilterValue, getExpenseDataNotJustSubmitted, getIsPanelOpen } from '../../Shared/Store/Expense.selectors';
import { statusDisplayTitles } from '../../Shared/SharedConstants';
import { mapDate } from '../../Helpers/DateFormating/DateFormatting';

function SecondaryHeader(): React.ReactElement {
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);

    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { profile, isLoadingProfile, hasError, summaryGroupedBy, expenseCounts, isLoadingStats, submittedReports } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );
    const expenseDataSummary = useSelector(getExpenseDataNotJustSubmitted);
    const filterValue = useSelector(getFilterValue);
    //const isPanelOpen = useSelector(getIsPanelOpen);
    const [filterMenuProps, setFilterMenuProps] = React.useState<IContextualMenuProps>(null);

    React.useEffect(() => {
        if(!expenseCounts && !isLoadingStats) {
            dispatch(requestExpenseStatCounts());
        }
    }, [dispatch]);

    React.useEffect(() => {
        getFilteredMenuItems();
    }, [filterValue, summaryGroupedBy, expenseDataSummary]);

    const totalCount = expenseCounts ? expenseCounts.totalExpensesCount : 0;
    const processingCount = expenseCounts ? expenseCounts.processingCount : 0;
    const paidCount = expenseCounts ? expenseCounts.paidCount : 0;
    const approvedCount = expenseCounts ? expenseCounts.approvedCount : 0;
    const inReviewCount = expenseCounts ? expenseCounts.inReviewCount : 0;
    const [draftCount, setDraftCount] = React.useState(0);
    const items = [];
    const photo = useGraphPhoto();

    React.useEffect(() => {
        setDraftCount(expenseCounts ? expenseCounts.draftCount - submittedReports.length : 0);
    }, [submittedReports, isLoadingStats])

    if (profile) {
        items.push({
            key: profile.displayName,
            text: profile.displayName,
            title: profile.displayName,

        })
    }

    if (totalCount > 0) {
        items.push({
            key: `${totalCount} Total Expenses`,
            text: `${totalCount} Total Expenses`,
            title: `${totalCount} Total Expenses`,
        })
    }

    if (draftCount > 0) {
        items.push({
            text: `${draftCount} Drafts`,
            key: `${draftCount} Drafts`,
            title: `${draftCount} Drafts`,
        })
    }

    if (inReviewCount > 0) {
        items.push({
            text: `${inReviewCount} In Review`,
            key: `${inReviewCount} In Review`,
            title: `${inReviewCount} In Review`,
        })
    }

    if (approvedCount > 0) {
        items.push({
            text: `${approvedCount} Approved`,
            key: `${approvedCount} Approved`,
            title: `${approvedCount} Approved`,
        })
    }

    if (processingCount > 0) {
        items.push({
            text: `${totalCount} Processing Payment`,
            key: `${totalCount} Processing Payment`,
            title: `${totalCount} Processing Payment`,
        })
    }
    
    if (paidCount > 0) {
        items.push({
            text: `${paidCount} Paid`,
            key: `${paidCount} Paid`,
            title: `${paidCount} Paid`,
        })
    }

    // FILTERING
    const getStatusLabel = (expenseStatus: string) => {
        switch(expenseStatus) {
            case 'approved':
                return 'Approved';
            case 'draft':
                return 'Draft';
            case 'in review':
                return 'In Review';
            case 'processing payment':
                return 'Processing Payment';
            case 'paid':
                return 'Paid';
            default:
                return expenseStatus;
        }
    }

    const getFilteredMenuItems = (): void => {
        let filterMenuItems: string[] = ['All'];
        let allItems: any = expenseDataSummary;
        let filterKey: string = summaryGroupedBy;

        for (const key in allItems) {
            let filterValue = allItems[key][filterKey];
            if (summaryGroupedBy === GroupingBy.Date) {
                filterValue = filterValue.toString().substring(0, 10);
            } 
            if (!filterMenuItems.includes(filterValue)) {
                filterMenuItems.push(filterValue);
            } 
        }

        if (summaryGroupedBy === GroupingBy.Status) {
            for (let i = 0; i < filterMenuItems.length; i++) {
                // reset the labels to not be all lowercase
                filterMenuItems[i] = getStatusLabel(filterMenuItems[i]);
                // get rid of statuses that we're not going to show
                if(filterMenuItems[i] !== "All" && !statusDisplayTitles.includes(filterMenuItems[i])) {
                    filterMenuItems.splice(filterMenuItems.indexOf(filterMenuItems[i]), 1);
                    i--;
                }
            }
        }

        // Sort Menu Items
        const allIndex = filterMenuItems.indexOf('All');
        if (allIndex > -1) {
            filterMenuItems.splice(allIndex, 1);
        }
        filterMenuItems.sort();
        if (filterKey === GroupingBy.Date) {
            // flip the sorting (descending)
            filterMenuItems.reverse();
            // format the dates
            filterMenuItems.forEach((date, index, dateArray) => {
                dateArray[index] = mapDate(date);
            });
        }
        filterMenuItems.unshift('All'); //have All always on top

        // Set Items
        let filterMenuProps: IContextualMenuProps = {
            items: [],
            directionalHint: DirectionalHint.bottomRightEdge,
            directionalHintFixed: true
        };
        filterMenuProps.items = filterMenuItems.map(x => ({
            ['key']: x,
            ['text']: x,
            ['title']: x,
            ['canCheck']: true,
            // change this if want to check multiple things
            ['isChecked']: x === filterValue ? true : false,
            ['onClick']: () => {
                dispatch(updateFilterValue(x));
            }
        }));
        setFilterMenuProps(filterMenuProps);
    };

    // GROUPING
    const handleSummaryGrouping = (groupType: string): void => {
        dispatch(updateGroupedSummary(groupType));
    }

    const summaryMenuProps: IContextualMenuProps = {
        items: [
            {
                key: "Expense status",
                text: "Expense status",
                canCheck: true,
                isChecked: summaryGroupedBy === GroupingBy.Status ? true : false,
                onClick: () => handleSummaryGrouping(GroupingBy.Status)
            },
            {
                key: "Submitted date",
                text: "Submitted date",
                canCheck: true,
                isChecked: summaryGroupedBy === GroupingBy.Date ? true : false,
                onClick: () => handleSummaryGrouping(GroupingBy.Date)
            }
        ],
        directionalHint: DirectionalHint.bottomRightEdge,
        directionalHintFixed: true
    };

    const menuProps: IContextualMenuProps = {
        items,
        directionalHintFixed: true,
    };

    return (
        <div role="complementary" aria-label='Expense count and grouping'>
            <HeaderStyled.SecondaryHeaderContainer>
                <Stack horizontal styles={HeaderStyled.SecondaryHeaderStackStyles}>
                    <Stack.Item>
                        <Stack horizontal styles={stackStyles}>
                            <Stack.Item align="center">
                                <Stack tokens={stackTokens}>
                                    {!isLoadingProfile && !hasError && profile && (
                                        <><Stack.Item className={'ms-hiddenMdDown'}>
                                            <Persona
                                                imageUrl={photo || undefined}
                                                emailAlias={profile.userPrincipalName}
                                                size={PersonaSize.size40}
                                                text={profile.displayName}
                                                optionalText={profile.officeLocation}/>
                                        </Stack.Item>
                                        <Stack.Item className={'ms-hiddenLgUp'} >
                                            <Persona
                                                emailAlias={profile.userPrincipalName}
                                                size={PersonaSize.size40}/>
                                        </Stack.Item></>
                                    )}
                                </Stack>
                            </Stack.Item>
                            {totalCount > 0 &&
                                <Stack.Item align="center" className={Styled.Styles.spacing + ' ms-hiddenMdDown'}>
                                    <Stack horizontal>
                                        <Styled.SummaryCount>{totalCount}</Styled.SummaryCount> <Styled.SummaryCountLabel>Total Expenses</Styled.SummaryCountLabel>
                                    </Stack>
                                </Stack.Item>
                            }
                            {draftCount > 0 &&
                                <Stack.Item align="center" className={Styled.Styles.spacing + ' ms-hiddenMdDown'}>
                                    <Stack horizontal>
                                        <Styled.SummaryCount>{draftCount}</Styled.SummaryCount> <Styled.SummaryCountLabel>Draft</Styled.SummaryCountLabel>
                                    </Stack>
                                </Stack.Item>
                            }
                            {inReviewCount > 0 &&
                                <Stack.Item align="center" className={Styled.Styles.spacing + ' ms-hiddenMdDown'}>
                                    <Stack horizontal>
                                        <Styled.SummaryCount>{inReviewCount}</Styled.SummaryCount> <Styled.SummaryCountLabel>In Review</Styled.SummaryCountLabel>
                                    </Stack>
                                </Stack.Item>
                            }
                            {approvedCount > 0 &&
                                <Stack.Item align="center" className={Styled.Styles.spacing + ' ms-hiddenMdDown'}>
                                    <Stack horizontal>
                                        <Styled.SummaryCount>{approvedCount}</Styled.SummaryCount> <Styled.SummaryCountLabel>Approved</Styled.SummaryCountLabel>
                                    </Stack>
                                </Stack.Item>
                            }
                            {processingCount > 0 &&
                                <Stack.Item align="center" className={Styled.Styles.spacing + ' ms-hiddenMdDown'}>
                                    <Stack horizontal>
                                        <Styled.SummaryCount>{processingCount}</Styled.SummaryCount> <Styled.SummaryCountLabel>Processing</Styled.SummaryCountLabel>
                                    </Stack>
                                </Stack.Item>
                            }
                            {paidCount > 0 &&
                                <Stack.Item align="center" className={Styled.Styles.spacing + ' ms-hiddenMdDown'}>
                                    <Stack horizontal>
                                        <Styled.SummaryCount>{paidCount}</Styled.SummaryCount> <Styled.SummaryCountLabel>Paid</Styled.SummaryCountLabel>
                                    </Stack>
                                </Stack.Item>
                            }
                            <Stack.Item align="center" className={Styled.Styles.spacing + ' ms-hiddenLgUp'}>
                                <Stack horizontalAlign="end" horizontal>
                                    <TooltipHost content="Status">
                                        <IconButton menuIconProps={{ iconName: 'More' }} title="Status" ariaLabel="Status" style={Styled.iconStyling} menuProps={menuProps} />
                                    </TooltipHost>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item grow={20} align="center" styles={HeaderStyled.GroupAndFilterIconStackItemStyles}>
                        <Stack horizontalAlign="end" horizontal>
                            <TooltipHost
                                style={HeaderStyled.SecondaryHeaderIconStyling}
                                content="Group By"
                            >
                                <IconButton
                                    id="groupBy"
                                    iconProps={{ iconName: 'GroupList' }}
                                    title="Group By"
                                    ariaLabel="Group By"
                                    style={HeaderStyled.SecondaryHeaderIconStyling}
                                    menuProps={summaryMenuProps}
                                />
                            </TooltipHost>
                            <TooltipHost content="Filter">
                                <IconButton
                                    id="filter"
                                    iconProps={{ iconName: filterValue === 'All' ? 'Filter' : 'FilterSolid' }}
                                    title="Filter"
                                    ariaLabel="Filter"
                                    style={HeaderStyled.SecondaryHeaderIconStyling}
                                    menuProps={filterMenuProps}
                                />
                            </TooltipHost>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </HeaderStyled.SecondaryHeaderContainer>
        </div>
    )
}

const connected = withContext(SecondaryHeader);
export { connected as SecondaryHeader };