import {
    SimpleEffect,
    Effect,
    getContext,
    put,
    all,
    call,
    takeLatest,
    select,
    actionChannel,
} from 'redux-saga/effects';
import {
    ExpenseActionType,
    IRequestProfileAction,
    IRequestUserPreferences,
    IRequestExpenseCountStats,
    IRequestSummaryAction,
    IRequestFilteredUsersAction,
    ISaveUserPreferences,
    IRequestDelegatesAction,
    IModifyDelegateAction,
} from './Expense.action-types';
import {
    receiveMyProfile,
    receiveMySummary,
    failedProfile,
    failedSummary,
    setContinuationToken,
    setContinuationTokenDraft,
    receiveSubmitterImages,
    receiveExpenseStatCounts,
    failedExpenseStatCounts,
    receiveFilteredUsers,
    ReceiveUserPreferences,
    saveUserPreferencesSuccess,
    saveUserPreferencesFailed,
    receiveDelegates,
    modifyDelegateSuccess,
    modifyDelegateFailed,
    receiveDelegateFailed,
} from './Expense.actions';
import { IExpenseDelegateDetails, IProfile, IStats } from './Expense.types';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Helpers/telemetryHelpers';
import { getStateCommonTelemetryProperties, _saveUserPreferences, getUserAlias, getProfile } from './Expense.selectors';
import { convertToUPN } from '../../Helpers/sharedHelpers';
import { getOperationText } from '../../Components/Pages/ManageDelegates/ManageDelegatesConstants';
import { IAuthClient } from '@micro-frontend-react/employee-experience/lib/IAuthClient';
import {
    IHttpClient,
    IHttpClientResult,
    IHttpClientRequest,
} from '@micro-frontend-react/employee-experience/lib/IHttpClient';
import { ITelemetryClient } from '@micro-frontend-react/employee-experience/lib/ITelemetryClient';

function* fetchProfile(action: IRequestProfileAction): IterableIterator<Effect<{}, {}>> {
    const authClient: IAuthClient = yield getContext('authClient');
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);

    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        // make graph call to get current user
        const graphResponse: IHttpClientResult<any> = yield call([httpClient, httpClient.request], {
            url: `${__GRAPH_BASE_URL__}/me`,
            resource: __GRAPH_RESOURCE_URL__,
        });

        // make expense profile call to get expense tool and company code
        let userAlias;
        if (graphResponse.data.userPrincipalName.includes('@')) {
            userAlias = graphResponse.data.userPrincipalName.substring(
                0,
                graphResponse.data.userPrincipalName.indexOf('@')
            );
        } else {
            userAlias = graphResponse.data.userPrincipalName;
        }

        const expenseProfileResponse: IHttpClientResult<IProfile> = yield call([httpClient, httpClient.request], {
            url: `${__API_BASE_URL_V1__}/user/profile/${userAlias}`,
            resource: __RESOURCE_URL__,
        });

        let profile: IProfile = {
            userPrincipalName: graphResponse?.data?.userPrincipalName,
            displayName: graphResponse?.data?.displayName,
            jobTitle: graphResponse?.data?.jobTitle,
            officeLocation: graphResponse?.data?.officeLocation,
            companyCode: expenseProfileResponse?.data?.response?.companyCode,
            expenseTool: expenseProfileResponse?.data?.response?.toolName,
        };

        yield put(receiveMyProfile(profile));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load User Profile from Microsoft graph - Success',
            'Expense-Web.LoadUserProfile.Success',
            TrackingEventId.LoadUserProfileFromGraphSuccess,
            stateCommonProperties,
            null,
            action.featureName
        );
    } catch (error) {
        yield put(failedProfile(error.message ? error.message : error));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load User Profile from Microsoft graph - Failure',
            'Expense-Web.LoadUserProfileFromGraph.Failure',
            TrackingEventId.LoadUserProfileFromGraphFailure,
            stateCommonProperties,
            exception,
            action.featureName
        );
    }
}

function* fetchGraphData(submitterAlias: string): IterableIterator<Effect<{}, {}>> {
    const httpClient: IHttpClient = yield getContext('httpClient');
    try {
        const resp: IHttpClientResult<any> = yield call([httpClient, httpClient.request], {
            url: `${__GRAPH_BASE_URL__}users/${submitterAlias}/photos/48x48/$value`,
            resource: __GRAPH_RESOURCE_URL__,
            responseType: 'blob',
        });
        if (resp.status === 200) {
            const imageURL = window.URL.createObjectURL(resp.data);
            return { alias: submitterAlias, image: imageURL };
        } else {
            return { alias: submitterAlias, image: null };
        }
    } catch (ex) {
        return { alias: submitterAlias, image: null };
    }
}

function* fetchExpenses(action: IRequestSummaryAction): IterableIterator<Effect<{}, {}>> {
    const httpClient: IHttpClient = yield getContext('httpClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    const email: string = yield call([authClient, authClient.getUserId]);
    const alias: string = email ? email.substring(0, email.indexOf('@')) : null;
    try {
        let summaryItems = [];
        let draftItems = [];

        if (!action.draftsOnly && (action.firstCall || action.continuationToken)) {
            const { data }: IHttpClientResult<IProfile> = yield call([httpClient, httpClient.request], {
                url: `${__API_BASE_URL__}/expense/headers?EmployeeAlias=${alias}`,
                resource: __RESOURCE_URL__,
                headers: action.continuationToken
                    ? {
                          ContinuationToken: action.continuationToken,
                      }
                    : {},
            });

            summaryItems =
                data?.response?.items && data?.response?.items.length > 0
                    ? data?.response?.items.map((s) => ({
                          ...s,
                          sortIndex: mapSortIndex(s),
                      }))
                    : [];

            yield put(setContinuationToken(data?.response?.continuationToken));
        }
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load all expense data - Success',
            'Expense-Web.LoadExpenseData.Success',
            TrackingEventId.LoadExpenseDataSuccess,
            stateCommonProperties,
            null,
            action.featureName
        );

        if (action.firstCall || action.continuationTokenDraft) {
            const draftResponse: IHttpClientResult<IProfile> = yield call([httpClient, httpClient.request], {
                url: `${__API_BASE_URL__}/expense/headers?EmployeeAlias=${email}&ExpenseStatus=draft`,
                resource: __RESOURCE_URL__,
                headers: action.continuationToken
                    ? {
                          ContinuationToken: action.continuationTokenDraft,
                      }
                    : {},
            });
            draftItems =
                draftResponse && draftResponse.data && draftResponse.data.response && draftResponse.data.response.items
                    ? draftResponse.data.response.items.map((d) => ({
                          ...d,
                          expenseStatus: 'draft',
                          totalAmount: d.totalAmount.toFixed(2),
                          sortIndex: 0,
                          dateSubmitted: d.dateCreated,
                      }))
                    : [];

            if (draftResponse && draftResponse?.data && draftResponse?.data?.response) {
                yield put(setContinuationTokenDraft(draftResponse?.data?.response?.continuationToken));
            }
        }
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load all draft expense data - Success',
            'Expense-Web.LoadDraftExpenseData.Success',
            TrackingEventId.LoadDraftExpenseSuccess,
            stateCommonProperties,
            null,
            action.featureName
        );
        const combinedResponse = [...summaryItems, ...draftItems];
        yield put(receiveMySummary(combinedResponse, action.firstCall));

        if (!action.draftsOnly) {
            let submitters: string[] = [];
            for (let i = 0; i < combinedResponse.length; i++) {
                let submitter = combinedResponse[i]?.submitter?.alias;
                submitter = convertToUPN(submitter);
                if (!submitters.includes(submitter)) {
                    submitters.push(submitter);
                }
            }
            const graphResponses = yield all(submitters.map((sub) => call(fetchGraphData, sub)));
            yield put(receiveSubmitterImages(graphResponses));
        }
    } catch (error) {
        yield put(failedSummary(error.message ? error.message : error));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load all expense data - Failure',
            'Expense-Web.LoadExpenseData.Failure',
            TrackingEventId.LoadExpenseDataFailure,
            stateCommonProperties,
            exception,
            action.featureName
        );
    }
}

function* fetchExpenseCountStats(action: IRequestExpenseCountStats): IterableIterator<Effect<{}, {}>> {
    const authClient: IAuthClient = yield getContext('authClient');
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    const email: string = yield call([authClient, authClient.getUserId]);
    const userAlias: string = email ? email.substring(0, email.indexOf('@')) : null;
    const defaultCounts: IStats = {
        totalExpensesCount: 0,
        inReviewCount: 0,
        processingCount: 0,
        approvedCount: 0,
        paidCount: 0,
        draftCount: 0,
    };

    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const result: IHttpClientResult<IProfile> = yield call([httpClient, httpClient.request], {
            url: `${__API_BASE_URL__}/expense/statistics/${userAlias}`,
            resource: __RESOURCE_URL__,
        });

        let counts: IStats = {
            totalExpensesCount: 0,
            inReviewCount: 0,
            processingCount: 0,
            approvedCount: 0,
            paidCount: 0,
            draftCount: 0,
        };
        result?.data?.response?.map((count: any) => {
            const status = count.expenseStatus.toLowerCase();
            if (status === 'in review') {
                counts.inReviewCount += count.totalReports;
            } else if (status === 'processing payment') {
                counts.processingCount += count.totalReports;
            } else if (status === 'approved') {
                counts.approvedCount += count.totalReports;
            } else if (status === 'paid') {
                counts.paidCount += count.totalReports;
            } else if (status === 'draft') {
                counts.draftCount += count.totalReports;
            }
        });

        // calculate total expenses from all the other counts
        counts.totalExpensesCount =
            counts.inReviewCount + counts.processingCount + counts.approvedCount + counts.paidCount + counts.draftCount;

        yield put(receiveExpenseStatCounts(counts));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load Expense Statistics - Success',
            'Expense-Web.LoadExpenseStatistics.Success',
            TrackingEventId.LoadExpenseStatisticsSuccess,
            stateCommonProperties,
            null,
            action.featureName
        );
    } catch (error) {
        yield put(failedExpenseStatCounts(error.message ? error.message : error, defaultCounts));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load Expense Statistics - Failure',
            'Expense-Web.LoadExpenseStatistics.Failure',
            TrackingEventId.LoadExpenseStatisticsFailure,
            stateCommonProperties,
            exception,
            action.featureName
        );
    }
}

function mapSortIndex(summaryItem: any) {
    let sortIndex = 1;

    switch (summaryItem.expenseStatus.toLowerCase()) {
        case 'in review':
            sortIndex = 1;
            break;
        case 'approved':
            sortIndex = 2;
            break;
        case 'processing payment':
            sortIndex = 3;
            break;
        case 'paid':
            sortIndex = 4;
            break;
    }

    return sortIndex;
}

function* fetchFilteredUsers(action: IRequestFilteredUsersAction): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const { data: results }: IHttpClientRequest = yield call([httpClient, httpClient.request], {
            url: `${_GRAPH_BASE_URL}users?$filter=startswith(userPrincipalName%2C+'${action.filterText}')`,
            resource: __GRAPH_RESOURCE_URL__,
        });
        yield put(receiveFilteredUsers(results.value));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load Users from Microsoft graph- Success',
            'MSExpense.LoadUsersFromMicrosoftGraph.Success',
            TrackingEventId.LoadUsersFromMicrosoftGraphSuccess,
            stateCommonProperties
        );
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse;
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load Users from Microsoft graph- Failure',
            'MSExpense.LoadUsersFromMicrosoftGraph.Failure',
            TrackingEventId.LoadUsersFromMicrosoftGraphFailure,
            stateCommonProperties,
            exception
        );
    }
}

function* fetchUserPreferences(): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    //email is being passed as upn
    const email: string = yield call([authClient, authClient.getUserId]);
    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const { data }: IHttpClientRequest = yield call([httpClient, httpClient.request], {
            url: `${__API_BASE_URL__}/expense/automationpreferences/${email}`,
            resource: __RESOURCE_URL__,
        });

        const userPreferences = data.response;

        yield put(ReceiveUserPreferences(userPreferences));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Get user preferences - Success',
            'MSExpense.GetUserPreferences.Success',
            TrackingEventId.GetUserPreferenceSuccess,
            stateCommonProperties,
            null,
            'User Preferences'
        );
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse; // handling cases where error occurs outside of httpclient call
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Get user preferences - Failure',
            'MSExpense.GetUserPreferences.Failure',
            TrackingEventId.GetUserPreferenceFailure,
            stateCommonProperties,
            exception,
            'User Preferences'
        );
    }
}

function* saveUserPreferences(action: ISaveUserPreferences): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        //email is being passed as upn
        const email: string = yield call([authClient, authClient.getUserId]);
        const profile = yield select(getProfile);
        const companyCode = (profile as IProfile).companyCode;
        const userPrefData = { ...action.userPreferences, ...{ userAlias: email, companyCode: companyCode } };
        const result: IHttpClientRequest = yield call([httpClient, httpClient.request], {
            url: `${__API_BASE_URL__}/expense/preferences`,
            method: 'POST',
            resource: __RESOURCE_URL__,
            data: userPrefData,
        });
        if (result?.data?.statusCode === 'OK') {
            yield put(saveUserPreferencesSuccess(['User preferences successfully saved']));
        } else {
            yield put(saveUserPreferencesFailed(result?.data?.response || ['An unidentified error occured']));
        }
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Save user preferences - Success',
            'MSExpense.SaveUserPreference.Success',
            TrackingEventId.SaveUserPreferenceSuccess,
            stateCommonProperties,
            null,
            'User Preferences'
        );
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse; // handling cases where error occurs outside of httpclient call
        const exception = error.message ? new Error(error.message) : error;
        yield put(saveUserPreferencesFailed([error.message]));
        trackException(
            authClient,
            telemetryClient,
            'Save user preferences - Failure',
            'MSExpense.SaveUserPreference.Failure',
            TrackingEventId.SaveUserPreferenceFailure,
            stateCommonProperties,
            exception,
            'User Preferences'
        );
    }
}

function* fetchDelegatesList(action: IRequestDelegatesAction): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    try {
        const email: string = yield call([authClient, authClient.getUserId]);
        const alias: string = email ? email.substring(0, email.indexOf('@')) : null;
        const delegatesData: IExpenseDelegateDetails = { userAlias: alias, delegateType: action.delegateType };
        const httpClient: IHttpClient = yield getContext('httpClient');
        const { data }: IHttpClientRequest = yield call([httpClient, httpClient.request], {
            url: `${__INTEGRATION_API_BASE_URL__}/user/get/expensedelegates`,
            method: 'POST',
            resource: __INTEGRATION_API_RESOURCE_URL__,
            data: delegatesData,
        });
        const delegatesList = data.response;
        yield put(receiveDelegates(delegatesList));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            `Get ${action?.delegateType} delegates list for ${alias} - Success`,
            'MSExpense.GetDelegatesList.Success',
            TrackingEventId.GetDelegatesListSuccess,
            stateCommonProperties,
            null,
            'Manage Delegates'
        );
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse;
        const exception = error.message ? new Error(error.message) : error;
        yield put(receiveDelegateFailed(`Error while fetching delegates. Please try again later.`));
        trackException(
            authClient,
            telemetryClient,
            `Get ${action?.delegateType} delegates list - Failure`,
            'MSExpense.GetDelegatesList.Failure',
            TrackingEventId.GetDelegatesListFailure,
            stateCommonProperties,
            exception,
            'Manage Delegates'
        );
    }
}

function* modifyDelegate(action: IModifyDelegateAction): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    const operation: string = getOperationText(action.operation);
    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const result: IHttpClientRequest = yield call([httpClient, httpClient.request], {
            url: `${__INTEGRATION_API_BASE_URL__}/user/${action.operation}/expensedelegate`,
            method: 'POST',
            resource: __INTEGRATION_API_RESOURCE_URL__,
            data: action.delegate,
        });
        if (result?.data?.statusCode === 'Success') {
            yield put(
                modifyDelegateSuccess(`The delegate '${action?.delegate?.delegateAlias}' was successfully ${operation}`)
            );
        }
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            `${action.operation.toString()} delegate - Success`,
            'MSExpense.ModifyDelegate.Success',
            TrackingEventId.ModifyDelegateSuccess,
            stateCommonProperties,
            null,
            'Manage Delegates'
        );
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse;
        const exception = error.message
            ? new Error(`${error.message}. Payload: ${JSON.stringify(action?.delegate)}`)
            : error;
        yield put(modifyDelegateFailed(`The delegate '${action?.delegate?.delegateAlias}' could not be ${operation}`));
        trackException(
            authClient,
            telemetryClient,
            `${action.operation.toString()} delegate - Failure`,
            'MSExpense.ModifyDelegate.Failure',
            TrackingEventId.ModifyDelegateFailure,
            stateCommonProperties,
            exception,
            'Manage Delegates'
        );
    }
}

export function* sharedExpenseSagas(): IterableIterator<{}> {
    yield all([
        takeLatest(ExpenseActionType.REQUEST_MY_PROFILE, fetchProfile),
        takeLatest(ExpenseActionType.REQUEST_MY_SUMMARY, fetchExpenses),
        takeLatest(ExpenseActionType.REQUEST_FILTERED_USERS, fetchFilteredUsers),
        takeLatest(ExpenseActionType.SAVE_USER_PREFERENCES, saveUserPreferences),
        takeLatest(ExpenseActionType.REQUEST_USER_PREFERENCES, fetchUserPreferences),
        takeLatest(ExpenseActionType.REQUEST_EXPENSE_STAT_COUNTS, fetchExpenseCountStats),
        takeLatest(ExpenseActionType.REQUEST_DELEGATES, fetchDelegatesList),
        takeLatest(ExpenseActionType.MODIFY_DELEGATE, modifyDelegate),
    ]);
}
