import { IStackStyles } from '@fluentui/react/lib/Stack';
import { IStyle, mergeStyleSets } from '@fluentui/react';

export const ButtonsStackStyles: IStackStyles = {
    root: {
        marginTop: '16px',
        marginBottom: '16px',
    },
};

export const LinkStackItemStyles: IStackStyles = {
    root: {
        marginTop: '16px',
        marginBottom: '16px',
    },
};

export const PanelStyles = mergeStyleSets({
    root: {
        marginLeft: '-10px',
        marginRight: '-10px'
    } as IStyle,
});