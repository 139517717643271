import * as React from 'react';
import { usePageTracking } from '@micro-frontend-react/employee-experience/lib/usePageTracking';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { getFeature, getPageLoadFeature } from '@micro-frontend-react/employee-experience/lib/UsageTelemetryHelper';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { updateShowSecondaryHeader } from '../../../Shared/Store/Expense.actions';
import { MyExpenseTraining } from './MyExpenseTraining/MyExpenseTrainingPage';
import { ConcurTraining } from './ConcurTraining/ConcurTrainingPage';
import { IExpenseAppState } from '../../../Shared/Store/Expense.types';
import { expenseReducerName, expenseReducer, expenseInitialState } from '../../../Shared/Store/Expense.reducer';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export interface IFAQList {
    title: string;
    text: string;
    videoUrl?: string;
    documentUrl?: string;
    videoWidth: string;
    videoHeight: string;
    textAsHeader: any;
    isExpanded: boolean;
    fullScreen: boolean;
}

export function Training(): React.ReactElement {
    usePageTitle(`Training - ${__APP_NAME__}`);
    const feature = getFeature('ExpenseWeb', 'TrainingPage');
    usePageTracking(getPageLoadFeature(feature));
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);

    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { profile, isLoadingProfile } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        dispatch(updateShowSecondaryHeader(false));
    }, [dispatch]);

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }
        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const expenseTool = profile ? profile.expenseTool : 'MYEXPENSE';

    return (
        <div>
            {expenseTool === 'CONCUR' && <ConcurTraining />}
            {expenseTool === 'MYEXPENSE' && <MyExpenseTraining />}
        </div>
    );
}
