import * as React from 'react';
import {
    Stack,
    TextField,
    PrimaryButton,
    DefaultButton,
    DatePicker,
    NormalPeoplePicker,
    IPersonaProps,
    IBasePickerSuggestionsProps,
    Label,
} from '@fluentui/react';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { CoherenceModal } from '@coherence-design-system/controls';
import * as Styled from './ManageDelegatesStyling';
import { IExpenseAppState, IExpenseDelegateDetails } from '../../../Shared/Store/Expense.types';
import { HttpClient } from '@micro-frontend-react/employee-experience/lib/HttpClient';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseInitialState, expenseReducer, expenseReducerName } from '../../../Shared/Store/Expense.reducer';
import { hideManageDelegateDialog, modifyDelegate } from '../../../Shared/Store/Expense.actions';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import { OperationType } from './ManageDelegatesConstants';
import { ManageDelegatesConfirmationDialog } from './ManageDelegatesConfirmationDialog';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

type IManageDelegateModalProps = {
    isOpen: boolean;
    onDismiss: () => void;
    delegateToUpdate?: IExpenseDelegateDetails;
    delegateType: string;
};

function ManageDelegateModal(props: IManageDelegateModalProps): React.ReactElement {
    const { useSelector, telemetryClient, dispatch, authClient } = React.useContext(
        Context as React.Context<IEmployeeExperienceContext>
    );
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);

    const {
        profile,
        delegatesList,
        showManageDelegatesDialog,
        modifyDelegateHasError,
        modifyDelegateErrorMessage,
        modifyDelegateSuccessMessage,
    } = useSelector((state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState);
    const [delegateAlias, setDelegateAlias] = React.useState('');
    const [comments, setComments] = React.useState('');
    const [startDate, setStartDate] = React.useState<Date | null | undefined>(undefined);
    const [endDate, setEndDate] = React.useState<Date | null | undefined>(undefined);
    const [isUpdatingDelegate, setIsUpdatingDelegate] = React.useState<boolean>(false);
    const [pickerSelectedDelegate, setPickerSelectedDelegate] = React.useState<IPersonaProps[]>(null);
    const [showDelegateExistsDialog, setShowDelegateExistsDialog] = React.useState<boolean>(false);
    const picker = React.useRef(null);
    const httpClient = new HttpClient(telemetryClient, authClient);
    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested People',
        noResultsFoundText: 'No results found',
        loadingText: 'Loading',
    };

    React.useEffect(() => {
        if (props.delegateToUpdate) {
            setDelegateAlias(props.delegateToUpdate?.delegateAlias);
            setComments(props.delegateToUpdate?.comments);
            setStartDate(new Date(props.delegateToUpdate?.startDate));
            setEndDate(new Date(props.delegateToUpdate?.endDate));
            setIsUpdatingDelegate(true);
            props.delegateToUpdate.delegateAlias
                ? setPickerSelectedDelegate([
                    {
                        text: props.delegateToUpdate.delegateAlias + '@microsoft.com',
                        secondaryText: props.delegateToUpdate.delegateAlias + '@microsoft.com',
                    },
                ])
                : setPickerSelectedDelegate([]);
        }
    }, [props.delegateToUpdate]);

    const clearAllFields = () => {
        setDelegateAlias('');
        setComments('');
        setStartDate(undefined);
        setEndDate(undefined);
        setPickerSelectedDelegate(null);
    };

    const closeModal = () => {
        clearAllFields();
        props.onDismiss();
    };

    const onSubmitClicked = (): void => {
        const delegate: IExpenseDelegateDetails = {
            delegateType: props.delegateType,
            delegateAlias: delegateAlias,
            comments: comments,
            startDate: startDate,
            endDate: endDate,
            companyCode: props?.delegateToUpdate?.companyCode
                ? props.delegateToUpdate.companyCode
                : profile?.companyCode,
            recordId: props.delegateToUpdate ? props.delegateToUpdate?.recordId : null,
            userAlias: props.delegateToUpdate
                ? props.delegateToUpdate?.userAlias
                : profile?.userPrincipalName.substring(0, profile?.userPrincipalName.indexOf('@')),
        };

        if (!delegateAlreadyExists(delegate)) {
            isUpdatingDelegate
                ? dispatch(modifyDelegate(delegate, OperationType.patch))
                : dispatch(modifyDelegate(delegate, OperationType.add));

            closeModal();
        }
    };

    function areFieldInValid(): boolean {
        return (
            delegateAlias == null ||
            delegateAlias == '' ||
            startDate == null ||
            startDate == undefined ||
            endDate == null ||
            endDate == undefined ||
            endDate <= startDate
        );
    }

    function delegateAlreadyExists(newDelegate: IExpenseDelegateDetails): boolean {
        let delegateExists = false;
        if (delegatesList) {
            delegatesList?.map((existingDelegate: IExpenseDelegateDetails) => {
                if (
                    existingDelegate?.delegateAlias === newDelegate?.delegateAlias &&
                    existingDelegate?.recordId !== newDelegate?.recordId
                ) {
                    const existingStartDate = new Date(existingDelegate?.startDate);
                    const existingEndDate = new Date(existingDelegate?.endDate);
                    const newStartDate = newDelegate?.startDate;
                    const newEndDate = newDelegate?.endDate;
                    if (existingStartDate <= newEndDate && newStartDate <= existingEndDate) {
                        setShowDelegateExistsDialog(true);
                        delegateExists = true;
                    }
                }
            });
        }
        return delegateExists;
    }

    function addOneDay(date: Date): Date {
        const newDate = new Date(date);
        newDate.setDate(newDate.getDate() + 1);
        return newDate;
    }

    const onFilterChanged = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            return new Promise((resolve) => {
                //calling graph api directly here instead of using sagas because api response value needs to be returned
                //TODO: add telemetry
                try {
                    httpClient
                        .request({
                            url: `${__GRAPH_BASE_URL__}users?$search="displayName:${filterText}" OR "userPrincipalName:${filterText}"`,
                            resource: __GRAPH_RESOURCE_URL__,
                            header: { ConsistencyLevel: 'eventual' },
                        })
                        .then((result) =>
                            resolve(
                                (result.data as any).value.map((item: any) => {
                                    return {
                                        text: item.displayName,
                                        upn: item.userPrincipalName,
                                        secondaryText: item.userPrincipalName,
                                    };
                                })
                            )
                        );
                } catch {
                    resolve([]);
                }
            });
        } else {
            return [];
        }
    };

    const getTextFromItem = (persona: IPersonaProps): string => {
        return persona.text as string;
    };

    function onPickerDelegateChange(items: IPersonaProps[]): void {
        setPickerSelectedDelegate(items);
        items?.map((item: IPersonaProps) => setDelegateAlias(item.secondaryText?.replace('@microsoft.com', '')));
    }

    const onRenderFooter = () => {
        return (
            <div>
                <Stack horizontal styles={Styled.ModalButtonGroup} tokens={Styled.ModalButtonGroupStackTokens}>
                    <Stack.Item>
                        <PrimaryButton
                            text={isUpdatingDelegate ? 'Update Delegate' : 'Add Delegate'}
                            title={isUpdatingDelegate ? 'Update Delegate' : 'Add Delegate'}
                            onClick={onSubmitClicked}
                            disabled={areFieldInValid()}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DefaultButton text="Cancel" title="Cancel" onClick={closeModal} />
                    </Stack.Item>
                </Stack>
            </div>
        );
    };

    const getModalContent = () => {
        return (
            <div>
                <Stack styles={Styled.AddNewDelegateStackStyles} tokens={Styled.AddNewDelegateStackTokens}>
                    <Stack.Item>
                        <TextField label="Delegate Type" value={props?.delegateType} required disabled />
                    </Stack.Item>
                    <Stack.Item>
                        <Stack.Item>
                            <Label required>Enter Delegate Alias</Label>
                        </Stack.Item>
                        <Stack.Item>
                            <NormalPeoplePicker
                                onResolveSuggestions={onFilterChanged}
                                getTextFromItem={getTextFromItem}
                                componentRef={picker}
                                pickerSuggestionsProps={suggestionProps}
                                selectedItems={pickerSelectedDelegate}
                                onChange={onPickerDelegateChange}
                                itemLimit={1}
                                disabled={isUpdatingDelegate}
                                removeButtonAriaLabel="Remove selection for delegate"
                                inputProps={{
                                    'aria-label': 'Set delegate',
                                    placeholder: 'Start typing for suggestions...',
                                }}
                            />
                        </Stack.Item>
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker
                            isRequired
                            label="Start Date"
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            disableAutoFocus={isUpdatingDelegate}
                            value={startDate}
                            minDate={new Date()}
                            onSelectDate={(date) => setStartDate(date)}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <DatePicker
                            isRequired
                            label="End Date"
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            value={endDate}
                            minDate={addOneDay(startDate)}
                            onSelectDate={(date) => setEndDate(date)}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        <TextField label="Comments" value={comments} onChange={(event, value) => setComments(value)} />
                    </Stack.Item>
                </Stack>
            </div>
        );
    };
    return (
        <div>
            <CoherenceModal
                isOpen={props.isOpen}
                modalWidth={'small'}
                height={'fixed'}
                onDismiss={closeModal}
                title={isUpdatingDelegate ? 'Update Delegate' : 'Add a New Delegate'}
                onRenderFooter={onRenderFooter}
            >
                {getModalContent()}
            </CoherenceModal>
            <ManageDelegatesConfirmationDialog
                dialogText={modifyDelegateHasError ? modifyDelegateErrorMessage : modifyDelegateSuccessMessage}
                dialogSubject={modifyDelegateErrorMessage ? 'Failure' : 'Confirmation'}
                buttonText="Ok"
                showDialog={showManageDelegatesDialog}
                onDismiss={() => {
                    dispatch(hideManageDelegateDialog());
                }}
            />
            <ManageDelegatesConfirmationDialog
                dialogText={`The delegate '${delegateAlias}' already exists for the selected start and end date. Please edit the
                existing delegate record instead`}
                dialogSubject="Warning"
                buttonText="Ok"
                showDialog={showDelegateExistsDialog}
                onDismiss={() => {
                    setShowDelegateExistsDialog(false);
                }}
            />
        </div>
    );
}

const connected = withContext(ManageDelegateModal);
export { connected as ManageDelegateModal };
