import * as React from 'react';
import { Reducer } from 'redux';
import { updateShowSecondaryHeader } from '../../../../Shared/Store/Expense.actions';
import { sharedExpenseSagas } from '../../../../Shared/Store/Expense.sagas';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseReducerName, expenseReducer } from '../../../../Shared/Store/Expense.reducer';

import * as Styled from './CardHelpers/CardStyling.styled';
import * as SharedStyled from '../../../../Shared/Styles/SharedLayout.styled';
import * as CardHelpers from './CardHelpers/CardHelpers';
import { Label, FontIcon, Link, Stack } from '@fluentui/react';
import * as Materials from './CardHelpers/TrainingMaterialsLinksData';
import { DefaultButton } from '@fluentui/react';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export function TrainingMaterialsCard(): React.ReactElement {
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);
    const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    React.useEffect(() => {
        dispatch(updateShowSecondaryHeader(false));
    }, [dispatch]);

    const getLink = (linkAddress: string, linkName: string) => {
        return (
            <Styled.LinkStyling>
                <Stack horizontal verticalAlign="center" styles={{ root: { paddingBottom: '14px' } }}>
                    <Stack.Item>
                        <FontIcon iconName="PDF" role="presentation" style={Styled.LinkIconStyling} />
                    </Stack.Item>
                    <Stack.Item>
                        <Link href={linkAddress} target="_blank" title={linkName}>
                            {linkName}
                        </Link>
                    </Stack.Item>
                </Stack>
            </Styled.LinkStyling>
        );
    };

    const getLinks = () => {
        let quickLinks;
        quickLinks = Materials.MyExpenseLinks;

        return <div>{quickLinks.map((link, index) => getLink(link.link, link.text))}</div>;
    };

    return (
        <Styled.LargeCard windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
            {CardHelpers.getHeader('MyExpense Training Materials')}
            <SharedStyled.Divider />
            <Styled.CardBody windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
                <Styled.CenterItemStyling>
                    <Styled.TrainingMaterialsImage />
                </Styled.CenterItemStyling>
                <Styled.CardBodyText>
                    <Label>MyExpense</Label>
                </Styled.CardBodyText>
                {getLinks()}
            </Styled.CardBody>
            <Styled.CardFooter>
                <DefaultButton
                    title="View all materials"
                    text="View all materials"
                    alt="View all training materials"
                    href="/Training"
                />
            </Styled.CardFooter>
        </Styled.LargeCard>
    );
}
