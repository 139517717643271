import * as React from 'react';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { requestDelegates, requestMyProfile } from '../../../Shared/Store/Expense.actions';
import { expenseReducerName, expenseInitialState, expenseReducer } from '../../../Shared/Store/Expense.reducer';
import { IExpenseAppState } from '../../../Shared/Store/Expense.types';
import { Dropdown, IDropdownOption, IStackTokens, PrimaryButton, Icon, ScrollablePane, Spinner, Stack } from '@fluentui/react';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import * as Styled from './ReeiptUploadStyling';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { getProfile } from '../../../Shared/Store/Expense.selectors';
import { updateReceiptUploadPanelState, resetReceiptUploadState, uploadReceipt } from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.actions';
import { receiptBankReducerName, receiptBankReducer, receiptBankInitialState } from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.reducer';
import { receiptBankSagas } from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.sagas';
import { IReceiptBankState } from '../MainDashboard/ReceiptUpload/ReceiptUploadStore/ReceiptUpload.types';
import { FileUploader } from '../../../Helpers/fileUploader';
import { FilePictureUpload } from '../../../Shared/Components/FilePictureUpload';
import { FileSelectorButton } from '../../../Shared/Components/FileSelectorButton';
import ErrorView from '../../../Shared/Components/MessageBars/ErrorView';
import SuccessViewBar from '../../../Shared/Components/MessageBars/SuccessViewBar';
import BasicButton from '../../../Shared/Components/BasicButton';
import * as SharedStyled from '../../../Shared/Styles/SharedLayout.styled';
import { useLocation } from 'react-router-dom';

export function ReceiptUploadPage(): React.ReactElement {
    //intializing reducer without persistance
    useDynamicReducer(receiptBankReducerName, receiptBankReducer as Reducer, [receiptBankSagas], false);
    const stackTokens: IStackTokens = { childrenGap: 12 };

    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas], false);
    const { useSelector, telemetryClient: telemetryHook, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const profile = useSelector(getProfile);

    //Extract parameters from the URL
    const location = useLocation();
    // Get a specific query parameter
    const clientDevice = new URLSearchParams(location.search).get('clientDevice');
    const uploadToMyExpenseFlagParam = new URLSearchParams(location.search).get('uploadToMyExpenseFlag');
    const scanReceiptFlagParam = new URLSearchParams(location.search).get('scanReceiptFlag');
    const expenseIdParam = new URLSearchParams(location.search).get('expenseId');
    
    React.useEffect(() => {
        if (profile === null) {
            dispatch(requestMyProfile());
        }
    }, [dispatch]);

    const { isPanelOpen, isUploadingReceipt, receiptUploadHasError, receiptUploadResponseMessage } = useSelector(
        (state: IReceiptBankState) => state.dynamic?.[receiptBankReducerName] || receiptBankInitialState
    );

    React.useEffect(() => {
        dispatch(updateReceiptUploadPanelState(false));
        setSelectedFile(null);
        dispatch(resetReceiptUploadState());
    }, []);

    // React.useEffect(() => {
    //     setSelectedFile(null);
    //     dispatch(resetReceiptUploadState());
    // }, [isPanelOpen]);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        if (receiptUploadResponseMessage) {
            setSelectedFile(null);
        }
    }, [receiptUploadResponseMessage]);

    // const updatePanel = (): void => {
    //     const panelState = !isPanelOpen;
    //     dispatch(updateReceiptUploadPanelState(panelState));
    //     return;
    // };
    const cancelUpload = (): void => {
        dispatch(updateReceiptUploadPanelState(false));
        setSelectedFile(null);
        return;
    }

    const [fileSelected, setSelectedFile] = React.useState<File>();
    const [isModalOpen, setModalOpen] = React.useState(false);

    const onSubmitButtonClicked = () => {
        dispatch(uploadReceipt(fileSelected, clientDevice, uploadToMyExpenseFlagParam, scanReceiptFlagParam, expenseIdParam));
    };

    const checkRequiredFieldFilled = () => {
        if (fileSelected == null) {
            return true;
        }
        return false;
    };

    const launchModal = () => {
        setModalOpen(true);
    };

    const closeModal = (): void => {
        setModalOpen(false);
    };


    return (
        <>
            {receiptUploadHasError && receiptUploadResponseMessage && (
                <Styled.MessageBarStyled>
                    <ErrorView
                        errorMessages={[receiptUploadResponseMessage]}
                        customTitle="An error occurred. Please find details below:"
                    />
                </Styled.MessageBarStyled>
            )}
            {!receiptUploadHasError && receiptUploadResponseMessage && (
                <Styled.MessageBarStyled>
                    <SuccessViewBar successMessage={receiptUploadResponseMessage} />
                </Styled.MessageBarStyled>
            )}
            {isUploadingReceipt && <Spinner label="Uploading receipt" ariaLabel="Uploading receipt" />}
            {!isUploadingReceipt && (
                <>
                    <Styled.SummaryContainer windowHeight={dimensions.height} windowWidth={dimensions.width}>
                        <Styled.ReceiptPageViewTitle>Add a Receipt</Styled.ReceiptPageViewTitle>
                        <Styled.FileSelectionArea>
                            <Styled.BrowseFilesArea>
                                <Styled.Subheader>Browse Files</Styled.Subheader>
                                <FileUploader accepts={''} callback={setSelectedFile} />
                            </Styled.BrowseFilesArea>
                            <Styled.Subheader>Take a Picture</Styled.Subheader>
                            <FileSelectorButton
                                text="Take Picture of Receipt"
                                // secondaryText="Limited to 5 MB in size"
                                onClick={launchModal}
                                iconName="Camera"
                            >
                                <FilePictureUpload
                                    isOpen={isModalOpen}
                                    onDismiss={closeModal}
                                    callback={setSelectedFile}
                                />
                            </FileSelectorButton>
                        </Styled.FileSelectionArea>
                        <SharedStyled.Divider />
                        {/* show the uploaded document */}
                        <Styled.FileSelectedArea>
                            <Styled.Subheader>File Selected</Styled.Subheader>
                            {fileSelected ? (
                                <Stack horizontal styles={Styled.DocSelectedStackStyles}>
                                    <Stack.Item>
                                        <Icon style={Styled.fileUploadIconStyle} iconName="TextDocument" />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack>
                                            <Stack.Item>
                                                <Styled.BoldedText>{fileSelected.name}</Styled.BoldedText>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <Styled.SecondaryText>
                                                    {fileSelected.type}
                                                </Styled.SecondaryText>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            ) : (
                                <Styled.DocNotSelectedStyle>
                                    <Styled.SecondaryTextItalics>
                                        No File Selected
                                    </Styled.SecondaryTextItalics>
                                </Styled.DocNotSelectedStyle>
                            )}
                        </Styled.FileSelectedArea>
                    </Styled.SummaryContainer>
                    <Stack horizontal styles={Styled.Footer}>
                        <Stack.Item styles={Styled.SmallSpace}>
                            <BasicButton
                                primary={true}
                                text="Add"
                                title="Add"
                                disabled={checkRequiredFieldFilled()}
                                onClick={() => {
                                    onSubmitButtonClicked();
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={Styled.SmallSpace}>
                            <BasicButton text="Cancel" title="Cancel" onClick={cancelUpload} />
                        </Stack.Item>
                    </Stack>
                </>
            )}
        </>
    );
}
