import { IDropdownOption } from '@fluentui/react/lib/Dropdown';

export const options: IDropdownOption[] = [
    { key: 'Submitter', text: 'Submitter' },
    { key: 'Approver', text: 'Approver' },
];

export enum OperationType {
    add = 'add',
    patch = 'patch',
    delete = 'delete',
}

export function getOperationText(operation: OperationType): string {
    switch (operation) {
        case OperationType.add:
            return 'added';
        case OperationType.delete:
            return 'deleted';
        case OperationType.patch:
            return 'updated';
        default:
            return '';
    }
}
