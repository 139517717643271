import { withContext } from '@micro-frontend-react/core/lib/Context';
import * as React from 'react';
import * as Helpers from '../../../Helpers/sharedHelpers';
import { ActionButton, Link, Stack } from '@fluentui/react';
import * as HeaderStyled from '../HeaderPanels.styled';
import * as SharedStyled from '../../../Shared/Styles/SharedLayout.styled';
import * as Styled from './HelpPanel.styled';

function HelpPanel(): React.ReactElement {

    const onGetHelpBtnClicked = () => {
        Helpers.launchBot();
    };

    return (
        <div className={HeaderStyled.PanelStyles.root}>
            <div>
                <Styled.FirstGroupLinks>
                    <ActionButton iconProps={{ iconName: 'Help' }} onClick={onGetHelpBtnClicked}>
                        Get help
                    </ActionButton>
                </Styled.FirstGroupLinks>
                <SharedStyled.Divider />
                <Stack styles={HeaderStyled.ButtonsStackStyles}>
                    <Stack.Item styles={HeaderStyled.LinkStackItemStyles}>
                        <Link
                            title="Privacy Statement"
                            href="http://aka.ms/MSA_CFIT_MA_Privacy"
                            target="_blank"
                        >
                            {'Privacy Statement'}
                        </Link>
                    </Stack.Item>
                    <Stack.Item styles={HeaderStyled.LinkStackItemStyles}>
                        <Link
                            title="Legal Notice"
                            href="http://aka.ms/MSA_CFIT_MA_Legal"
                            target="_blank"
                        >
                            {'Legal Notice'}
                        </Link>
                    </Stack.Item>
                    <Stack.Item styles={HeaderStyled.LinkStackItemStyles}>
                        <Link
                            title="Data Privacy Notice"
                            href="https://privacy.microsoft.com/en-US/data-privacy-notice"
                            target="_blank"
                        >
                            {'Data Privacy Notice'}
                        </Link>
                    </Stack.Item>
                </Stack>
            </div>
        </div>
    );
}

const connected = withContext(HelpPanel);
export { connected as HelpPanel };