import * as React from 'react';
import * as AdaptiveCards from 'adaptivecards';
import * as MarkdownIt from 'markdown-it';
import { hostConfig } from './AdaptiveHostConfig.js';
import * as adaptivecardsTemplating from 'adaptivecards-templating';
import * as Styling from './AdaptiveCardStyling';

interface IAdaptiveProps {
    template: any;
    dataPayload: any;
    style?: any;
    onOpenURLActionExecuted: any;
    onSubmitActionExecuted: any;
}

export class Adaptive extends React.Component<IAdaptiveProps> {
    private adaptiveCard = new AdaptiveCards.AdaptiveCard();

    shouldComponentUpdate(nextProps: IAdaptiveProps): boolean {
        return(
            nextProps.dataPayload !== this.props.dataPayload
        );
    }

    public render(): React.ReactElement {
        const openURLclickHandler = this.props.onOpenURLActionExecuted;
        const onSubmitActionExecuted = this.props.onSubmitActionExecuted;
        try {
            AdaptiveCards.AdaptiveCard.onProcessMarkdown = function (text, result) {
                result.outputHtml = MarkdownIt().render(text);
                result.didProcess = true;
            };

            AdaptiveCards.AdaptiveCard.onExecuteAction = function (action) {
                const type = action.getJsonTypeName();
                if (type === 'Action.OpenUrl') {
                    if (openURLclickHandler) {
                        openURLclickHandler(action.getHref(), action.id, action.title);
                    }
                } else if (type === 'Action.Submit') {
                    if (onSubmitActionExecuted) {
                        onSubmitActionExecuted(action.id, action.data);
                    }
                }
            };

            var templatePayload = new adaptivecardsTemplating.Template(this.props.template);

            // Expand the template with your `$root` data object.
            // This binds it to the data and produces the final Adaptive Card payload
            var cardPayload = templatePayload.expand({
                $root: this.props.dataPayload
            });

            this.adaptiveCard.hostConfig = new AdaptiveCards.HostConfig(hostConfig);
            this.adaptiveCard.parse(cardPayload);
            const result = this.adaptiveCard.render();
            result.setAttribute('tabindex', '-1');

            // setting styling / aria attributes
            const elements = result.getElementsByClassName('ac-horizontal-separator');
            for (let i = 0; i < elements.length; i++) {
                elements[i].setAttribute('aria-hidden', 'true');
            }

            //setting style for links
            const acSelectableElements = result.getElementsByClassName('ac-selectable');
            for (let i = 0; i < acSelectableElements.length; i++) {
                (acSelectableElements[i] as HTMLElement).style.cursor = 'Pointer';
            }

            // style buttons
            const allButtons = result.getElementsByClassName('ac-pushButton style-default');
            for (let i = 0; i < allButtons.length; i++) {
                if(allButtons[i].getAttribute('class').includes('ac-pushButton style-default primary style-positive')) {
                    const primaryButtonStyle = allButtons[i].getAttribute('style');
                    allButtons[i].setAttribute('style', primaryButtonStyle + Styling.primaryButtonStyling);
                }
                else {
                    const secondaryButtonStyle = allButtons[i].getAttribute('style');
                    allButtons[i].setAttribute('style', secondaryButtonStyle + Styling.secondaryButtonStyling);
                }
            }

            return (
                <div
                    style={this.props.style}
                    ref={(n) => {
                        n != null && n.firstChild && n.removeChild(n.firstChild);
                        n != null && n.appendChild(result);
                    }}
                />
            );
        } catch (err) {
            return <div style={{ color: 'red' }}>{err.message}</div>;
        }
    }
}
