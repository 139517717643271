import { IStackStyles } from '@fluentui/react/lib/Stack';
import { FontSizes, FontWeights } from '@fluentui/react';
import { IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { TextColors } from '../../../../../Shared/Styles/SharedColors'

export const Subheader = styled.h2`
    padding-bottom: 9px;
    font-size: ${FontSizes.size14};
    font-weight: ${FontWeights.semibold};
`;

export const BoldedText = styled.div`
    margin: 0px 0px 5px;
    display: block;
    color: ${TextColors.lightPrimary};
    font-size: ${FontSizes.size14};
    font-weight: ${FontWeights.semibold};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
`;

export const SecondaryText = styled.div`
    display: block;
    color: ${TextColors.secondary};
    font-size: ${FontSizes.size12};
    font-weight: ${FontWeights.regular};
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 160px;
`;

export const SecondaryTextItalics = styled.div`
    display: block;
    color: ${TextColors.lightPrimary};
    font-size: ${FontSizes.size12};
    font-weight: ${FontWeights.regular};
    font-style: italic;
    text-align: center;
`;

export const BrowseFilesArea = styled.div`
    margin-bottom: 20px;
`;

export const FileSelectionArea = styled.div`
    margin-bottom: 36px;
`;

export const FileSelectedArea = styled.div`
    padding-top: 36px;
    padding-bottom: 24px;
`;

export const DocNotSelectedStyle = styled.div`
    padding: 10px;
    border: 1px solid #ebebeb;
    background: #f2f2f2;
    align-items: center;
`;

export const DocSelectedStackStyles: IStackStyles = {
    root: {
        padding: '10px',
        border: '1px solid #ebebeb',
        background: '#f2f2f2',
        alignItems: 'center',
        display: 'flex',
    },
};

export const Footer: IStackStyles = {
    root: {
        position: 'fixed',
        bottom: '0',
        paddingLeft: '12px',
        width: '100%',
        background: 'white',
    },
};

export const SmallSpace: IStackStyles = {
    root: {
        marginBottom: '12px',
        paddingRight: '12px',
    },
};

export const fileUploadIconStyle = {
    padding: '10px',
    fontSize: '36px',
    color: TextColors.disabled,
};

export const documentTypesDropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};

export const MessageBarStyled = styled.div`
    padding-bottom: 10px;
`;