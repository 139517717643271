import * as React from 'react';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { requestDelegates, requestMyProfile } from '../../../Shared/Store/Expense.actions';
import { expenseReducerName, expenseInitialState, expenseReducer } from '../../../Shared/Store/Expense.reducer';
import { IExpenseAppState } from '../../../Shared/Store/Expense.types';
import { Dropdown, IDropdownOption, PrimaryButton, ScrollablePane, Spinner, Stack } from '@fluentui/react';
import { ManageDelegateModal } from './ManageDelegateModal';
import { ManageDelegatesTableColumns } from './ManageDelegatesTableColumns';
import { options } from './ManageDelegatesConstants';
import * as Styled from './ManageDelegatesStyling';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export function ManageDelegates(): React.ReactElement {
    const [selectedItem, setSelectedItem] = React.useState<IDropdownOption>(options[0]);
    const [delegateType, setDelegateType] = React.useState('Submitter');
    const [isMyExpenseUser, setIsMyExpenseUser] = React.useState(false);
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);
    const { isLoadingDelegates, delegatesList, receiveDelegateHasError, receiveDelegateListErrorMessage, isLoadingProfile, isModifyingDelegate, finishedModifyingDelegate, profile } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );
    const [isModalOpen, setModalOpen] = React.useState(false);
    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    React.useEffect(() => {
        dispatch(requestDelegates(delegateType));
    }, [dispatch, delegateType]);

    React.useEffect(() => {
        if (finishedModifyingDelegate) {
            dispatch(requestDelegates(delegateType));
        }
    }, [finishedModifyingDelegate]);

    React.useEffect(() => {
        if (profile === null) {
            dispatch(requestMyProfile());
        }
    }, []);

    React.useEffect(() => {
        const expenseTool = profile ? profile.expenseTool : '';
        if (expenseTool === 'MYEXPENSE') {
            setIsMyExpenseUser(true);
        } else {
            setIsMyExpenseUser(false);
        }
    }, [profile]);

    const launchModal = () => {
        setModalOpen(true);
    };

    const closeModal = (): void => {
        setModalOpen(false);
    };

    return (
        <div>
            {isMyExpenseUser && (
                <Styled.SummaryContainer windowHeight={dimensions.height} windowWidth={dimensions.width}>
                    <Styled.DelegatesViewTitle>Manage Expense Delegates</Styled.DelegatesViewTitle>
                    <div>
                        <Stack
                            horizontal
                            horizontalAlign="space-between"
                            wrap
                            styles={{ root: { paddingBottom: '1.5%', width: '100%' } }}
                        >
                            <Stack
                                horizontal
                                tokens={Styled.DelegatesNavStackTokens}
                                styles={{ root: { width: '100%' } }}
                            >
                                <Stack.Item styles={{ root: { width: 200 } }}>
                                    <Dropdown
                                        label="Select a delegate type"
                                        onChange={(event, item) => {
                                            setSelectedItem(item);
                                            setDelegateType(item.key.toString());
                                        }}
                                        selectedKey={selectedItem.key}
                                        options={options}
                                    />
                                </Stack.Item>
                                {(!isLoadingDelegates && !isModifyingDelegate) && (
                                    <Stack.Item
                                        align="end"
                                        styles={{ root: { marginLeft: 'auto !important', color: 'red' } }}
                                    >
                                        <PrimaryButton
                                            text={delegateType ? `Add ${delegateType} Delegate` : 'Add New Delegate'}
                                            title={delegateType ? `Add ${delegateType} Delegate` : 'Add New Delegate'}
                                            onClick={launchModal}
                                            ariaLabel={delegateType ? `Add ${delegateType} Delegate` : 'Add New Delegate'}
                                        />
                                        <ManageDelegateModal
                                            isOpen={isModalOpen}
                                            onDismiss={closeModal}
                                            delegateType={delegateType}
                                        />
                                    </Stack.Item>
                                )}
                            </Stack>
                        </Stack>
                        {(isLoadingDelegates || isModifyingDelegate) && (
                            <Styled.SpinnerContainer>
                                <Spinner label="Loading user roles..." />
                            </Styled.SpinnerContainer>
                        )}
                        {!isLoadingDelegates && !isModifyingDelegate && delegatesList && delegatesList.length !== 0 && (
                            <Stack grow>
                                <Styled.DelegatesTableContainer
                                    windowHeight={dimensions.height}
                                    windowWidth={dimensions.width}
                                >
                                    <Stack
                                        style={{
                                            height: dimensions.width < 1024 ? '100%' : `${dimensions.height - 250}px`,
                                        }}
                                    >
                                        <div
                                            style={{
                                                position: 'relative',
                                                height:
                                                    dimensions.width < 1024 ? '90%' : `${dimensions.height - 350}px`,
                                                overflowY: 'hidden',
                                                marginBottom: '1%',
                                            }}
                                        >
                                            <ScrollablePane>
                                                <ManageDelegatesTableColumns delegateType={delegateType} />
                                            </ScrollablePane>
                                        </div>
                                    </Stack>
                                </Styled.DelegatesTableContainer>
                            </Stack>
                        )}
                        {!isLoadingDelegates &&
                            !isModifyingDelegate &&
                            !receiveDelegateHasError &&
                            (!delegatesList || delegatesList.length === 0) && (
                                <Styled.EmptyDelegatedListMessage>
                                    No {delegateType} delegates found
                                </Styled.EmptyDelegatedListMessage>
                            )}
                        {!isLoadingDelegates &&
                            !isModifyingDelegate &&
                            receiveDelegateHasError && (
                                <Styled.EmptyDelegatedListMessage>
                                    {receiveDelegateListErrorMessage}
                                    <br />Please contact <a href="mailto:myexpdri@microsoft.com">Expense Team</a>  to manage delegate in case of urgency
                                </Styled.EmptyDelegatedListMessage>
                            )}
                    </div>
                </Styled.SummaryContainer>
            )}
            {!isMyExpenseUser && !isLoadingProfile && (
                <Styled.NotMyExpenseUserTitle>
                    Manage Delegates is only available for MyExpense users
                </Styled.NotMyExpenseUserTitle>
            )}
        </div>
    );
}
