/* eslint-disable prettier/prettier */
import { statusDisplayTitles } from "../Shared/SharedConstants";

export interface IGrouping {
    key: any;
    displayValue: string;
    grouping: any[];
}

export function mapDate(date: string) {
    if (!date)
        return date;

    const monthNames = [
        "Jan ", "Feb ", "Mar ",
        "Apr ", "May ", "June ", "July ",
        "Aug ", "Sept ", "Oct ",
        "Nov ", "Dec "
    ];
    var [year, monthIndex, day] = date.substring(0, 10).split("-");
    var month = monthNames[parseInt(monthIndex, 10) - 1];
    return month + day.toString() + ", " + year.toString();
}

const groupCardsBySubmitter = (grouping: object[]) => {
    return grouping.sort((a, b) => (a.submitter.Name > b.submitter.Name) ? 1 : -1);
}

export const groupByDate = (expenseData: object[]) => {
    const groupedByDate: IGrouping[] = [];

    // create set of dates
    const datesSet = new Set<string>();
    expenseData.forEach((item: any) => {
        // get the date without the time stamp
        datesSet.add(item.dateSubmitted ? item.dateSubmitted.substring(0, item.dateSubmitted.indexOf('T')) : null);
    });

    // grab the approval requests for each date
    datesSet.forEach((date) => {
        const dateGroup: IGrouping = {
            key: date,
            displayValue: mapDate(date),
            // sort the cards by submitter name in their groups
            grouping: groupCardsBySubmitter(
                expenseData.filter(
                    (item: object) =>
                        ((item as any).dateSubmitted ? (item as any).dateSubmitted.substring(0, (item as any).dateSubmitted.indexOf('T')) : null) === date
                )
            ),
        };
        groupedByDate.push(dateGroup);
    });

    // sort by descending date
    groupedByDate.sort((a, b) => (a.key < b.key ? 1 : -1));

    return groupedByDate;
};

const sortCardsByDescendingDate = (grouping: Array<any>) => {
    return grouping.sort((a, b) => (a.dateSubmitted?.substring(0, a.dateSubmitted.indexOf("T")) < b.dateSubmitted?.substring(0, b.dateSubmitted.indexOf("T"))) ? 1 : -1);
}

export const groupByStatus = (summary: object[]) => {
    const groupedByStatus: IGrouping[] = [];

    // grab the requests for each status
    statusDisplayTitles.forEach((status) => {
        var statusGroup: IGrouping = {
            key: status,
            displayValue: status,
            // sort the cards by descending date in their groups
            grouping: sortCardsByDescendingDate(summary.filter((item: object) => (item as any).expenseStatus.toLowerCase() === status.toLowerCase()))
        }
        if(statusGroup.grouping.length > 0) {
            groupedByStatus.push(statusGroup);
        }
    });

    return groupedByStatus;
}
