import { IStackTokens } from '@fluentui/react/lib/Stack';
import { IStyle, mergeStyleSets, mergeStyles, FontSizes, FontWeights } from '@fluentui/react';
import { CSSProperties } from 'styled-components';

export const PanelStyles = mergeStyleSets({
    root: {
        marginLeft: '-10px',
        marginRight: '-10px'
    } as IStyle,
});

export const PreferenceHeading = styled.h2`
    padding-bottom: 0px;
    font-weight: ${FontWeights.semibold};
    font-size: ${FontSizes.size14};
`;

export const PanelContent = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
`;

export const ItalicPanelContent = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    font-style: italic;
`;

export const subHeader: IStackTokens = {
    padding: 10,
};

export const firstGroup: IStackTokens = {
    childrenGap: 20,
    padding: 10,
};

export const secondGroup: IStackTokens = {
    childrenGap: 20,
    padding: 10,
};

export const footer: IStackTokens = {
    childrenGap: 20,
    padding: 10,
};

export const footerStyle = mergeStyles({
    position: 'relative',
    bottom: '37%',
});

export const iconStyle: CSSProperties = {
    paddingBottom: '10px',
    color: '#0064BF',
};