import { IStackStyles, IStackTokens } from '@fluentui/react/lib/Stack';

export const SummaryContainer = styled.div<any>`
    padding: 1% 2% 0 2%;
    height: ${(props: any) => props.windowHeight - 150}px;

    @media only screen and (max-device-width: 480px) {
        margin-left: 0;
    }
    @media only screen and (max-width: 320px) {
        height: ${(props: any) => props.windowHeight - 50}px;
    }
`;

export const DelegatesTableContainer: any = styled.div`
    height: ${(props: any) => props.windowHeight - 250}px;

    @media only screen and (max-device-width: 480px) {
        margin-left: 0;
    }
    @media only screen and (max-width: 320px) {
        height: ${(props: any) => props.windowHeight - 50}px;
    }
`;

export const DelegatesViewTitle = styled.h1`
    padding-bottom: 12px;
`;

export const NotMyExpenseUserTitle = styled.h2`
    padding-top: 16px;
    margin-bottom: 24px;
    text-align: center;
`;

export const EmptyDelegatedListMessage = styled.h2`
    text-align: center;
`;

export const DelegatesNavStackTokens: IStackTokens = {
    childrenGap: 15,
};

export const SpinnerContainer = styled.div`
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const AddNewDelegateStackTokens: IStackTokens = {
    childrenGap: 20,
    maxWidth: 350,
};

export const AddNewDelegateStackStyles: IStackStyles = {
    root: {
        marginBottom: '8%',
    },
};

export const ModalButtonGroup: IStackStyles = {
    root: {
        alignItems: 'end',
        display: 'flex',
    },
};

export const ModalButtonGroupStackTokens: IStackTokens = {
    childrenGap: 12,
};
