import {
    IUpdateUploadPanelStateAction, 
    IUploadReceiptAction,
    IUploadReceiptActionSuccess,
    IFailedReceiptUploadAction,
    IResetReceiptUploadStateAction,
    ReceiptBankActionType
} from './ReceiptUpload.action-types';

export function updateReceiptUploadPanelState(isUploadPanelOpen: boolean): IUpdateUploadPanelStateAction {
    return {
        type: ReceiptBankActionType.UPDATE_UPLOAD_PANEL_STATE,
        isUploadPanelOpen
    };
}

export function uploadReceipt(receiptToUpload: File, clientDevice?: string, uploadToMyExpenseFlag?: string, scanReceiptFlag?: string, expenseId?: string,): IUploadReceiptAction {
    return {
        type: ReceiptBankActionType.UPLOAD_RECEIPT,
        receiptToUpload,
        clientDevice,
        uploadToMyExpenseFlag,
        scanReceiptFlag,
        expenseId
    };
}

export function uploadReceiptSuccess(responseMessage: string): IUploadReceiptActionSuccess {
    return {
        type: ReceiptBankActionType.UPLOAD_RECEIPT_SUCCESS,
        responseMessage
    };
}

export function failedReceiptUpload(errorMessage: string): IFailedReceiptUploadAction {
    return {
        type: ReceiptBankActionType.FAILED_RECEIPT_UPLOAD,
        errorMessage
    };
}

export function resetReceiptUploadState(): IResetReceiptUploadStateAction {
    return {
        type: ReceiptBankActionType.RESET_RECEIPT_UPLOAD_STATE
    };
}