import { CoherencePanel } from '@coherence-design-system/controls';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { Stack } from '@fluentui/react';
import * as React from 'react';
import { expenseInitialState, expenseReducerName } from '../../Shared/Store/Expense.reducer';
import { IExpenseAppState } from '../../Shared/Store/Expense.types';
import { detailsReducerName, detailsInitialState } from './Details.reducer';
import { IDetailsAppState } from './Details.types';
import { DetailsAdaptive } from './DetailsAdaptive';
import * as LayoutStyled from '../../Shared/Styles/SharedLayout.styled';
import { MaximizeButton } from './DetailsButton/MaximizeButton';
import { CloseButton } from '../../Shared/Components/CloseButton';
import { BackButton } from './DetailsButton/BackButton';

function DetailsPanel(props: { templateType?: string; windowWidth: number; windowHeight: number }): React.ReactElement {
    const { useSelector, telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { isPanelOpen, maxDetailsScreen } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    const { isPreviewOpen } = useSelector(
        (state: IDetailsAppState) => state.dynamic?.[detailsReducerName] || detailsInitialState
    );

    function calculateWidth() {
        const widthXL8 = (window.innerWidth * 66.66) / 100;
        const widthXL5 = (window.innerWidth * 41.66) / 100;

        return {
            widthXL8,
            widthXL5,
        };
    }

    const _pWidth =
        window.innerWidth >= 768
            ? maxDetailsScreen
                ? calculateWidth().widthXL8
                : calculateWidth().widthXL5
            : window.innerWidth;

    const [detailPanelWidth, setDetailPanelWidth] = React.useState(_pWidth);

    function handleResize() {
        const _pWidth =
            window.innerWidth >= 768
                ? maxDetailsScreen
                    ? calculateWidth().widthXL8
                    : calculateWidth().widthXL5
                : window.innerWidth;

        setDetailPanelWidth(_pWidth);
    }
    React.useEffect(() => {
        handleResize();
    }, [maxDetailsScreen]);

    window.addEventListener('resize', handleResize);

    return (
        <Stack>
            <CoherencePanel
                isOpen={isPanelOpen}
                customWidth={`${detailPanelWidth}px`}
                className={props.templateType == 'All' ? 'detail-panel-with-sticky-footer' : ''}
                isLightDismiss={true}
                overlayProps={{
                    isDarkThemed: true,
                }}
                focusTrapZoneProps={{
                    firstFocusableSelector: 'close-button',
                }}
                telemetryHook={telemetryClient}
                onRenderNavigation={() => {
                    return (
                        <Stack.Item
                            styles={{
                                ...LayoutStyled.docPreviewHeaderBarStyles,
                                root: {
                                    ...LayoutStyled.docPreviewHeaderBarStyles.root,
                                    ...LayoutStyled.StickyDetailsHeder.root,
                                },
                            }}
                        >
                            {isPreviewOpen && <BackButton></BackButton>}
                            {!isPreviewOpen && <div></div>}
                            <Stack style={{ flexFlow: 'row' }}>
                                <Stack.Item>
                                    <MaximizeButton
                                        callbackOnMaximizeToggle={() => {
                                            adjustPanelWidth();
                                        }}
                                    />
                                </Stack.Item>
                                <Stack.Item>
                                    <CloseButton />
                                </Stack.Item>
                            </Stack>
                        </Stack.Item>
                    );
                }}
            >
                <DetailsAdaptive
                    componentContext={componentContext}
                    hideHeaderActionBar={true}
                    windowWidth={props.windowWidth}
                    windowHeight={props.windowHeight}
                ></DetailsAdaptive>
            </CoherencePanel>
        </Stack>
    );
}

const connected = withContext(DetailsPanel);
export { connected as DetailsPanel };
