import { IStackStyles, IStackItemStyles } from '@fluentui/react/lib/Stack';
import { Text } from '@fluentui/react/lib/Text';
import { MessagingColors, TextColors } from '../../Shared/Styles/SharedColors';
//import { IMessageBarStyles } from '@fluentui/react/lib/MessageBar';

export const SecondaryHeaderContainer = styled.div`
    height: 48px;

    .ms-Button-menuIcon {
        color: rgb(0, 13, 23) !important;
    }

    @media only screen and (min-device-width: 1023px) and (min-width: 320px) and (max-width: 639px) {
        height: 24px !important;
    }

    @media only screen and (min-device-width: 1023px) and (max-width: 320px) {
        height: 14px !important;
    }

    @media only screen and (min-device-width: 1023px) and (max-width: 1024px) {
        margin-left: 0px !important;
    }
`;

export const SummaryCountText = styled(Text).attrs({
    as: 'p',
})`
    color: ${TextColors.lightPrimary};
    font-weight: bold;
`;

export const SummaryCountLabelText = styled(Text).attrs({
    as: 'p',
})`
    color: ${TextColors.lightPrimary};
    font-weight: regular;
    padding-left: 5px;
`;

export const FailedCountText = styled(Text).attrs({
    as: 'p',
})`
    color: ${MessagingColors.errorBlockIcon};
    font-weight: bold;
`;

export const FailedCountLabelText = styled(Text).attrs({
    as: 'p',
})`
    color: ${MessagingColors.errorBlockIcon};
    padding-left: 5px;
`;

export const SummaryCountStyling: IStackItemStyles = {
    root: {
        width: 'max-content',
    },
};

export const PersonaDropdownStyles: IStackItemStyles = {
    root: {
        marginLeft: '2%',
    },
};

export const GroupAndFilterIconStackItemStyles: IStackItemStyles = {
    root: {
        width: 'max-content',
        selectors: {
            '@media (min-device-width: 1023px) and (min-width: 320px) and (max-width: 639px)': {
                height: 24 + 'px !important'
            },
            '@media (min-device-width: 1023px) and (max-width: 320px)': {
                height: 10 + 'px !important',
                'margin-top': '-8px',
                'font-size': '5px'
            }
        }
    }
};

export const SecondaryHeaderStackStyles = (isPanelOpen: boolean): IStackStyles => ({
    root: {
        height: '48px',
        background: '#e5e5e5',
        width: 'calc(100vw - 48px)',
        zIndex: 50,
        position: 'fixed',
        selectors: {
            '@media (min-device-width: 1023px) and (min-width: 320px) and (max-width: 639px)': {
                height: 24 + 'px !important',
                display: `${isPanelOpen ? 'none' : 'flex'}`
            },
            '@media (min-device-width: 1023px) and (max-width: 320px)': {
                height: 14 + 'px',
                display: `${isPanelOpen ? 'none' : 'flex'}`
            }
        }
    }
});

export const SecondaryHeaderIconStyling = {
    height: '24px',
    paddingTop: '4px'
};
