import { Depths, NeutralColors, FontSizes, FontWeights } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react';

export interface IAdditionalHTMLAttribute {
    windowWidth: number;
    windowHeight: number;
}

export const LargeCard = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: ${Depths.depth4};
    margin-bottom: 16px;
    margin-right: 16px;
    width: 384px;
    max-width: 384px;
    @media only screen and (max-width: 400px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    @media only screen and (max-width: 320px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    height: 528px;
`;

export const SmallCard = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: ${Depths.depth4};
    margin-bottom: 16px;
    margin-right: 16px;
    width: 384px;
    max-width: 384px;
    @media only screen and (max-width: 400px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    @media only screen and (max-width: 320px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    height: 252px;
`;

export const CardHeader = styled.div`
    padding-bottom: 12px;
    padding-top: 12px;
    padding-left: 16px;
    padding-right: 16px;
`;

export const CountBanner = styled.div`
    background-color: #faf9f8;
    height: 148px;
`;

export const AfterBanner = styled.div`
    height: 275px;
    @media only screen and (max-width: 400px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    @media only screen and (max-width: 320px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
`;

export const CountImage = styled.div<any>`
    width: ${(props) => (props.imageUrl ? getIconWidth(props.imageUrl) : 120)}px;
    height: ${(props) => (props.imageUrl ? getIconHeight(props.imageUrl) : 88)}px;
    background-image: url(${(props) => (props.imageUrl ? props.imageUrl : '/images/automated-expense.svg')});
    display: flex;
    flex-direction: row;
    align-self: center;
`;

const getIconHeight = (imageUrl: string) => {
    if (imageUrl === '/images/submitted-expense-count.svg') {
        return 89;
    } else if (imageUrl === '/images/processing-expense-count.svg') {
        return 87;
    }
    // default - also for automated expense and approved images
    return 88;
};

const getIconWidth = (imageUrl: string) => {
    if (imageUrl === '/images/approved-expense-count.svg') {
        return 80;
    } else if (imageUrl === '/images/submitted-expense-count.svg') {
        return 121;
    } else if (imageUrl === '/images/processing-expense-count.svg') {
        return 117;
    } else if (imageUrl === '/images/upload-receipt.svg') {
        return 108;
    }
    // default - also for automated expense image
    return 120;
};

export const CountData = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    max-width: 50%;
`;

export const CountNumber = styled.div`
    font-size: 58px;
    font-weight: ${FontWeights.regular};
    padding-right: 10px;
    padding-top: 12px;
`;

export const SemiboldText = styled.div`
    font-size: ${FontSizes.size14};
    font-weight: ${FontWeights.semibold};
    display: flex;
    flex-direction: row;
    align-self: flex-start;
    padding-top: 40px;
`;

export const CountRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 24px;
    align-items: flex-start;
    height: inherit;
`;

export const HeaderTitleContainer = styled.h3`
    font-size: ${FontSizes.size14};
    font-weight: ${FontWeights.semibold};
    text-overflow: ellipsis;
`;

export const HeaderIconStyling = {
    color: '#171717',
    fontSize: '16px',
};

export const CardBody = styled.div`
    height: 80%;
    @media only screen and (max-width: 400px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    @media only screen and (max-width: 320px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
`;

export const CardBodyText = styled.div`
    padding-left: 8px;
    padding-bottom: 10px;
`;

export const Button = styled.div`
    padding-left: 8px;
`;

export const CardFooter = styled.div`
    padding-left: 10px;
    padding-top: 14px;
`;

export const LinkStyling = styled.div`
    padding-right: 8px;
`;

export const LinkIconStyling = {
    color: '#0064BF',
    fontSize: '16px',
    padding: '0px 8px',
};

export const TextContainer = styled.div`
    height: 100%;
    margin-left: 12px;
    margin-right: 12px;
    padding-bottom: 1%
    position: relative;
`;

export const Submitter = styled.div`
    font-size: ${FontSizes.size16};
    font-weight: ${FontWeights.semibold};
`;

export const Date = styled.div`
    font-size: ${FontSizes.size12};
`;

export const CompanyCode = styled.div`
    font-size: ${FontSizes.size12};
`;

export const DateRow = styled.div`
    height: 22.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;
export const UnitValueRow = styled.div`
    height: 37.5%;
    padding-top: 0.5%;
    display: flex;
    flex-direction: row;
    align-items: top;
`;

export const UnitValue = styled.div`
    font-size: ${FontSizes.size22};
    font-weight: ${FontWeights.regular};
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    margin-right: 5px;
    max-width: 70%;
`;

export const UnitofMeasure = styled.div`
    font-size: ${FontSizes.size14};
    padding-top: 3%;
    max-width: 30%;
`;

export const Title = styled.div`
    height: 15%;
    font-weight: ${FontWeights.regular};
    padding-bottom: 0.5%;
`;

export const SecondaryTitleContainer = styled.div<any>`
    height: 10%;
    font-weight: ${(props) => (!props.isRead ? FontWeights.regular : FontWeights.semilight)};
    font-size: ${FontSizes.size12};
    vertical-align: text-top;
`;

export const DisplayDocumentNumber = styled.div<any>`
    height: 15%;
    font-size: ${FontSizes.size14};
    font-weight: ${(props) => (!props.isRead ? FontWeights.semibold : FontWeights.regular)};
`;

export const Desc = styled.div`
    color: ${NeutralColors.gray200};
    font-size: ${FontSizes.size18};
    line-height: 1.5;
    padding-right: 16px;
`;

export const HeaderTenantIcon = styled.div`
    padding-right: 6px;
    padding-top: 6px;
`;

export const HeaderIcons = styled.div`
    align-items: center;
    display: flex;
    flex-direction row;
    justify-content: flex-end;
    width: 15%;
`;

export const Styles = mergeStyleSets({
    text: {
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
});

export const Footer = styled.div`
    padding-top: 2%;
    margin-bottom: 2%;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const CenterItemStyling = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const QuickLinksImageStyling = {
    paddingBottom: `14px`,
    paddingTop: `14px`,
};

export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: calc(3vw);
    margin-right: calc(3vw);
`;

export const ExpenseTileRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 55px;
    border-left: 2px solid #0078D4;
    padding-left: 10px;
    @media only screen and (max-width: 400px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    @media only screen and (max-width: 320px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
`;

export const ReportColumn = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 70%;
    max-height: 100%;
    border-right: ;
`;

export const TitleText = styled.div`
    font-weight: ${FontWeights.regular};
    padding-bottom: 0.5%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 40%;
`;

export const IconSection = styled.div`
    display: flex;
    flex-direction: row;
`;

export const AutomatedExpenseContainer = styled.div`
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
`;

export const ErrorInAutomatedCardContainer = styled.div`
    padding-left: 10px;
    padding-right: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media only screen and (max-width: 400px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    @media only screen and (max-width: 320px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
`;

export const QuickLinksImage = styled.div`
    height: 127px;
    width: 280px;
    margin-bottom: 14px;
    margin-top: 14px;
    background-image: url('/images/quick-links.svg');
    @media only screen and (max-width: 400px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
    @media only screen and (max-width: 320px) {
        width: ${(props: IAdditionalHTMLAttribute) => props.windowWidth - 32}px;
    }
`;

export const TrainingMaterialsImage = styled.div`
    height: 145px;
    width: 382px;
    margin-bottom: 14px;
    margin-top: 14px;
    @media only screen and (max-width: 400px) {
        margin-left: -96px;
    }
    @media only screen and (max-width: 320px) {
        margin-left: -96px;
    }
    background-image: url('/images/training.svg');
`;

export const NoExpensesText = styled.div`
    color: ${NeutralColors.gray140};
    font-size: ${FontSizes.size14};
    line-height: 1.5;
    padding-right: 16px;
    width: fit-content;
    margin: 0 auto;
    padding-top: 12px;
`;
