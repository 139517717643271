import * as React from 'react';
import { connect } from 'react-redux';
import * as Styled from './ExpenseCard.styled';
import Text from 'react-texty';
import 'react-texty/styles.css';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { imitateClickOnKeyPressForDiv } from '../../../Helpers/sharedHelpers';
import { PersonaSize } from '@micro-frontend-react/employee-experience/lib/Persona';
import { trackBusinessProcessEvent, TrackingEventId } from '../../../Helpers/telemetryHelpers';
import { TooltipHost } from '@fluentui/react';
import { IExpenseCardProps } from './ExpenseCard.types';
import { SubmitterPersona } from '../../../Shared/Components/SubmitterPersona';
import { isRequestCurrentlySelected } from '../../ExpenseDetails/Details.selectors';
import {
    updateMyRequest,
    requestMyDetails,
    submitExpenseDetails,
    submitExpenseDetailsFailed,
    clearDocumentPreview,
    closeDocumentPreview,
} from '../../ExpenseDetails/Details.actions';
import { updatePanelState } from '../../../Shared/Store/Expense.actions';
import { IDetailsAppState, IDetailsState } from '../../ExpenseDetails/Details.types';
import { detailsReducerName, detailsInitialState } from '../../ExpenseDetails/Details.reducer';
import { getStateCommonTelemetryProperties } from '../../../Shared/Store/Expense.selectors';

function ExpenseCardBase(props: IExpenseCardProps): React.ReactElement {
    const {
        cardRef,
        SubmitterAlias,
        SubmitterName,
        ReportName,
        StatusImg,
        ExpenseStatus,
        UnitValue,
        UnitofMeasure,
        ReportNumber,
        SubmittedDate,
    } = props.cardInfo;
    const formattedDate = SubmittedDate.toString();
    const formattedUnitValue = isNaN(Number(UnitValue))
        ? UnitValue
        : Number(UnitValue).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const { useSelector, telemetryClient, authClient, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const isSelectedInState = useSelector((state: any) => isRequestCurrentlySelected(state, ReportNumber));
    const { isPreviewOpen } = useSelector(
        (state: IDetailsAppState) => state.dynamic?.[detailsReducerName] || detailsInitialState
    );

    const stateCommonProperties = useSelector(getStateCommonTelemetryProperties);

    function renderIcon() {
        return <img style={Styled.StatusImageIconStyle} src={StatusImg} alt={ExpenseStatus + ' icon'} />;
    }

    function detailCallPerf(): void {
        if (!isSelectedInState) {
            const actionType = ExpenseStatus == 'Draft' ? 'editAction' : 'view';
            if (isPreviewOpen) {
                dispatch(clearDocumentPreview());
                dispatch(closeDocumentPreview());
            }
            dispatch(updateMyRequest(ReportNumber, actionType, ExpenseStatus));
            dispatch(updatePanelState(true));
            dispatch(requestMyDetails(ReportNumber, ExpenseStatus));
        }
    }

    function handleClickwithLogging(ev: any): void {
        detailCallPerf();

        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Expense card clicked for report number: ' + ReportNumber,
            'Expense-Web.ExpenseCardClicked',
            TrackingEventId.ExpenseCardClicked,
            stateCommonProperties
        );
    }

    return (
        <Styled.Card
            id={cardRef}
            onClick={handleClickwithLogging}
            role="button"
            tabIndex={0}
            onKeyPress={imitateClickOnKeyPressForDiv(() => detailCallPerf())}
            isSelected={isSelectedInState}
        >
            <Styled.CardHeader>
                <Styled.Header>
                    <SubmitterPersona emailAlias={SubmitterAlias} size={PersonaSize.size32} />
                    <Styled.HeaderTitleContainer className={Styled.Styles.text}>
                        <Styled.StrongHeaderTitle>
                            <TooltipHost content={SubmitterName} aria-label="Header">
                                {SubmitterName}
                            </TooltipHost>
                        </Styled.StrongHeaderTitle>
                    </Styled.HeaderTitleContainer>
                    <Styled.HeaderIcons>{renderIcon()}</Styled.HeaderIcons>
                </Styled.Header>
            </Styled.CardHeader>
            <Styled.CardBody>
                <Styled.TextContainer>
                    <Styled.Title>
                        {' '}
                        <Text tooltipMaxWidth={200}>{ReportName}</Text>{' '}
                    </Styled.Title>
                    <Styled.UnitValueRow>
                        {UnitValue && (
                            <Styled.UnitValue>
                                <Text tooltipMaxWidth={200}>{formattedUnitValue}</Text>
                            </Styled.UnitValue>
                        )}
                        {UnitofMeasure && (
                            <Styled.UnitofMeasure>
                                <Text tooltipMaxWidth={200}>{UnitofMeasure}</Text>
                            </Styled.UnitofMeasure>
                        )}
                    </Styled.UnitValueRow>
                    {ReportNumber && (
                        <Styled.DisplayDocumentNumber>
                            <Text tooltipMaxWidth={200}>{ReportNumber}</Text>
                        </Styled.DisplayDocumentNumber>
                    )}
                    <Styled.DateRow>{formattedDate && <Styled.Date> {formattedDate}</Styled.Date>}</Styled.DateRow>
                </Styled.TextContainer>
            </Styled.CardBody>
        </Styled.Card>
    );
}

const mapStateToProps = (state: IDetailsAppState): IDetailsState => {
    if (state.dynamic[detailsReducerName]) {
        return state.dynamic[detailsReducerName];
    } else {
        return detailsInitialState;
    }
};

const connected = withContext(
    connect(mapStateToProps, {
        submitExpenseDetailsFailed,
        submitExpenseDetails,
        updateMyRequest,
        updatePanelState,
        requestMyDetails,
        clearDocumentPreview,
        closeDocumentPreview,
    })(ExpenseCardBase)
);
export { connected as ExpenseCard };
