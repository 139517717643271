import { IReceiptBankState } from './ReceiptUpload.types';
import { ReceiptBankAction, ReceiptBankActionType } from './ReceiptUpload.action-types';

export const receiptBankReducerName = 'ReceiptBankReducer';
export const receiptBankInitialState: IReceiptBankState = {
    isPanelOpen: false,
    receiptUploadHasError: false,
    receiptToUpload: null,
    receiptUploadResponseMessage: null,
    isUploadingReceipt: false
};

export function receiptBankReducer(
    prev: IReceiptBankState = receiptBankInitialState,
    action: ReceiptBankAction
): IReceiptBankState {
    switch (action.type) {
        case ReceiptBankActionType.UPDATE_UPLOAD_PANEL_STATE:
            return {
                ...prev,
                isPanelOpen: action.isUploadPanelOpen,
            };
        case ReceiptBankActionType.UPLOAD_RECEIPT:
            return {
                ...prev,
                receiptToUpload: action.receiptToUpload,
                isUploadingReceipt: true
            };
        case ReceiptBankActionType.UPLOAD_RECEIPT_SUCCESS:
            return {
                ...prev,
                receiptUploadResponseMessage: action.responseMessage,
                isUploadingReceipt: false,
                receiptUploadHasError: false
            };
        case ReceiptBankActionType.FAILED_RECEIPT_UPLOAD:
            return {
                ...prev,
                receiptUploadResponseMessage: action.errorMessage,
                isUploadingReceipt: false,
                receiptUploadHasError: true,
                receiptToUpload: null
            };
        case ReceiptBankActionType.RESET_RECEIPT_UPLOAD_STATE:
            return {
                ...prev,
                receiptUploadHasError: false,
                receiptToUpload: null,
                receiptUploadResponseMessage: null,
                isUploadingReceipt: false
            };
        default:
            return prev;
    }
}
