import * as React from 'react';
import { Stack } from '@fluentui/react';
import * as Styled from './CardStyling.styled';

export const getHeader = (headerTitle: string): JSX.Element => {
    return (
        <Styled.CardHeader>
            <Stack horizontal horizontalAlign="space-between" verticalAlign="center">
                <Stack.Item>
                    <Styled.HeaderTitleContainer>{headerTitle}</Styled.HeaderTitleContainer>
                </Stack.Item>
            </Stack>
        </Styled.CardHeader>
    );
}
