import { OperationType } from '../../Components/Pages/ManageDelegates/ManageDelegatesConstants';
import {
    ExpenseActionType,
    IRequestProfileAction,
    IReceiveProfileAction,
    IFailedProfileAction,
    IRequestSummaryAction,
    IReceiveSummaryAction,
    IFailedSummaryAction,
    IUpdatePanelState,
    IDetailsDefaultView,
    IToggleDetailsScreenMax,
    IUpdateGroupedSummary,
    ISetContinuationToken,
    ISetContinuationTokenDraft,
    IUpdateShowSecondaryHeader,
    IUpdateSettingsPageShowing,
    IRequestFilteredUsersAction,
    IReceiveFilteredUsersAction,
    IUpdatePeoplePickerHasError,
    ISaveUserPreferences,
    ISaveUserPreferencesSuccess,
    ISaveUserPreferencesFailed,
    IRequestUserPreferences,
    IReceiveUserPreferences,
    IUpdateDropdownHasError,
    IUserPreferencesResponseObj,
    IUpdateExpenseAutomationHasError,
    IReceiveSubmitterImages,
    IRequestDrafts,
    IUpdateDeletedReports,
    IUpdateSubmittedReports,
    IRequestExpenseCountStats,
    IReceiveExpenseCountStats,
    IFailedExpenseCountStats,
    ISaveUserPreferencesPayload,
    IUpdateFilterValue,
    IUpdateIsComingFromDashboard,
    IRequestDelegatesAction,
    IReceiveDelegatesAction,
    IModifyDelegateAction,
    IModifyDelegateActionSuccess,
    IModifyDelegateActionFailed,
    IHideDelegateDialog,
    IReceiveDelegateActionFailed,
} from './Expense.action-types';
import { IProfile, IGraphPhoto, IStats, IExpenseDelegateDetails } from './Expense.types';

export function setContinuationToken(continuationToken: string): ISetContinuationToken {
    return {
        type: ExpenseActionType.SET_CONTINUATION_TOKEN,
        continuationToken,
    };
}

export function setContinuationTokenDraft(continuationTokenDraft: string): ISetContinuationTokenDraft {
    return {
        type: ExpenseActionType.SET_CONTINUATION_TOKEN_DRAFT,
        continuationTokenDraft,
    };
}

export function requestMyProfile(featureName?: string): IRequestProfileAction {
    return {
        type: ExpenseActionType.REQUEST_MY_PROFILE,
        featureName,
    };
}

export function failedProfile(profileErrorMessage: string): IFailedProfileAction {
    return {
        type: ExpenseActionType.FAILED_PROFILE,
        profileErrorMessage,
    };
}

export function receiveMyProfile(profile: IProfile): IReceiveProfileAction {
    return {
        type: ExpenseActionType.RECEIVE_MY_PROFILE,
        profile,
    };
}

export function requestMySummary(
    continuationToken: string,
    continuationTokenDraft: string,
    firstCall: boolean,
    featureName: string,
    draftsOnly?: boolean
): IRequestSummaryAction {
    return {
        type: ExpenseActionType.REQUEST_MY_SUMMARY,
        continuationToken,
        continuationTokenDraft,
        firstCall,
        featureName,
        draftsOnly,
    };
}

export function receiveMySummary(expenseData: any, isFirstCall?: boolean): IReceiveSummaryAction {
    return {
        type: ExpenseActionType.RECEIVE_MY_SUMMARY,
        expenseData,
        isFirstCall,
    };
}

export function failedSummary(summaryErrorMessage: string): IFailedSummaryAction {
    return {
        type: ExpenseActionType.FAILED_SUMMARY,
        summaryErrorMessage,
    };
}

export function updatePanelState(isOpen: boolean): IUpdatePanelState {
    return {
        type: ExpenseActionType.UPDATE_PANEL_STATE,
        isOpen,
    };
}

export function DetailsDefaultView(defaultView: string): IDetailsDefaultView {
    return {
        type: ExpenseActionType.SET_DETAILS_DEFAULT_VIEW,
        defaultView,
    };
}

export function ToggleDetailsScreenMax(): IToggleDetailsScreenMax {
    return {
        type: ExpenseActionType.TOGGLE_DETAILS_SCREEN_MAX,
    };
}

export function updateGroupedSummary(summaryGroupedBy: string): IUpdateGroupedSummary {
    return {
        type: ExpenseActionType.UPDATE_GROUPED_SUMMARY,
        summaryGroupedBy,
    };
}

export function updateShowSecondaryHeader(showSecondaryHeader: boolean): IUpdateShowSecondaryHeader {
    return {
        type: ExpenseActionType.UPDATE_SHOW_SECONDARY_HEADER,
        showSecondaryHeader,
    };
}

export function updateSettingsPageShowing(settingsPageShowing: string): IUpdateSettingsPageShowing {
    return {
        type: ExpenseActionType.UPDATE_SETTINGS_PAGE_SHOWING,
        settingsPageShowing,
    };
}

export function receiveSubmitterImages(submitterImages: IGraphPhoto[]): IReceiveSubmitterImages {
    return {
        type: ExpenseActionType.RECEIVE_SUBMITTER_IMAGES,
        submitterImages,
    };
}

export function requestMyDrafts(): IRequestDrafts {
    return {
        type: ExpenseActionType.REQUEST_MY_DRAFTS,
    };
}

export function updateDeletedReports(reportId: string): IUpdateDeletedReports {
    return {
        type: ExpenseActionType.UPDATE_DELETED_REPORTS,
        reportId,
    };
}

export function updateSubmittedReports(reportId: string): IUpdateSubmittedReports {
    return {
        type: ExpenseActionType.UPDATE_SUBMITTED_REPORTS,
        reportId,
    };
}

export function requestExpenseStatCounts(featureName: string): IRequestExpenseCountStats {
    return {
        type: ExpenseActionType.REQUEST_EXPENSE_STAT_COUNTS,
        featureName,
    };
}

export function receiveExpenseStatCounts(expenseCounts: IStats): IReceiveExpenseCountStats {
    return {
        type: ExpenseActionType.RECEIVE_EXPENSE_STAT_COUNTS,
        expenseCounts,
    };
}

export function failedExpenseStatCounts(errorMessage: string, defaultCounts: IStats): IFailedExpenseCountStats {
    return {
        type: ExpenseActionType.FAILED_EXPENSE_STAT_COUNTS,
        errorMessage,
        defaultCounts,
    };
}

export function requestFilteredUsers(filterText: string): IRequestFilteredUsersAction {
    return {
        type: ExpenseActionType.REQUEST_FILTERED_USERS,
        filterText,
    };
}

export function receiveFilteredUsers(filteredUsers: object[]): IReceiveFilteredUsersAction {
    return {
        type: ExpenseActionType.RECEIVE_FILTERED_USERS,
        filteredUsers,
    };
}

export function updatePeoplePickerHasError(peoplePickerHasError: boolean): IUpdatePeoplePickerHasError {
    return {
        type: ExpenseActionType.UPDATE_PEOPLEPICKER_HASERROR,
        peoplePickerHasError,
    };
}

export function updateDropdownHasError(dropdownHasError: boolean): IUpdateDropdownHasError {
    return {
        type: ExpenseActionType.UPDATE_DROPDOWN_HASERROR,
        dropdownHasError,
    };
}

export function updateExpenseAutomationHasError(choiceGroupHasError: boolean): IUpdateExpenseAutomationHasError {
    return {
        type: ExpenseActionType.UPDATE_EXPENSE_AUTOMATION_HASERROR,
        choiceGroupHasError,
    };
}

export function saveUserPreferences(userPreferences?: ISaveUserPreferencesPayload): ISaveUserPreferences {
    return {
        type: ExpenseActionType.SAVE_USER_PREFERENCES,
        userPreferences,
    };
}

export function saveUserPreferencesSuccess(responseMessages: string[]): ISaveUserPreferencesSuccess {
    return {
        type: ExpenseActionType.SAVE_USER_PREFERENCES_SUCCESS,
        responseMessages,
    };
}

export function saveUserPreferencesFailed(responseMessages: string[]): ISaveUserPreferencesFailed {
    return {
        type: ExpenseActionType.SAVE_USER_PREFERENCES_FAILED,
        responseMessages,
    };
}

export function requestUserPreferences(): IRequestUserPreferences {
    return {
        type: ExpenseActionType.REQUEST_USER_PREFERENCES,
    };
}

export function ReceiveUserPreferences(userPreferences: IUserPreferencesResponseObj): IReceiveUserPreferences {
    return {
        type: ExpenseActionType.RECEIVE_USER_PREFERENCES,
        userPreferences,
    };
}

export function updateFilterValue(filterValue: string): IUpdateFilterValue {
    return {
        type: ExpenseActionType.UPDATE_FILTER_VALUE,
        filterValue,
    };
}

export function updateIsComingFromDashboard(isComingFromDashboard: boolean): IUpdateIsComingFromDashboard {
    return {
        type: ExpenseActionType.UPDATE_IS_COMING_FROM_DASHBOARD,
        isComingFromDashboard,
    };
}

export function requestDelegates(delegateType: string): IRequestDelegatesAction {
    return {
        type: ExpenseActionType.REQUEST_DELEGATES,
        delegateType,
    };
}

export function receiveDelegates(delegatesList: IExpenseDelegateDetails[]): IReceiveDelegatesAction {
    return {
        type: ExpenseActionType.RECEIVE_DELEGATES,
        delegatesList,
    };
}

export function receiveDelegateFailed(receiveDelegateListErrorMessage: string): IReceiveDelegateActionFailed {
    return {
        type: ExpenseActionType.RECEIVE_DELEGATE_FAILED,
        receiveDelegateListErrorMessage,
    };
}

export function modifyDelegate(delegate: IExpenseDelegateDetails, operation: OperationType): IModifyDelegateAction {
    return {
        type: ExpenseActionType.MODIFY_DELEGATE,
        delegate,
        operation,
    };
}

export function modifyDelegateSuccess(modifyDelegateSuccessMessage: string): IModifyDelegateActionSuccess {
    return {
        type: ExpenseActionType.MODIFY_DELEGATE_SUCCESS,
        modifyDelegateSuccessMessage,
    };
}

export function modifyDelegateFailed(modifyDelegateErrorMessage: string): IModifyDelegateActionFailed {
    return {
        type: ExpenseActionType.MODIFY_DELEGATE_FAILED,
        modifyDelegateErrorMessage,
    };
}

export function hideManageDelegateDialog(): IHideDelegateDialog {
    return {
        type: ExpenseActionType.HIDE_DELEGATE_DIALOG,
    };
}
