import * as React from 'react';
import { Link, Stack, Spinner } from '@fluentui/react';
import { Reducer } from 'redux';
import { updateShowSecondaryHeader } from '../../../../Shared/Store/Expense.actions';
import { sharedExpenseSagas } from '../../../../Shared/Store/Expense.sagas';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseReducerName, expenseReducer, expenseInitialState } from '../../../../Shared/Store/Expense.reducer';
import * as Styled from './CardHelpers/CardStyling.styled';
import * as SharedStyled from '../../../../Shared/Styles/SharedLayout.styled';
import { IExpenseAppState } from '../../../../Shared/Store/Expense.types';
import * as CardHelpers from './CardHelpers/CardHelpers';
import * as QuickLinks from './CardHelpers/QuickLinksData';
import { FontIcon } from '@fluentui/react';
import * as Helpers from '../../../../Helpers/sharedHelpers';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export function QuickLinksCard(): React.ReactElement {
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { profile, isLoadingProfile } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    React.useEffect(() => {
        dispatch(updateShowSecondaryHeader(false));
    }, [dispatch]);

    const getLink = (link: any) => {
        return (
            <Styled.LinkStyling>
                <Stack horizontal verticalAlign="center" styles={{ root: { paddingBottom: '14px' } }}>
                    <Stack.Item>
                        <FontIcon iconName="Link" role="presentation" style={Styled.LinkIconStyling} />
                    </Stack.Item>
                    {link.link ? (
                        <Stack.Item>
                            <Link href={link.link} target={link.target} title={link.text}>
                                {link.text}
                            </Link>
                        </Stack.Item>
                    ) : (
                        <Link href="#" onClick={Helpers.launchBot} target={link.target} title={link.text}>
                            {link.text}
                        </Link>
                    )}
                </Stack>
            </Styled.LinkStyling>
        );
    };

    const getLinks = () => {
        // default to MyExpense if expense tool isn't specified
        const expenseTool = profile ? profile.expenseTool : 'MYEXPENSE';
        let quickLinks;
        if (expenseTool === 'CONCUR') {
            quickLinks = QuickLinks.ConcurQuickLinks;
        } else if (expenseTool === 'EXPENSE2') {
            quickLinks = QuickLinks.MSExpense2QuickLinks;
        } else {
            quickLinks = QuickLinks.MyExpenseQuickLinks;
        }
        return <div>{quickLinks.map((link, index) => getLink(link))}</div>;
    };

    return (
        <Styled.LargeCard windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
            {CardHelpers.getHeader('Quick Links')}
            <SharedStyled.Divider />
            <Styled.CardBody windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
                <Styled.CenterItemStyling>
                    <Styled.QuickLinksImage windowHeight={window.innerHeight} windowWidth={window.innerWidth} />
                </Styled.CenterItemStyling>
                {isLoadingProfile && (
                    <SharedStyled.SpinnerContainer>
                        <Spinner label="Loading links" />
                    </SharedStyled.SpinnerContainer>
                )}
                {!isLoadingProfile && getLinks()}
            </Styled.CardBody>
        </Styled.LargeCard>
    );
}
