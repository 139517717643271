import { AuthClient } from '@micro-frontend-react/employee-experience/lib/AuthClient';
import { TelemetryClient } from '@micro-frontend-react/employee-experience/lib/TelemetryClient';
import { HttpClient } from '@micro-frontend-react/employee-experience/lib/HttpClient';
import { GraphClient } from '@micro-frontend-react/employee-experience/lib/GraphClient';
import { Shell } from '@micro-frontend-react/employee-experience/lib/Shell';
import { withStore } from '@micro-frontend-react/employee-experience/lib/withStore';
import { StoreBuilder } from '@micro-frontend-react/employee-experience/lib/StoreBuilder';
import { ReducerRegistry } from '@micro-frontend-react/employee-experience/lib/ReducerRegistry';
import sessionStorage from 'redux-persist/lib/storage/session';

const telemetryClient = new TelemetryClient({
  instrumentationKey: __INSTRUMENTATION_KEY__,
  UTPConfig: {
    EnvironmentName: __ENV_NAME__,
    ServiceOffering: 'Expense Service Offering',
    ServiceLine: 'Expense Service Line',
    Service: 'Expense Service',
    ComponentName: 'Expense Component',
    ComponentId: '',
  },
  defaultProperties: {
    appName: __APP_NAME__,
  },
});

const authClient = new AuthClient(
  {
    auth: {
      clientId: __CLIENT_ID__,
      redirectUri: window.location.origin,
      authority: __AUTHORITY__,
    },
  },
  telemetryClient
);

const httpClient = new HttpClient(telemetryClient, authClient);
const graphClient = new GraphClient(httpClient);

const blacklistDynamicConfig = {
  storage: sessionStorage,
  blacklist: ['dynamic'],
};

const reducerRegistry = new ReducerRegistry();
const appName = 'OneExpense';
const storeResult = new StoreBuilder(reducerRegistry, {})
  .configureLogger(true)
  .configureSaga({ telemetryClient, authClient, httpClient, graphClient, appName })
  .configurePersistor(blacklistDynamicConfig)
  .build();

export const ShellWithStore = withStore(storeResult)(Shell);
