import { SimpleEffect, getContext, put, all, call, takeLatest, select } from 'redux-saga/effects';
import { IHttpClient, IHttpClientRequest } from '@micro-frontend-react/employee-experience/lib/IHttpClient';
import {
    DetailsActionType,
    IRequestAllDocumentsAction,
    IRequestDetailsAction,
    IRequestDocumentAction,
    IRequestDocumentPreviewAction,
    IRequestStatFormExpenseDetails,
    ISubmitExpenseDetails,
} from './Details.action-types';
import {
    receiveMyExpenseDetails,
    failedDetails,
    receiveDocumentPreview,
    submitExpenseDetailsSuccess,
    submitExpenseDetailsFailed,
    responseStatFormExpenseDetails,
    failedStatFormExpenseDetails,
    receiveUserImage,
    failedUserImage,
    requestDocumentEnd,
    failedDocumentDownload,
    requestAllDocumentsEnd,
    failedAllDocumentDownload,
    failedDocumentPreview,
} from './Details.actions';
import { getStateCommonTelemetryProperties } from '../../Shared/Store/Expense.selectors';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Helpers/telemetryHelpers';
import { convertToUPN } from '../../Helpers/sharedHelpers';
import { Buffer } from 'buffer';
import { ITelemetryClient } from '@micro-frontend-react/employee-experience/lib/ITelemetryClient';
import { IAuthClient } from '@micro-frontend-react/employee-experience/lib/IAuthClient';

function* fetchAdaptiveDetails(action: IRequestDetailsAction): IterableIterator<SimpleEffect<{}, {}>> {
    const authClient: IAuthClient = yield getContext('authClient');
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);

    try {
        let url = '';
        let isDraft = action.expenseStatus === 'Draft';
        let dataResponse;

        if (isDraft) {
            url = `${__API_BASE_URL__}/expense/details/draft/${action.expenseReportId}`;
        } else {
            url = `${__API_BASE_URL__}/expense/details/${action.expenseReportId}`;
        }

        const httpClient: IHttpClient = yield getContext('httpClient');
        const { data }: any = yield call([httpClient, httpClient.request], {
            url,
            resource: __RESOURCE_URL__,
        });

        if (data.statusCode == 'Success') {
            yield put(receiveMyExpenseDetails(data.response));
            trackBusinessProcessEvent(
                authClient,
                telemetryClient,
                'Load Expense Details - Success',
                'Expense-Web.LoadExpenseDetails.Success',
                TrackingEventId.LoadExpenseDetailsSuccess,
                stateCommonProperties
            );
        } else if (data.statusCode == 'NotFound') {
            yield put(failedDetails(data.message));
            const exception = new Error(data.message);
            trackException(
                authClient,
                telemetryClient,
                'Load Expense Details - Failure',
                'Expense-Web.LoadExpenseDetails.Failure',
                TrackingEventId.LoadExpenseDetailsFailure,
                stateCommonProperties,
                exception
            );
        }
        try {
            const userAlias = data.response?.header?.submitter?.alias;
            const { data: userImage }: IHttpClientRequest = yield call([httpClient, httpClient.request], {
                url: `${__GRAPH_BASE_URL__}users/${convertToUPN(userAlias)}/photos/96x96/$value`,
                resource: __GRAPH_RESOURCE_URL__,
                responseType: 'arraybuffer',
            });
            // convert to base64
            let charConversion = '';
            const byteArrayCopy = new Uint8Array(userImage);
            for (let i = 0; i < byteArrayCopy.byteLength; i++) {
                charConversion += String.fromCharCode(byteArrayCopy[i]);
            }
            const base64Version = btoa(charConversion);

            yield put(receiveUserImage(base64Version));
            trackBusinessProcessEvent(
                authClient,
                telemetryClient,
                'Load User Image - Success',
                'MSExpense.LoadUserImage.Success',
                TrackingEventId.LoadUserImageSuccess,
                stateCommonProperties
            );
        } catch (errorResponse) {
            const error = errorResponse.data ?? errorResponse;
            yield put(failedUserImage(error.message ? error.message : error));
            const exception = error.message ? new Error(error.message) : error;
            trackException(
                authClient,
                telemetryClient,
                'Load User Image - Failure',
                'MSExpense.LoadUserImage.Failure',
                TrackingEventId.LoadUserImageFailure,
                stateCommonProperties,
                exception
            );
        }
    } catch (error) {
        const exception = error.message ? new Error(error.message) : error;
        yield put(failedDetails(exception));
        trackException(
            authClient,
            telemetryClient,
            'Load Expense Details - Failure',
            'Expense-Web.LoadExpenseDetails.Failure',
            TrackingEventId.LoadExpenseDetailsFailure,
            stateCommonProperties,
            exception
        );
    }
}

function* fetchAllDocuments(action: IRequestAllDocumentsAction): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);

    try {
        for (let attachment of action.attachmentArray) {
            try {
                const httpClient: IHttpClient = yield getContext('httpClient');
                const fileBytes: any = yield call([httpClient, httpClient.request], {
                    url: `${__API_BASE_URL__}/receipt/${attachment.id}`,
                    resource: __RESOURCE_URL__,
                    responseType: 'arraybuffer',
                });

                const id = action.actionId;
                if (id.includes('download')) {
                    let charConversion = '';
                    const byteArrayCopy = new Uint8Array(fileBytes.data);
                    for (let i = 0; i < byteArrayCopy.byteLength; i++) {
                        charConversion += String.fromCharCode(byteArrayCopy[i]);
                    }
                    const base64Version = btoa(charConversion);

                    let file = Buffer.from(base64Version, 'base64');
                    const blob = new Blob([file], { type: 'application/octet-stream' });
                    const link = document.createElement('a');
                    const objectUrl = URL.createObjectURL(blob);
                    link.setAttribute('target', '_blank');
                    link.setAttribute('href', objectUrl);
                    link.setAttribute('download', attachment.name);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    trackBusinessProcessEvent(
                        authClient,
                        telemetryClient,
                        'Load Expense Receipt download - Success',
                        'Expense-Web.LoadExpenseReceiptDownload.Success',
                        TrackingEventId.LoadExpenseReceiptDownloadSuccess,
                        stateCommonProperties
                    );
                }
            } catch (errorResponse) {
                const error = errorResponse.data ?? errorResponse;
                yield put(failedAllDocumentDownload(error.message ? error.message : 'Unable to download attachment'));
                const exception = error.message ? new Error(error.message) : error;
                trackException(
                    authClient,
                    telemetryClient,
                    'Load Expense Receipt Download - Failure',
                    'Expense-Web.LoadExpenseReceiptDownload.Failure',
                    TrackingEventId.LoadExpenseReceiptDownloadFailure,
                    stateCommonProperties,
                    exception
                );
            }
        }
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load Expense All Receipts Download - Success',
            'Expense-Web.AllLoadExpenseReceiptDownload.Success',
            TrackingEventId.AllLoadExpenseReceiptDownloadSuccess,
            stateCommonProperties
        );
        yield put(requestAllDocumentsEnd());
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse;
        yield put(failedAllDocumentDownload(error.message ? error.message : 'Unable to download attachments'));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load Expense All Receipts Download - Failure',
            'Expense-Web.AllLoadExpenseReceiptDownload.Failure',
            TrackingEventId.AllLoadExpenseReceiptDownloadFailure,
            stateCommonProperties,
            exception
        );
    }
}

function* fetchDocument(action: IRequestDocumentAction): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);

    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const fileBytes: any = yield call([httpClient, httpClient.request], {
            url: `${__API_BASE_URL__}/receipt/${action.attachmentId}`,
            resource: __RESOURCE_URL__,
            responseType: 'arraybuffer',
        });

        yield put(requestDocumentEnd());
        const id = action.actionId;
        if (id.includes('download')) {
            let charConversion = '';
            const byteArrayCopy = new Uint8Array(fileBytes.data);
            for (let i = 0; i < byteArrayCopy.byteLength; i++) {
                charConversion += String.fromCharCode(byteArrayCopy[i]);
            }
            const base64Version = btoa(charConversion);

            let file = Buffer.from(base64Version, 'base64');
            const blob = new Blob([file], { type: 'application/octet-stream' });
            const link = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            link.setAttribute('target', '_blank');
            link.setAttribute('href', objectUrl);
            link.setAttribute('download', action.attachmentName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            trackBusinessProcessEvent(
                authClient,
                telemetryClient,
                'Load Expense Receipt download - Success',
                'Expense-Web.LoadExpenseReceiptDownload.Success',
                TrackingEventId.LoadExpenseReceiptDownloadSuccess,
                stateCommonProperties
            );
        }
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse;
        yield put(failedDocumentDownload(error.message ? error.message : 'Unable to download receipt'));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load Expense Receipt download - Failure',
            'Expense-Web.LoadExpenseReceiptDownload.Failure',
            TrackingEventId.LoadExpenseReceiptDownloadFailure,
            stateCommonProperties,
            exception
        );
    }
}

function* fetchDocumentPreview(action: IRequestDocumentPreviewAction): IterableIterator<SimpleEffect<{}, {}>> {
    const authClient: IAuthClient = yield getContext('authClient');
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);

    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        const fileBytes: any = yield call([httpClient, httpClient.request], {
            url: `${__API_BASE_URL__}/receipt/${action.attachmentId}`,
            resource: __RESOURCE_URL__,
            responseType: 'arraybuffer',
        });

        let charConversion = '';
        const byteArrayCopy = new Uint8Array(fileBytes.data);
        for (let i = 0; i < byteArrayCopy.byteLength; i++) {
            charConversion += String.fromCharCode(byteArrayCopy[i]);
        }
        const base64Version = btoa(charConversion);
        yield put(receiveDocumentPreview(base64Version));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load Expense Receipt Preview - Success',
            'Expense-Web.LoadExpenseReceiptDocument.Success',
            TrackingEventId.LoadExpenseReceiptDocumentSuccess,
            stateCommonProperties
        );
    } catch (error) {
        console.error(error);
        yield put(failedDocumentPreview(error.message ? error.message : 'Unable to preview document'));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load Expense Receipt Preview - Failure',
            'Expense-Web.LoadExpenseReceiptDocument.Failure',
            TrackingEventId.LoadExpenseReceiptDocumentFailure,
            stateCommonProperties,
            exception
        );
    }
}

function* submitExpense(action: ISubmitExpenseDetails): IterableIterator<SimpleEffect<{}, {}>> {
    const authClient: IAuthClient = yield getContext('authClient');
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);

    try {
        const httpClient: IHttpClient = yield getContext('httpClient');
        let submitUrl = `${__API_BASE_URL__}/expense/submit`;
        if (action.isDeleteRequest) {
            submitUrl += '?isCancel=true';
        }
        const result: any = yield call([httpClient, httpClient.request], {
            url: submitUrl,
            method: 'POST',
            resource: __RESOURCE_URL__,
            data: action.expenseDetails,
        });

        yield put(submitExpenseDetailsSuccess('Expense successfully submitted'));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Submit Draft Expense - Success',
            'Expense-Web.SubmitDraftExpense.Success',
            TrackingEventId.SubmitDraftExpenseSuccess,
            stateCommonProperties
        );
    } catch (error) {
        yield put(submitExpenseDetailsFailed(error.data.displayMessage));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Submit Draft Expense- Failure',
            'Expense-Web.Expense SubmitDraftExpense.Failure',
            TrackingEventId.SubmitDraftExpenseFailure,
            stateCommonProperties,
            exception
        );
    }
}

function* fetchStatFormExpenseDetails(action: IRequestStatFormExpenseDetails): IterableIterator<SimpleEffect<{}, {}>> {
    const authClient: IAuthClient = yield getContext('authClient');
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);

    try {
        const url = `${__API_BASE_URL__}/expense/details/${action.expenseReportId}`;

        const httpClient: IHttpClient = yield getContext('httpClient');
        const { data }: any = yield call([httpClient, httpClient.request], {
            url,
            resource: __RESOURCE_URL__,
        });
        yield put(responseStatFormExpenseDetails(data.response));
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Load Stat Form - Success',
            'Expense-Web.LoadStatForm.Success',
            TrackingEventId.LoadStatFormSuccess,
            stateCommonProperties
        );
    } catch (error) {
        yield put(failedStatFormExpenseDetails(error.message ? error.message : error));
        const exception = error.message ? new Error(error.message) : error;
        trackException(
            authClient,
            telemetryClient,
            'Load Stat Form - Failure',
            'Expense-Web.LoadStatForm.Failure',
            TrackingEventId.LoadStatFormFailure,
            stateCommonProperties,
            exception
        );
    }
}

export function* detailsSagas(): IterableIterator<{}> {
    yield all([
        takeLatest(DetailsActionType.REQUEST_MY_DETAILS, fetchAdaptiveDetails),
        takeLatest(DetailsActionType.REQUEST_ALL_DOCUMENTS, fetchAllDocuments),
        takeLatest(DetailsActionType.REQUEST_DOCUMENT, fetchDocument),
        takeLatest(DetailsActionType.REQUEST_DOCUMENT_PREVIEW, fetchDocumentPreview),
        takeLatest(DetailsActionType.SUBMIT_EXPENSE_DETAILS, submitExpense),
        takeLatest(DetailsActionType.STAT_FORM_REQUEST_EXPENSE_DETAILS, fetchStatFormExpenseDetails),
    ]);
}
