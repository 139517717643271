import { IButtonProps } from "@fluentui/react";
import { FileSelectorButton } from "../Shared/Components/FileSelectorButton";
import * as React from 'react';
type IFileUploaderProps = IButtonProps & {
	accepts: string;
	callback: React.Dispatch<React.SetStateAction<File | undefined>>;
	usePrimaryButton?: boolean;
};
export const FileUploader: React.FunctionComponent<IFileUploaderProps> = (props) => {
	// a reference to control the uncontrolled input tag
	let key = Math.random().toString(30);
	const hiddenFileInput = React.createRef<HTMLInputElement>();

	// use the ref to catch clicks of the button and pass them through to the <input>
	const handleClick = () => {
		if (hiddenFileInput.current) {
			hiddenFileInput.current.click();
		}
	};

	// respond to changes of the uploaded file
	const handleChange = () => {
		const submittedFiles = hiddenFileInput.current?.files;
		if (submittedFiles && submittedFiles.length > 0) {
			const files: FileList = submittedFiles;
			const file: File = files[0];
			props.callback(file);
			// force react to re-render the hidden input so we can upload same file twice in a row...
			key = Math.random().toString(30);
		}
	};

	return (
		<div>
			<FileSelectorButton
				primary={props.usePrimaryButton}
				text="Choose File to Upload"
				// secondaryText="Limited to 5 MB in size"
				onClick={handleClick}
				iconName="FileImage"
			>
				<input
					key={key}
					type="file"
					data-testid="file-uploader"
					accept={props.accepts}
					multiple={false}
					ref={hiddenFileInput}
					onChange={handleChange}
					style={{ display: "none" }}
				/>
			</FileSelectorButton>
		</div>
	);
};