import { maxWidth, minWidth } from '../../../Styles/Media';

export const DashboardContainer = styled.div`
    padding: 1% 2% 0 2%;
`;

export const DashboardTitle = styled.h1`
    padding-bottom: 1%;
`;

export const DashboardCol = styled.div<{ convertToRowInLowRes?: boolean }>`
    display: flex;
    flex-flow: column wrap;

    ${maxWidth.xxl} {
        flex-flow: ${(props) => (props.convertToRowInLowRes ? 'row wrap' : 'column wrap')};
    }
`;

export const DashboardRow = styled.div`
    display: flex;
    flex-flow: row wrap;
`;
