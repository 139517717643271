import { SimpleEffect, getContext, put, all, call, takeLatest, select } from 'redux-saga/effects';
import { IUploadReceiptAction, ReceiptBankActionType } from './ReceiptUpload.action-types';
import { failedReceiptUpload, uploadReceiptSuccess } from './ReceiptUpload.actions';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../../../../Helpers/telemetryHelpers';
import { v4 as guid } from 'uuid';

import { IHttpClient, IHttpClientResult } from '@micro-frontend-react/employee-experience/lib/IHttpClient';
import { IAuthClient } from '@micro-frontend-react/employee-experience/lib/IAuthClient';
import { ITelemetryClient } from '@micro-frontend-react/employee-experience/lib/ITelemetryClient';
import { getStateCommonTelemetryProperties } from '../../../../../Shared/Store/Expense.selectors';
import { getProfile } from '../../../../../Shared/Store/Expense.selectors';

function* postReceipt(action: IUploadReceiptAction): IterableIterator<SimpleEffect<{}, {}>> {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    const stateCommonProperties = yield select(getStateCommonTelemetryProperties);
    //Check if theses optional params are present else assign default values
    const clientDeviceParam = action?.clientDevice && action?.clientDevice !== "" ? action.clientDevice : "Portal";
    const scanReceiptFlagParam = action?.scanReceiptFlag && action?.scanReceiptFlag !== "" ? action.scanReceiptFlag.toLowerCase() === 'true' : false;
    const uploadToMyExpenseFlagParam = !scanReceiptFlagParam;

    try {
        if (!validFileType(action.receiptToUpload.name)) {
            yield put(failedReceiptUpload('The receipt failed to upload because it is not a supported file type'));
            trackBusinessProcessEvent(
                authClient,
                telemetryClient,
                'Upload receipt to Expense API - File type not supported - Failure',
                'Expense-Web.UploadReceipt.Error',
                TrackingEventId.ReceiptUploadFailure,
                stateCommonProperties
            );
            return;
        }
        if (action.receiptToUpload.size > 20000000) {
            yield put(failedReceiptUpload('The receipt failed to upload because it is larger than 20MB.'));
            trackBusinessProcessEvent(
                authClient,
                telemetryClient,
                'Upload receipt to Expense API - File size exceeded - Failure',
                'Expense-Web.UploadReceipt.Error',
                TrackingEventId.ReceiptUploadFailure,
                stateCommonProperties
            );
            return;
        }

        const httpClient: IHttpClient = yield getContext('httpClient');
        const base64 = await convertBase64(action.receiptToUpload);
        const profile = yield select(getProfile);

        const receiptMetadata = {
            source: clientDeviceParam,
            user: {
                companyName: profile ? profile.companyCode : '',
                userPrincipalName: profile ? profile.userPrincipalName : '',
            },
            receipt: {
                //readAsDataURL fetches data in this format : data:[<mediatype>][;base64],<data>
                base64Content: base64.toString().split(',').pop(),
                name: action.receiptToUpload.name,
                id: guid(),
            },
        };
        var api_URL = `${__API_BASE_URL_V4__}/receipt/upload?uploadToMyExpenseFlag=${uploadToMyExpenseFlagParam}&scanReceiptFlag=${scanReceiptFlagParam}`
        if (action.expenseId && action.expenseId != "") {
            api_URL = api_URL + `&expenseID=${action.expenseId}`
        }
        const response: IHttpClientResult<any> = yield call([httpClient, httpClient.request], {
            url: api_URL,
            method: 'POST',
            resource: __RESOURCE_URL__,
            data: receiptMetadata,
            header: {
                ClientDevice: clientDeviceParam,
            },
        });

        if (response?.status === 200) {
            //Assign messages based on the client device and intent parameters 
            if (clientDeviceParam?.toLowerCase() === "copilot" && scanReceiptFlagParam === true) {
                yield put(uploadReceiptSuccess('Your receipt has been successfully uploaded to MyExpense Receipt Library and the details are being processed for the Expense creation. Navigate back to to the Expense Copilot app to view the created expense draft within a few minutes'));
            }
            else if (clientDeviceParam?.toLowerCase() === "copilot" && uploadToMyExpenseFlagParam === true) {
                if (action.expenseId && action.expenseId != "") {
                    yield put(uploadReceiptSuccess('The receipt uploaded and attached to your expense successfully.'));
                }
                else {
                    yield put(uploadReceiptSuccess('The receipt uploaded successfully to MyExpense Receipt Library and will attempt to match to an existing AMEX expense. Please navigate back to the Expense Copilot app.'));
                }
            }
            else {
                yield put(uploadReceiptSuccess('The receipt uploaded successfully to MyExpense'));
            }
        } else {
            if (clientDeviceParam?.toLowerCase() === "copilot" && scanReceiptFlagParam === true) {
                yield put(
                    failedReceiptUpload(
                        response?.data?.response || 'An error occurred. We are not able to capture your receipt for processing. Please try again later'
                    )
                );
            }
            else if (clientDeviceParam?.toLowerCase() === "copilot" && uploadToMyExpenseFlagParam === true) {
                if (action.expenseId && action.expenseId != "") {
                    yield put(
                        failedReceiptUpload(
                            response?.data?.response || 'An error occurred. The receipt failed to attach to your expense. Please try again later'
                        )
                    );
                }
                else {
                    yield put(
                        failedReceiptUpload(
                            response?.data?.response || 'An error occurred. The receipt failed to upload to MyExpense. Please try again later'
                        )
                    );
                }
            }
            else {
                yield put(
                    failedReceiptUpload(
                        response?.data?.response || 'An error occurred.  The receipt failed to upload to MyExpense'
                    )
                );
            }
        }

        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Upload receipt to Expense API - Success',
            'Expense-Web.UploadReceipt.Success',
            TrackingEventId.ReceiptUploadSuccess,
            stateCommonProperties
        );
    } catch (errorResponse) {
        const error = errorResponse.data ?? errorResponse; // handling cases where error occurs outside of httpclient call
        const exception = error.message ? new Error(error.message) : error;
        if (clientDeviceParam?.toLowerCase() === "copilot" && scanReceiptFlagParam === true) {
            yield put(
                failedReceiptUpload(
                    error.message || 'An error occurred. We are not able to capture your receipt for processing. Please try again later'
                )
            );
        }
        else if (clientDeviceParam?.toLowerCase() === "copilot" && uploadToMyExpenseFlagParam === true) {
            if (action.expenseId && action.expenseId != "") {
                yield put(
                    failedReceiptUpload(
                        error.message || 'An error occurred. The receipt failed to attach to your expense. Please try again later'
                    )
                );
            }
            else {
                yield put(
                    failedReceiptUpload(
                        error.message || 'An error occurred. The receipt failed to upload to MyExpense. Please try again later'
                    )
                );
            }
        }
        else {
            yield put(
                failedReceiptUpload(error.message || 'An error occurred.  The receipt failed to upload to MyExpense')
            );
        }
        trackException(
            authClient,
            telemetryClient,
            'Receipt upload - Failure',
            'MSExpense.receiptUpload.Failure',
            TrackingEventId.ReceiptUploadFailure,
            stateCommonProperties,
            exception
        );
    }
}

async function convertBase64(file: File) {
    const telemetryClient: ITelemetryClient = yield getContext('telemetryClient');
    const authClient: IAuthClient = yield getContext('authClient');
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (errorResponse) => {
            const error = errorResponse.data ?? errorResponse;
            const exception = error.message ? new Error(error.message) : error;

            trackException(
                authClient,
                telemetryClient,
                'Receipt upload - Failure',
                'MSExpense.receiptUpload.Failure',
                TrackingEventId.ReceiptUploadConvertToBase64Failure,
                null,
                exception
            );

            reject(errorResponse);
        };
    });
}

function validFileType(name: string) {
    const allowedExtensions =
        /(\.avi|\.bmp|\.csv|\.doc|\.docx|\.gif|\.htm|\.html|\.jpeg|\.jpg|\.json|\.mht|\.mhtml|\.msg|\.pdf|\.png|\.ppt|\.pptx|\.rtf|\.sgml|\.tif|\.txt|\.vsd|\.wmf|\.xhtml|\.xls|\.xlsx|\.xml)$/i;
    return allowedExtensions.test(name);
}

export function* receiptBankSagas(): IterableIterator<{}> {
    yield all([takeLatest(ReceiptBankActionType.UPLOAD_RECEIPT, postReceipt)]);
}
