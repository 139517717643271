import { IStackTokens, IStackStyles } from '@fluentui/react/lib/Stack';

export const ModalButtonGroup: IStackStyles = {
	root: {
		alignItems: 'end',
		display: 'flex'
	}
};

export const ModalButtonGroupStackTokens: IStackTokens = {
	childrenGap: 12,
};

export const ImagePreview = styled.div`
	width: 100%;
	height: auto;
	position: relative;
	text-align: center;
`;

export const CameraWrapper = styled.div`
	width: 90%;
`;

export const TextFieldStackTokens: IStackTokens = {
	childrenGap: 19,
};

export const Root = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	text-align: center;
	overflow-x: hidden;
	overflow-y: auto;
`;

export const Preview = styled.div`
	width: 100%;
	height: auto;
`;

export const Footer = styled.footer`
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	background: silver;

	button {
	margin: 0 10px;
	}
`;