import { IDetailsListStyles } from '@fluentui/react';
import { IStackTokens, IStackStyles, IStackItemStyles } from '@fluentui/react/lib/Stack';
import { customH2FontStyling } from '../../../Shared/Styles/SharedLayout.styled';

export interface IAdditionalHTMLAttribute {
    windowHeight?: number;
    windowWidth?: number;
}

export const FAQContainer = styled.div`
    padding: 1% 1% 3%;
    overflow-x: hidden;
    margin-left: 15px;

    @media only screen and (max-width: 320px) {
        height: ${(props: IAdditionalHTMLAttribute) => props.windowHeight - 50}px;
    }
`;

export const FAQTitle = styled.h1`
    padding-bottom: 4px;
`;

export const HeadingTitle = styled.h2`
    padding-top: 24px;
    padding-bottom: 8px;
    ${customH2FontStyling}
`;

export const QuickLink = styled.div`
    margin-bottom: 16px;
`;

export const TrainingContainer = styled.div`
    padding: 1% 2% 0 2%;
`;

export const TrainingTitle = styled.h1`
    padding-bottom: 4px;
`;

export const QuickRefListStyles: IDetailsListStyles = {
    root: {
        backgroundColor: '#f2f2f2',
    }
}

export const MainContentStackStyles: IStackStyles = {
    root: {
        padding: '10px',
        background: '#f2f2f2',
        display: 'flex'
    }
};

export const HalfViewportWidth = {
    width: '40vw',
};

export const TextFieldStackTokens: IStackTokens = {
    childrenGap: 19,
};

export const div1 = styled.div`
    max-width: 300px;
`;

export const div2 = styled.div`
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden;
`;

export const ConcurContentHeader = styled.h3`
    font: 18px Segoe UI semibold;
    padding-bottom: 6px;
    padding-top: 6px;
    color: #006CBE;
`;

export const ConcurTextStackTokens: IStackTokens = {
    childrenGap: 24,
};

export const ConcurTextStackStyles: IStackStyles = {
    root: {
        width: '90%',
        display: 'flex'
    }
};

export const ConcurTextStackItemStyles: IStackItemStyles = {
    root: {
        display: 'flex',
        width: '33%'
    }
};

export const ConcurLinkStackTokens: IStackTokens = {
    childrenGap: 12,
};