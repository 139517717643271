import { detailsReducerName } from '../../Components/ExpenseDetails/Details.reducer';
import { IUserPreferencesResponseObj } from './Expense.action-types';
import { expenseReducerName, expenseInitialState } from './Expense.reducer';
import { createSelector } from 'reselect';
import { IExpenseAppState, IGraphPhoto } from './Expense.types';
import { GroupingBy } from '../GroupingBy';
import { groupByDate, groupByStatus } from '../../Helpers/groupingSummary';
import { statusesToShow } from '../SharedConstants';

export const getStateCommonTelemetryProperties = (state: any): any => {
    const logData = {};
    if (state.dynamic && state.dynamic[expenseReducerName]) {
        const profile = state.dynamic[expenseReducerName].profile;
        if (profile) {
            Object.assign(logData, {
                UserAlias: profile.userPrincipalName,
                LoggedInUserAlias: profile.userPrincipalName,
            });
        }
    }
    if (state.dynamic && state.dynamic[detailsReducerName]) {
        const { expenseStatus, expenseReportId, tcv } = state.dynamic[detailsReducerName];
        Object.assign(logData, {
            MessageId: tcv,
            ExpenseReportId: expenseReportId,
            ExpenseStatus: expenseStatus,
        });
    }
    return logData;
};

export const getUserAlias = (state: IExpenseAppState): any => {
    let userAlias = state.dynamic?.[expenseReducerName]?.profile?.userPrincipalName;
    userAlias = userAlias.replace('@microsoft.com', '');
    return userAlias;
};

export const getProfile = (state: IExpenseAppState): any => {
    return state.dynamic?.[expenseReducerName]?.profile || expenseInitialState.profile;
};

export const getIsLoadingProfile = (state: IExpenseAppState): any => {
    return state.dynamic?.[expenseReducerName]?.isLoadingProfile || expenseInitialState.isLoadingProfile;
};

export const getIsLoadingUserPreferences = (state: IExpenseAppState): boolean => {
    return (
        state.dynamic?.[expenseReducerName]?.isLoadingUserPreferences || expenseInitialState.isLoadingUserPreferences
    );
};

export const getSaveUserPreferencesHasError = (state: IExpenseAppState): boolean => {
    return (
        state.dynamic?.[expenseReducerName]?.saveUserPreferencesHasError ||
        expenseInitialState.saveUserPreferencesHasError
    );
};

export const getIsSavingUserPreferences = (state: IExpenseAppState): boolean => {
    return state.dynamic?.[expenseReducerName]?.isSavingUserPreferences || expenseInitialState.isSavingUserPreferences;
};

export const getSaveUserPreferencesResponseMessages = (state: IExpenseAppState): string[] => {
    return (
        state.dynamic?.[expenseReducerName]?.saveUserPreferencesResponseMessages ||
        expenseInitialState.saveUserPreferencesResponseMessages
    );
};

export const getSettingsPageShowing = (state: IExpenseAppState): string => {
    return state.dynamic?.[expenseReducerName]?.settingsPageShowing || expenseInitialState.settingsPageShowing;
};

export const getSubmitterImages = (state: IExpenseAppState): IGraphPhoto[] => {
    return state.dynamic?.[expenseReducerName]?.submitterImages || expenseInitialState.submitterImages;
};

const getAlias = (_: any, alias: string): string => alias;

export const getImageURLForAlias = createSelector(getSubmitterImages, getAlias, (submitterImages, alias) => {
    if (submitterImages && submitterImages.length > 0 && alias) {
        const matchingElement = submitterImages.find((el) => el.alias === alias);
        return matchingElement?.image || null;
    }
    return null;
});

export const getIsLoadingSubmitterImages = (state: IExpenseAppState): boolean => {
    return (
        state.dynamic?.[expenseReducerName]?.isLoadingSubmitterImages || expenseInitialState.isLoadingSubmitterImages
    );
};

export const getDeletedReports = (state: IExpenseAppState): string[] => {
    return state.dynamic?.[expenseReducerName]?.deletedReports || expenseInitialState.deletedReports;
};

export const getExpenseData = (state: IExpenseAppState): any => {
    // only return expense data with the status types we want to show
    if (state.dynamic?.[expenseReducerName]?.expenseData) {
        const expenseReports2: any = [];
        state.dynamic?.[expenseReducerName]?.expenseData.forEach((item: any) => {
            if (statusesToShow.includes(item.expenseStatus.toLowerCase())) {
                expenseReports2.push(item);
            }
        });
        return expenseReports2;
    } else {
        return expenseInitialState.expenseData;
    }
};

export const getDraftData = createSelector(getExpenseData, (allReports) => {
    if (allReports && allReports.length > 0) {
        return allReports.filter((item: any) => item?.expenseStatus === 'draft');
    } else {
        return allReports;
    }
});

export const getFilteredDrafts = createSelector(getDraftData, getDeletedReports, (allReports, deletedReports) => {
    if (allReports && allReports.length > 0 && deletedReports && deletedReports.length > 0) {
        return allReports.filter((item: any) => !deletedReports.includes(item.reportId));
    } else {
        return allReports;
    }
});

export const isAliasInSubmitters = createSelector(getSubmitterImages, getAlias, (submitterImages, alias) => {
    if (submitterImages && submitterImages.length > 0 && alias) {
        const matchingElement = submitterImages.find((el) => el.alias === alias);
        return !!matchingElement;
    }
    return null;
});

export const getIsLoadingSummary = (state: IExpenseAppState): boolean => {
    return state.dynamic?.[expenseReducerName]?.isLoadingSummary || expenseInitialState.isLoadingSummary;
};
export const getSubmittedReports = (state: IExpenseAppState): string[] => {
    return state.dynamic?.[expenseReducerName]?.submittedReports || expenseInitialState.submittedReports;
};

export const getExpenseDataNotJustSubmitted = createSelector(
    getExpenseData,
    getSubmittedReports,
    (allReports, submittedReports) => {
        if (allReports && allReports.length > 0 && submittedReports && submittedReports.length > 0) {
            return allReports.filter((item: any) => !submittedReports.includes(item.reportId));
        } else {
            return allReports;
        }
    }
);

export const getUserPreferences = (state: IExpenseAppState): IUserPreferencesResponseObj => {
    return state.dynamic?.[expenseReducerName]?.userPreferences || expenseInitialState.userPreferences;
};

export const getFilterValue = (state: IExpenseAppState): string => {
    return state.dynamic?.[expenseReducerName]?.filterValue || expenseInitialState.filterValue;
};

export const getIsPanelOpen = (state: IExpenseAppState): any => {
    return state.dynamic?.[expenseReducerName]?.isPanelOpen || expenseInitialState.isPanelOpen;
};

export const getSummaryGroupedBy = (state: IExpenseAppState) => {
    return state.dynamic?.[expenseReducerName]?.summaryGroupedBy || expenseInitialState.summaryGroupedBy;
};

export const getGroupedBySummaryMemo = (summary: any, groupedBy: string) => {
    let groupedSummaryData = {};
    if (summary && groupedBy && groupedBy != '') {
        switch (groupedBy) {
            case GroupingBy.Status:
                groupedSummaryData = groupByStatus(summary);
                break;
            case GroupingBy.Date:
                groupedSummaryData = groupByDate(summary);
                break;
            // default group by status
            default:
                groupedSummaryData = groupByStatus(summary);
                break;
        }
    }
    return groupedSummaryData;
};
