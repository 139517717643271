import * as React from 'react';
import { IconButton } from '@fluentui/react';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import * as Styled from '../Styles/SharedLayout.styled';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { updatePanelState } from '../Store/Expense.actions';
import { detailsInitialState, detailsReducerName } from '../../Components/ExpenseDetails/Details.reducer';
import { IDetailsAppState } from '../../Components/ExpenseDetails/Details.types';
import { useHistory, useLocation } from 'react-router-dom';
import { clearReportURL } from '../../Helpers/sharedHelpers';
import { breakpointMap } from '../../Styles/Media';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

function CloseButton(props: { action?(): void }): React.ReactElement {
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const history = useHistory();
    const location = useLocation();

    const { expenseReportId } = useSelector(
        (state: IDetailsAppState) => state.dynamic?.[detailsReducerName] || detailsInitialState
    );

    let closeButtonRef: any = null;

    React.useEffect(() => {
        if (closeButtonRef && expenseReportId && window.innerWidth <= breakpointMap.xl) {
            closeButtonRef.focus();
            document.body.classList.add('ms-Fabric--isFocusVisible');
        }
    }, [closeButtonRef, expenseReportId]);

    return (
        <TooltipHost content="Close" calloutProps={Styled.tooltipCalloutProps} styles={Styled.tooltipHostContainer}>
            <IconButton
                id="detail-close-btn"
                className="close-button"
                componentRef={(input) => (closeButtonRef = input)}
                iconProps={{ iconName: 'Cancel' }}
                title="Close"
                ariaLabel="Click here to close the details card"
                style={Styled.DetailActionIcon}
                onClick={() => {
                    if (props.action) {
                        props.action();
                    } else {
                        clearReportURL(history, location);
                        dispatch(updatePanelState(false));
                    }
                }}
            />
        </TooltipHost>
    );
}

const connected = withContext(CloseButton);
export { connected as CloseButton };
