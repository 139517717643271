import * as React from 'react';
import * as Styled from './DetailsMessageBarsStyling';
import { Stack } from '@fluentui/react/lib/Stack';
import { MessageBar, MessageBarType, Link } from '@fluentui/react';
import * as sanitizeHtml from 'sanitize-html';

interface SuccessViewBarProps {
    successMessage: string;
    successType?: string;
    linkHref?: string;
    linkText?: string;
    dismissHandler?: any;
}

const SuccessViewBar = ({
    successMessage = '',
    successType = '',
    linkHref = null,
    linkText = null,
    dismissHandler = null,
}: SuccessViewBarProps): JSX.Element => {
    const cleanSuccessMessage = sanitizeHtml(successMessage, {
        allowedTags: ['a'],
        allowedAttributes: {
            a: ['href', 'target'],
        },
    });

    const [successViewRef, setSuccessViewRef] = React.useState(null);

    React.useEffect(() => {
        if (successViewRef) {
            successViewRef.focus();
        }
    }, [successViewRef]);

    return (
        <MessageBar
            messageBarType={MessageBarType.success}
            isMultiline={true}
            aria-label={'Success message'}
            onDismiss={dismissHandler}
            dismissButtonAriaLabel={'Dismiss success message'}
        >
            <Stack tokens={Styled.OtherViewsStackTokensGap}>
                {successType && (
                    <Stack.Item>
                        <Styled.DetailsMessageBarTitle>{successType} Success.</Styled.DetailsMessageBarTitle>
                    </Stack.Item>
                )}
                <Stack.Item>
                    <div
                        ref={(input) => {
                            setSuccessViewRef(input);
                        }}
                        role="status"
                        aria-label={cleanSuccessMessage}
                        title={cleanSuccessMessage}
                        tabIndex={0}
                        style={{ outline: 'none' }}
                        dangerouslySetInnerHTML={{ __html: cleanSuccessMessage }}
                    />
                </Stack.Item>
                <Stack.Item>{linkHref && <Link href={linkHref}>{linkText}</Link>}</Stack.Item>
            </Stack>
        </MessageBar>
    );
};

export default SuccessViewBar;
