import * as React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { useLoginOnStartup } from '@micro-frontend-react/employee-experience/lib/useLoginOnStartup';
import { loadTheme, Stack, Spinner, ThemeProvider, initializeIcons } from '@fluentui/react';
import { CoherenceTheme } from '@coherence-design-system/styles';
import { ShellWithStore } from './ShellWithStore';
import { authorizedConfig, navConfig } from './navConfig';
import { Routes } from './Routes';
import { useHeaderConfig } from './useHeaderConfig';
import { Header } from './Components/Header/Header';
import { Nav } from './Components/Nav/Nav';
import { useCallback, useContext, useEffect, useState } from 'react';
import { registerIcons } from '@fluentui/react/lib/Styling';
import './App.css';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { SecondaryHeader } from './Components/SecondaryHeader/SecondaryHeader';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { IExpenseAppState } from './Shared/Store/Expense.types';
import * as SharedStyled from './Shared/Styles/SharedLayout.styled';
import { useUser } from '@micro-frontend-react/employee-experience/lib/useUser';
import { expenseReducerName, expenseInitialState } from './Shared/Store/Expense.reducer';
import ErrorResult from './Shared/Components/ErrorResult';
import BetaInfoViewBar from './Shared/Components/MessageBars/BetaInfoViewBar';
import * as Styled from './App.styled';
import { Main } from './Components/Main/Main';

function App(): React.ReactElement {
    initializeIcons();
    useLoginOnStartup(true, { scopes: ['https://graph.microsoft.com/.default'] });
    loadTheme(CoherenceTheme);
    const headerConfig = useHeaderConfig();
    const { useSelector } = useContext(Context as React.Context<IEmployeeExperienceContext>);
    // Set this to true if we want to display the banner
    const [showBetaBar, setShowBetaBar] = useState<boolean>(false);
    const closeBetaBar = useCallback(() => setShowBetaBar(false), []);

    const { isPanelOpen, showSecondaryHeader, isAuthorized, settingsPageShowing } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    const [showLoading, setShowLoading] = React.useState(true);

    registerIcons({
        icons: {
            customExpenseDashboard: <Styled.ExpenseDashboardIcon />,
            customTraining: <Styled.TrainingIcon />,
        },
    });

    React.useEffect(() => {
        const timer = setTimeout(() => setShowLoading(false), 12000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    const isValidUser = !!useUser();

    const loginMessage = (
        <SharedStyled.HeightBelowShell windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
            {showLoading ? ( 
                <SharedStyled.SpinnerContainer>
                    <Spinner label="Logging in..." />
                </SharedStyled.SpinnerContainer>
            ) : (
                <ErrorResult message="A login error occured, please login with a valid Microsoft account." />
            )}
        </SharedStyled.HeightBelowShell>
    );

    const getBetaBanner = () => {
        return <BetaInfoViewBar closeAction={closeBetaBar} />;
    };

    return (
        <BrowserRouter>
            <ThemeProvider theme={CoherenceTheme}>
                {headerConfig && <Header {...headerConfig} />}
                <div>
                    <Stack horizontal className={isPanelOpen ? 'ms-hiddenSm' : ''}>
                    <Stack.Item>
                            <div className="navContainer">
                            <Nav groups={authorizedConfig} />
                            </div>
                        </Stack.Item>
                       {showSecondaryHeader && (
                            <Stack.Item grow>
                                <Stack className={isPanelOpen ? 'ms-hiddenSm' : ''}>
                                    <Stack.Item>
                                        <SecondaryHeader />
                                    </Stack.Item>
                                    <Stack.Item>{showBetaBar && getBetaBanner()}</Stack.Item>
                                </Stack>
                            </Stack.Item>
                        )}
                        {!showSecondaryHeader && showBetaBar && getBetaBanner()}
                    </Stack>

                    <Main id="main" tabIndex={-1}>
                        {isValidUser ? <Routes /> : loginMessage}
                    </Main>
                </div>
            </ThemeProvider>
        </BrowserRouter>
    );
}

render(
    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);
