import { Dropdown } from '@fluentui/react/lib/Dropdown';
import * as React from 'react';

const BasicDropdown = ({
    options = [],
    placeholder = '',
    selectedKey = '',
    label = '',
    onChange = (): void => {},
    required = false,
    styles = {},
    componentRef = null,
    errorMessage = null,
}) => {
    return (
        <Dropdown
            options={options}
            placeholder={placeholder}
            selectedKey={selectedKey}
            label={label}
            onChange={onChange}
            required={required}
            styles={styles}
            componentRef={componentRef}
            errorMessage={errorMessage}
        />
    );
};
export default BasicDropdown;
