import { ApprovedIcon, InReviewIcon, PaidIcon, ProcessingPaymentIcon, DraftIcon, RejectedIcon } from '../Static/Icons';
import * as SharedStyled from '../Shared/Styles/SharedLayout.styled';
import { breakpointMap } from '../Styles/Media';

export const mapExpenseStatusIcon = (expenseStatus: string) => {
    if (!expenseStatus) return '';
    switch (expenseStatus.toLowerCase()) {
        case 'draft':
            return DraftIcon;
            break;
        case 'approved':
            return ApprovedIcon;
            break;
        case 'processing payment':
            return ProcessingPaymentIcon;
            break;
        case 'in review':
            return InReviewIcon;
            break;
        case 'paid':
            return PaidIcon;
            break;
        case 'rejected':
            return RejectedIcon;
            break;
        default:
            return InReviewIcon;
            break;
    }
};

export const isMobileResolution = (width: number): boolean => {
    return width <= breakpointMap.m;
};

export const imitateClickOnKeyPressForDiv = (
    onClick: VoidFunction
): ((e: React.KeyboardEvent<HTMLDivElement>) => void) => {
    const onKeyPress = (e: React.KeyboardEvent<HTMLDivElement>): void => {
        const enterOrSpace =
            e.key === 'Enter' || e.key === ' ' || e.key === 'Spacebar' || e.which === 13 || e.which === 32;
        if (enterOrSpace) {
            e.preventDefault();
            onClick();
        }
    };
    return onKeyPress;
};

export const clearReportURL = (routerHistory: any, routerLocation: any): void => {
    if (routerHistory && routerLocation) {
        if (routerLocation.pathname?.length > 1) {
            const path = routerLocation.pathname;
            if (path?.split('/')?.length > 2) {
                routerHistory.push('/ExpenseDashboard');
            }
        }
    }
};

export const convertToUPN = (alias: string): string => {
    if (alias && !alias.includes('@')) {
        return alias + '@microsoft.com';
    }
    return alias;
};

export const launchBot = () => {
    OpenFDA('hostappid=2AC2D000-A1E6-4B6C-8EE7-7FF932E6056C', SharedStyled.LaunchBotStyling);
};
