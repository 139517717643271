import * as React from 'react';
import { withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { CompoundButton, IButtonProps, IButtonStyles, Icon, IRenderFunction } from "@fluentui/react";
import { TextColors } from '../Styles/SharedColors'


type IFileSelectorProps = IButtonProps & {
    usePrimaryButton?: boolean;
    iconName: string;
    onClick: () => void;
    text: string;
    secondaryText?: string;
};

function FileSelectorButton(props: IFileSelectorProps): React.ReactElement {
    const _onRenderIcon:IRenderFunction<IButtonProps> = () => {
        return (
          <Icon 
            style={fileUploadIconStyle} 
            iconName={props.iconName}/>
        );
    }

    // STYLING FOR FILE SELECT BUTTON
    const fileUploadIconStyle = {
        padding: '10px 20px 10px 10px', 
        fontSize: '36px', 
        color: TextColors.disabled
    }

    const buttonTextStyle:IButtonStyles = {
        flexContainer: {
        alignItems: 'center'
        },
        root: {
        minWidth: '-webkit-fill-available'
        }
    }

    const buttonStyle = {
        padding: '10px'
    }

    return (
        <div>
            <CompoundButton
                primary={props.usePrimaryButton}
                text={props.text}
                secondaryText={props.secondaryText}
                onClick={props.onClick}
                onRenderIcon={_onRenderIcon}
                styles={buttonTextStyle}
                style={buttonStyle}
                {...props}
            ></CompoundButton>
        </div>
    );
}

const connected = withContext(FileSelectorButton);
export { connected as FileSelectorButton };
