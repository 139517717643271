export const Wrapper = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	width: 100%;
`;

export const Container = styled.div<{ maxWidth?: any, maxHeight?: any }>`
	position: relative;
	width: 100%;
	max-width: ${(props) => (props.maxWidth && `${props.maxWidth}px`)}
	max-height: ${(props) => (props.maxHeight && `${props.maxHeight}px`)}
	overflow: hidden;
`;

export const Canvas = styled.canvas<{ top?: any, left?: any }>`
	position: absolute;
	top: ${(props) => (props.top ? `-${props.top}px` : 0)};
	left: ${(props) => (props.left ? `-${props.left}px` : 0)};
`;

export const Video = styled.video`
	position: absolute;

	&::-webkit-media-controls-play-button {
		display: none !important;
		-webkit-appearance: none;
	}
`;

export const Flash = styled.div<{ flash?: boolean }>`
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #ffffff;
	opacity: 0;  

	
`;

export const ButtonStyling = styled.div`
	margin-top: 24px;
`;
