import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { MainDashboardPage } from './Components/Pages/MainDashboard/MainDashboardPage';
import { ExpenseDashboardPage } from './Components/Pages/ExpenseDashboard/ExpenseDashboardPage';
import { StatFormsPage } from './Components/StatForms/StatFormsPage';
import { TransitionToMSExpensePage } from './Components/TransitionToMSExpense/TransitionToMSExpense';
import { ReceiptPage } from './Components/Pages/ReceiptBank/ReceiptPage';
import {Training } from './Components/Pages/Trainings/TrainingPage';
import { ManageDelegates } from './Components/Pages/ManageDelegates/ManageDelegates';
import { ReceiptUploadPage } from './Components/Pages/ReceiptUploadPage/ReceiptUploadPage';

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <Route path="/" component={MainDashboardPage} exact={true} />
            <Route path="/ExpenseDashboard" component={ExpenseDashboardPage} exact={true} />
            <Route path="/ReceiptBank" component={ReceiptPage} exact={true} />
            <Route path="/ReceiptUpload" component={ReceiptUploadPage} exact={true} />
            <Route path="/ExpenseDashboard/:status/:reportId" component={ExpenseDashboardPage} exact={true} />
            {/* Trainings */}
            <Route path="/Training" component={Training} exact={true} />
            <Route path="/ManageDelegates" component={ManageDelegates} exact={true} />
            <Route path="/statforms" component={StatFormsPage} exact={true} />
            <Route path="/TransitionToMSExpensePage" component={TransitionToMSExpensePage} exact={true} />
        </Switch>
    );
}
