import { IExpenseState } from './Expense.types';
import { ExpenseAction, ExpenseActionType } from './Expense.action-types';
import { GroupingBy } from '../GroupingBy';
import { DOCKED_VIEW } from '../SharedConstants';

export const expenseReducerName = 'ExpenseReducerName';
export const expenseInitialState: IExpenseState = {
    profile: null,
    profileErrorMessage: null,
    profileHasError: false,
    expenseData: null,
    isLoadingSummary: false,
    isLoadingProfile: false,
    hasError: false,
    errorMessage: null,
    isPanelOpen: false,
    summaryErrorMessage: null,
    detailsDefaultView: DOCKED_VIEW,
    maxDetailsScreen: false,
    summaryGroupedBy: GroupingBy.Status,
    continuationToken: '',
    continuationTokenDraft: '',
    showSecondaryHeader: false,
    settingsPageShowing: 'Settings',
    filteredUsers: null,
    peoplePickerHasError: false,
    isLoadingSubmitterImages: false,
    deletedReports: [],
    submittedReports: [],
    isLoadingStats: false,
    expenseCounts: null,
    submitterImages: [],
    userPreferences: null,
    isLoadingUserPreferences: false,
    saveUserPreferencesHasError: false,
    saveUserPreferencesResponseMessages: null,
    isSavingUserPreferences: false,
    filterValue: 'All',
    isComingFromDashboard: false,
    delegatesList: null,
    receiveDelegateHasError: false,
    receiveDelegateListErrorMessage: null,
    modifyDelegateHasError: false,
    modifyDelegateErrorMessage: null,
    modifyDelegateSuccessMessage: null,
    isLoadingDelegates: false,
    isModifyingDelegate: false,
    finishedModifyingDelegate: false,
    showManageDelegatesDialog: false,
};

export function expenseReducer(prev: IExpenseState = expenseInitialState, action: ExpenseAction): IExpenseState {
    switch (action.type) {
        case ExpenseActionType.REQUEST_MY_PROFILE:
            return {
                ...prev,
                isLoadingProfile: true,
                hasError: false,
            };
        case ExpenseActionType.RECEIVE_MY_PROFILE:
            return {
                ...prev,
                isLoadingProfile: false,
                hasError: false,
                profile: action.profile,
            };
        case ExpenseActionType.FAILED_PROFILE:
            return {
                ...prev,
                isLoadingProfile: false,
                profileHasError: true,
                profileErrorMessage: action.profileErrorMessage,
            };
        case ExpenseActionType.SET_CONTINUATION_TOKEN:
            return {
                ...prev,
                continuationToken: action.continuationToken,
            };
        case ExpenseActionType.SET_CONTINUATION_TOKEN_DRAFT:
            return {
                ...prev,
                continuationTokenDraft: action.continuationTokenDraft,
            };
        case ExpenseActionType.REQUEST_MY_SUMMARY:
            return {
                ...prev,
                isLoadingSummary: true,
                hasError: false,
                expenseData: prev.expenseData,
                filterValue: 'All',
            };
        case ExpenseActionType.RECEIVE_MY_SUMMARY:
            let preExpenseData = prev.expenseData;
            if (!preExpenseData) {
                preExpenseData = [];
            }
            return {
                ...prev,
                isLoadingSummary: false,
                hasError: false,
                expenseData: action.isFirstCall ? action.expenseData : [...preExpenseData, ...action.expenseData],
                isLoadingSubmitterImages: true,
            };
        case ExpenseActionType.FAILED_SUMMARY:
            return {
                ...prev,
                isLoadingSummary: false,
                hasError: true,
                summaryErrorMessage: action.summaryErrorMessage,
                expenseData: prev.expenseData && prev.expenseData.length > 0 ? prev.expenseData : null,
                isPanelOpen: false,
            };
        case ExpenseActionType.UPDATE_PANEL_STATE:
            return {
                ...prev,
                isPanelOpen: action.isOpen,
                maxDetailsScreen: action.isOpen ? prev.maxDetailsScreen : false,
            };
        case ExpenseActionType.SET_DETAILS_DEFAULT_VIEW:
            return {
                ...prev,
                detailsDefaultView: action.defaultView,
            };
        case ExpenseActionType.TOGGLE_DETAILS_SCREEN_MAX:
            return {
                ...prev,
                maxDetailsScreen: !prev.maxDetailsScreen,
            };
        case ExpenseActionType.UPDATE_GROUPED_SUMMARY:
            return {
                ...prev,
                summaryGroupedBy: action.summaryGroupedBy,
                isPanelOpen: false,
                filterValue: 'All',
            };
        case ExpenseActionType.UPDATE_FILTER_VALUE:
            return {
                ...prev,
                isPanelOpen: false,
                filterValue: action.filterValue,
            };
        case ExpenseActionType.UPDATE_IS_COMING_FROM_DASHBOARD:
            return {
                ...prev,
                isComingFromDashboard: action.isComingFromDashboard,
            };
        case ExpenseActionType.UPDATE_SHOW_SECONDARY_HEADER:
            return {
                ...prev,
                showSecondaryHeader: action.showSecondaryHeader,
            };
        case ExpenseActionType.UPDATE_SETTINGS_PAGE_SHOWING:
            return {
                ...prev,
                settingsPageShowing: action.settingsPageShowing,
            };
        case ExpenseActionType.REQUEST_USER_PREFERENCES:
            return {
                ...prev,
                isLoadingUserPreferences: true,
            };
        case ExpenseActionType.RECEIVE_USER_PREFERENCES:
            return {
                ...prev,
                userPreferences: action.userPreferences,
                isLoadingUserPreferences: false,
            };
        case ExpenseActionType.SAVE_USER_PREFERENCES:
            return {
                ...prev,
                saveUserPreferencesHasError: false,
                saveUserPreferencesResponseMessages: null,
                isSavingUserPreferences: true,
            };
        case ExpenseActionType.SAVE_USER_PREFERENCES_SUCCESS:
            return {
                ...prev,
                saveUserPreferencesHasError: false,
                saveUserPreferencesResponseMessages: action.responseMessages,
                isSavingUserPreferences: false,
            };
        case ExpenseActionType.SAVE_USER_PREFERENCES_FAILED:
            return {
                ...prev,
                saveUserPreferencesHasError: true,
                saveUserPreferencesResponseMessages: action.responseMessages,
                isSavingUserPreferences: false,
            };
        case ExpenseActionType.RECEIVE_SUBMITTER_IMAGES:
            return {
                ...prev,
                submitterImages: action.submitterImages,
                isLoadingSubmitterImages: false,
            };
        case ExpenseActionType.UPDATE_DELETED_REPORTS:
            return {
                ...prev,
                deletedReports: prev.deletedReports.concat(action.reportId),
            };
        case ExpenseActionType.UPDATE_SUBMITTED_REPORTS:
            return {
                ...prev,
                submittedReports: prev.submittedReports.concat(action.reportId),
            };
        case ExpenseActionType.REQUEST_EXPENSE_STAT_COUNTS:
            return {
                ...prev,
                isLoadingStats: true,
                hasError: false,
            };
        case ExpenseActionType.RECEIVE_EXPENSE_STAT_COUNTS:
            return {
                ...prev,
                isLoadingStats: false,
                hasError: false,
                expenseCounts: action.expenseCounts,
            };
        case ExpenseActionType.FAILED_EXPENSE_STAT_COUNTS:
            return {
                ...prev,
                isLoadingStats: false,
                hasError: true,
                errorMessage: action.errorMessage,
                expenseCounts: action.defaultCounts,
            };
        case ExpenseActionType.REQUEST_DELEGATES:
            return {
                ...prev,
                isLoadingDelegates: true,
                receiveDelegateHasError: false,
            };
        case ExpenseActionType.RECEIVE_DELEGATES:
            return {
                ...prev,
                isLoadingDelegates: false,
                receiveDelegateHasError: false,
                delegatesList: action.delegatesList,
            };
        case ExpenseActionType.RECEIVE_DELEGATE_FAILED:
            return {
                ...prev,
                isLoadingDelegates: false,
                delegatesList: [],
                receiveDelegateHasError: true,
                receiveDelegateListErrorMessage: action.receiveDelegateListErrorMessage,
            };
        case ExpenseActionType.MODIFY_DELEGATE:
            return {
                ...prev,
                isModifyingDelegate: true,
                finishedModifyingDelegate: false,
                modifyDelegateErrorMessage: null,
                modifyDelegateSuccessMessage: null,
            };
        case ExpenseActionType.MODIFY_DELEGATE_SUCCESS:
            return {
                ...prev,
                isModifyingDelegate: false,
                modifyDelegateSuccessMessage: action.modifyDelegateSuccessMessage,
                modifyDelegateErrorMessage: null,
                modifyDelegateHasError: false,
                finishedModifyingDelegate: true,
                showManageDelegatesDialog: true,
            };
        case ExpenseActionType.MODIFY_DELEGATE_FAILED:
            return {
                ...prev,
                isModifyingDelegate: false,
                modifyDelegateSuccessMessage: null,
                modifyDelegateErrorMessage: action.modifyDelegateErrorMessage,
                modifyDelegateHasError: true,
                finishedModifyingDelegate: true,
                showManageDelegatesDialog: true,
            };
        case ExpenseActionType.HIDE_DELEGATE_DIALOG:
            return {
                ...prev,
                showManageDelegatesDialog: false,
            };
        default:
            return prev;
    }
}
