import * as React from 'react';
import { Reducer } from 'redux';
import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { Icon, Spinner, PrimaryButton, DefaultButton, Stack } from '@fluentui/react';
import { FileUploader } from '../../../../../Helpers/fileUploader';
import * as PanelStyled from './ReceiptUploadPanel.styled';
import * as SharedStyled from '../../../../../Shared/Styles/SharedLayout.styled';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import {
    updateReceiptUploadPanelState,
    uploadReceipt,
    resetReceiptUploadState,
} from '../ReceiptUploadStore/ReceiptUpload.actions';
import { IReceiptBankState } from '../ReceiptUploadStore/ReceiptUpload.types';
import {
    receiptBankReducerName,
    receiptBankReducer,
    receiptBankInitialState,
} from '../ReceiptUploadStore/ReceiptUpload.reducer';
import { receiptBankSagas } from '../ReceiptUploadStore/ReceiptUpload.sagas';
import { FileSelectorButton } from '../../../../../Shared/Components/FileSelectorButton';
import { FilePictureUpload } from '../../../../../Shared/Components/FilePictureUpload';
import SuccessViewBar from '../../../../../Shared/Components/MessageBars/SuccessViewBar';
import ErrorView from '../../../../../Shared/Components/MessageBars/ErrorView';
import { getProfile } from '../../../../../Shared/Store/Expense.selectors';
import { expenseReducer, expenseReducerName } from '../../../../../Shared/Store/Expense.reducer';
import { sharedExpenseSagas } from '../../../../../Shared/Store/Expense.sagas';
import { requestMyProfile } from '../../../../../Shared/Store/Expense.actions';
import { CloseButton } from '../../../../../Shared/Components/CloseButton';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import BasicButton from '../../../../../Shared/Components/BasicButton';

function ReceiptUploadPanel(): React.ReactElement {
    //intializing reducer without persistance
    useDynamicReducer(receiptBankReducerName, receiptBankReducer as Reducer, [receiptBankSagas], false);
    const stackTokens: IStackTokens = { childrenGap: 12 };

    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas], false);
    const { useSelector, telemetryClient: telemetryHook, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const profile = useSelector(getProfile);

    React.useEffect(() => {
        if (profile === null) {
            dispatch(requestMyProfile());
        }
    }, [dispatch]);

    const { isPanelOpen, isUploadingReceipt, receiptUploadHasError, receiptUploadResponseMessage } = useSelector(
        (state: IReceiptBankState) => state.dynamic?.[receiptBankReducerName] || receiptBankInitialState
    );

    React.useEffect(() => {
        dispatch(updateReceiptUploadPanelState(false));
    }, []);

    React.useEffect(() => {
        setSelectedFile(null);
        dispatch(resetReceiptUploadState());
    }, [isPanelOpen]);

    React.useEffect(() => {
        if (receiptUploadResponseMessage) {
            setSelectedFile(null);
        }
    }, [receiptUploadResponseMessage]);

    const updatePanel = (): void => {
        const panelState = !isPanelOpen;
        dispatch(updateReceiptUploadPanelState(panelState));
        return;
    };

    const [fileSelected, setSelectedFile] = React.useState<File>();
    const [isModalOpen, setModalOpen] = React.useState(false);

    const onSubmitButtonClicked = () => {
        dispatch(uploadReceipt(fileSelected));
    };

    const checkRequiredFieldFilled = () => {
        if (fileSelected == null) {
            return true;
        }
        return false;
    };

    const launchModal = () => {
        setModalOpen(true);
    };

    const closeModal = (): void => {
        setModalOpen(false);
    };

    const getPanelContent = () => {
        return (
            <div>
                {receiptUploadHasError && receiptUploadResponseMessage && (
                    <PanelStyled.MessageBarStyled>
                        <ErrorView
                            errorMessages={[receiptUploadResponseMessage]}
                            customTitle="An error occurred. Please find details below:"
                        />
                    </PanelStyled.MessageBarStyled>
                )}
                {!receiptUploadHasError && receiptUploadResponseMessage && (
                    <PanelStyled.MessageBarStyled>
                        <SuccessViewBar successMessage={receiptUploadResponseMessage} />
                    </PanelStyled.MessageBarStyled>
                )}
                {isUploadingReceipt && <Spinner label="Uploading receipt" ariaLabel="Uploading receipt" />}
                {!isUploadingReceipt && (
                    <>
                        <PanelStyled.FileSelectionArea>
                            <PanelStyled.BrowseFilesArea>
                                <PanelStyled.Subheader>Browse Files</PanelStyled.Subheader>
                                <FileUploader accepts={''} callback={setSelectedFile} />
                            </PanelStyled.BrowseFilesArea>
                            {/* take picture of receipt */}
                            <PanelStyled.Subheader>Take a Picture</PanelStyled.Subheader>
                            <FileSelectorButton
                                text="Take Picture of Receipt"
                                // secondaryText="Limited to 5 MB in size"
                                onClick={launchModal}
                                iconName="Camera"
                            >
                                <FilePictureUpload
                                    isOpen={isModalOpen}
                                    onDismiss={closeModal}
                                    callback={setSelectedFile}
                                />
                            </FileSelectorButton>
                        </PanelStyled.FileSelectionArea>
                        <SharedStyled.Divider />
                        {/* show the uploaded document */}
                        <PanelStyled.FileSelectedArea>
                            <PanelStyled.Subheader>File Selected</PanelStyled.Subheader>
                            {fileSelected ? (
                                <Stack horizontal styles={PanelStyled.DocSelectedStackStyles}>
                                    <Stack.Item>
                                        <Icon style={PanelStyled.fileUploadIconStyle} iconName="TextDocument" />
                                    </Stack.Item>
                                    <Stack.Item>
                                        <Stack>
                                            <Stack.Item>
                                                <PanelStyled.BoldedText>{fileSelected.name}</PanelStyled.BoldedText>
                                            </Stack.Item>
                                            <Stack.Item>
                                                <PanelStyled.SecondaryText>
                                                    {fileSelected.type}
                                                </PanelStyled.SecondaryText>
                                            </Stack.Item>
                                        </Stack>
                                    </Stack.Item>
                                </Stack>
                            ) : (
                                <PanelStyled.DocNotSelectedStyle>
                                    <PanelStyled.SecondaryTextItalics>
                                        No File Selected
                                    </PanelStyled.SecondaryTextItalics>
                                </PanelStyled.DocNotSelectedStyle>
                            )}
                        </PanelStyled.FileSelectedArea>
                    </>
                )}
            </div>
        );
    };

    return (
        <>
            <Stack>
                <CoherencePanel
                    panelSize={CoherencePanelSize.small}
                    titleText={'Add a Receipt'}
                    isOpen={isPanelOpen}
                    telemetryHook={telemetryHook}
                    onRenderNavigation={() => {
                        return (
                            <Stack.Item>
                                <Stack>
                                    <Stack.Item style={{ flexFlow: 'row' }}>
                                        <CloseButton
                                            action={() => {
                                                updatePanel();
                                            }}
                                        />
                                    </Stack.Item>
                                </Stack>
                            </Stack.Item>
                        );
                    }}
                >
                    {getPanelContent()}
                    <Stack horizontal styles={PanelStyled.Footer}>
                        <Stack.Item styles={PanelStyled.SmallSpace}>
                            <BasicButton
                                primary={true}
                                text="Add"
                                title="Add"
                                disabled={checkRequiredFieldFilled()}
                                onClick={() => {
                                    onSubmitButtonClicked();
                                }}
                            />
                        </Stack.Item>
                        <Stack.Item styles={PanelStyled.SmallSpace}>
                            <BasicButton text="Cancel" title="Cancel" onClick={updatePanel} />
                        </Stack.Item>
                    </Stack>
                </CoherencePanel>
            </Stack>
        </>
    );
}

const connected = withContext(ReceiptUploadPanel);
export { connected as ReceiptUploadPanel };
