import * as React from 'react';
import { useContext } from 'react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { ICoherenceHeaderProps } from '@coherence-design-system/controls/lib/header';
import { HelpPanel } from './Components/Header/HelpPanel/HelpPanel';
import { SettingsPanel } from './Components/Header/SettingsPanel/SettingsPanel';
import { feedbackLink } from './Shared/SharedConstants';
import { IExpenseAppState } from './Shared/Store/Expense.types';
import { expenseReducerName, expenseInitialState } from './Shared/Store/Expense.reducer';

export function useHeaderConfig(): ICoherenceHeaderProps {
    const { useSelector, telemetryClient: telemetryHook } = useContext(
        Context as React.Context<IEmployeeExperienceContext>
    );
    const { settingsPageShowing } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );
    return {
        headerLabel: __APP_NAME__,
        appNameSettings: {
            label: __APP_NAME__,
        },
        farRightSettings: {
            settingsSettings: {
                panelSettings: {
                    body: <SettingsPanel />,
                    titleText: settingsPageShowing,
                    hasCloseButton: false,
                },
            },
            helpSettings: {
                panelSettings: {
                    title: 'Help',
                    titleText: 'Help',
                    hasCloseButton: false,
                    body: <HelpPanel />,
                },
            },
            feedbackSettings: {
                panelSettings: {
                    ocvButtonIsFocused: false,
                    onClick: () => {
                        window.open(feedbackLink, 'MyExpenseFeedback');
                        return false;
                    },
                    launchOptions: {
                        categories: {
                            show: true,
                            customCategories: [
                                'Dashboard',
                                'Feed',
                                'Catalog',
                                'Vision',
                                'Hearing',
                                'Mobility',
                                'Cognitive',
                            ],
                        },
                    },
                },
            },
        },
    };
}
