import { IDetailsState } from './Details.types';
import { DetailsAction, DetailsActionType } from './Details.action-types';

export const detailsReducerName = 'DetailsReducer';
export const detailsInitialState: IDetailsState = {
    adaptiveJSON: null,
    isLoadingDetails: false,
    hasError: false,
    errorMessage: null,
    actionMessage: null,
    documentPreview: null,
    isPreviewOpen: false,
    actionDetails: null,
    isLoadingPreview: false,
    toggleDetailPanelWidth: false,
    expenseStatus: null,
    expenseReportId: null,
    postActionErrorMessage: null,
    submitSuccessMessage: null,
    actionType: null,
    statFormExpenseReportId: null,
    statFormReportCountry: null,
    statFormReportData: null,
    statFormReportDataFailedMessage: null,
    isLoadingUserImage: false,
    userImage: null,
    userImageHasError: false,
    userImageErrorMessage: null,
    postActionHasError: false,
    panelWidth: 600,
    footerHeight: 64,
    isModalPreviewOpen: false,
    documentDownloadHasError: false,
    documentDownloadErrorMessage: null,
    isDocumentDownloading: false,
    isAllDocumentsDownloading: false,
};

export function detailsReducer(prev: IDetailsState = detailsInitialState, action: DetailsAction): IDetailsState {
    switch (action.type) {
        case DetailsActionType.REQUEST_MY_DETAILS:
            return {
                ...prev,
                isLoadingDetails: true,
                hasError: false,
                isProcessingAction: false,
                adaptiveJSON: null,
                postActionErrorMessage: null,
                submitSuccessMessage: null,
                isLoadingUserImage: true,
            };
        case DetailsActionType.REQUEST_DOCUMENT_PREVIEW:
            return {
                ...prev,
                isLoadingPreview: true,
                isPreviewOpen: !action.isModal,
                isModalPreviewOpen: action.isModal,
                documentPreviewHasError: false,
                documentDownloadHasError: false,
            };

        case DetailsActionType.RECEIVE_MY_DETAILS:
            return {
                ...prev,
                isLoadingDetails: false,
                hasError: false,
                adaptiveJSON: action.adaptiveDetails,
            };
        case DetailsActionType.FAILED_DETAILS:
            return {
                ...prev,
                isLoadingDetails: false,
                hasError: true,
                errorMessage: action.errorMessage,
            };
        case DetailsActionType.UPDATE_REQUEST:
            return {
                ...prev,
                expenseReportId: action.expenseReportId,
                expenseStatus: action.expenseStatus,
                actionType: action.actionType,
                postActionErrorMessage: null,
                submitSuccessMessage: null,
            };

        case DetailsActionType.UPDATE_ACTION_TYPE:
            return {
                ...prev,
                actionType: action.actionType,
                postActionErrorMessage: null,
                submitSuccessMessage: null,
            };

        case DetailsActionType.RECEIVE_DOCUMENT_PREVIEW:
            return {
                ...prev,
                documentPreview: action.filePreview,
                isLoadingPreview: false,
            };
        case DetailsActionType.CLEAR_DOCUMENT_PREVIEW:
            return {
                ...prev,
                documentPreview: null,
            };

        case DetailsActionType.CLOSE_DOCUMENT_PREVIEW:
            return {
                ...prev,
                isPreviewOpen: false,
                isModalPreviewOpen: false,
            };
        case DetailsActionType.TOGGLE_DETAIL_PANEL_WIDTH:
            return {
                ...prev,
                toggleDetailPanelWidth: !prev.toggleDetailPanelWidth,
            };
        case DetailsActionType.SUBMIT_EXPENSE_DETAILS:
            return {
                ...prev,
                postActionHasError: false,
                isProcessingAction: true,
            };
        case DetailsActionType.SUBMIT_EXPENSE_DETAILS_SUCCESS:
            return {
                ...prev,
                submitSuccessMessage: action.submitSuccessMessage,
                isProcessingAction: false,
            };
        case DetailsActionType.SUBMIT_EXPENSE_DETAILS_FAILED:
            return {
                ...prev,
                isProcessingAction: false,
                postActionHasError: true,
                postActionErrorMessage: action.postActionErrorMessage,
            };
        case DetailsActionType.STAT_FORM_REQUEST_EXPENSE_DETAILS:
            return {
                ...prev,
                statFormExpenseReportId: action.expenseReportId,
                statFormReportCountry: action.selectedCountry,
            };
        case DetailsActionType.STAT_FORM_RESPONSE_EXPENSE_DETAILS:
            return {
                ...prev,
                statFormReportData: action.data,
            };
        case DetailsActionType.STAT_FORM_FAILED_EXPENSE_DETAILS:
            return {
                ...prev,
                statFormReportData: null,
                statFormReportDataFailedMessage: action.message,
            };
        case DetailsActionType.RECEIVE_USER_IMAGE:
            return {
                ...prev,
                isLoadingUserImage: false,
                userImageHasError: false,
                userImage: action.userImage,
            };
        case DetailsActionType.FAILED_USER_IMAGE:
            return {
                ...prev,
                isLoadingUserImage: false,
                userImageHasError: true,
                userImageErrorMessage: action.userImageErrorMessage,
            };

        case DetailsActionType.REQUEST_DOCUMENT_START:
            return {
                ...prev,
                isDocumentDownloading: true,
                documentDownloadHasError: false,
                documentDownloadErrorMessage: null,
            };
        case DetailsActionType.REQUEST_DOCUMENT_END:
            return {
                ...prev,
                isDocumentDownloading: false,
            };
        case DetailsActionType.REQUEST_DOCUMENT:
            return {
                ...prev,
            };
        case DetailsActionType.FAILED_DOCUMENT_PREVIEW:
            return {
                ...prev,
                isLoadingPreview: false,
                documentPreviewHasError: true,
                documentPreviewErrorMessage: action.documentPreviewErrorMessage,
            };
        case DetailsActionType.FAILED_DOCUMENT_DOWNLOAD:
            return {
                ...prev,
                isDocumentDownloading: false,
                documentDownloadHasError: true,
                documentDownloadErrorMessage: action.documentDownloadErrorMessage,
            };
        case DetailsActionType.REQUEST_ALL_DOCUMENTS_START:
            return {
                ...prev,
                isAllDocumentsDownloading: true,
                documentDownloadHasError: false,
                documentDownloadErrorMessage: null,
            };
        case DetailsActionType.REQUEST_ALL_DOCUMENTS_END:
            return {
                ...prev,
                isAllDocumentsDownloading: false,
            };
        case DetailsActionType.REQUEST_ALL_DOCUMENTS:
            return {
                ...prev,
            };
        case DetailsActionType.FAILED_ALL_DOCUMENT_DOWNLOAD:
            return {
                ...prev,
                isAllDocumentsDownloading: false,
                documentDownloadHasError: true,
                documentDownloadErrorMessage: action.documentDownloadErrorMessage,
            };
        default:
            return prev;
    }
}
