import * as React from 'react';
import * as Styled from './CardStyling.styled';

export function CountBanner(props: { countValue: number; title: string; imageUrl: string; }): React.ReactElement {
    const { countValue, title, imageUrl } = props;
    return (
        <Styled.CountBanner>
            <Styled.CountRow>
                <Styled.CountData>
                    <Styled.CountNumber>{countValue}</Styled.CountNumber>
                    <Styled.SemiboldText>{title}</Styled.SemiboldText>
                </Styled.CountData>
                <Styled.CountImage imageUrl={imageUrl}/>
            </Styled.CountRow>
        </Styled.CountBanner>
    );
}
