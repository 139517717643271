import { IGroup } from '@fluentui/react';

import { IFAQList } from '../TrainingPage';

export const Topics: IGroup[] = [
    { key: 'beginningReport', name: 'Beginning Your Report', startIndex: 0, count: 6, level: 0 },
    { key: 'attachingReceipts', name: 'Attaching Receipts', startIndex: 6, count: 1, level: 0 },
    { key: 'delegates', name: 'Delegates or Acting on Behalf of', startIndex: 2, count: 0, level: 0 },
    { key: 'itemization', name: 'Itemization and Distribution', startIndex: 2, count: 0, level: 0 },
    { key: 'categories', name: 'MyExpense Categories', startIndex: 2, count: 0, level: 0 },
    { key: 'approvals', name: 'Approvals', startIndex: 2, count: 0, level: 0 },
];

export const SubTopics = [
    {
        key: 'loggingIntoMyExpenseTool',
        name: 'Logging into MyExpense Tool',
        link: 'https://expense.microsoft.com/document/Logging into MyExpense tool.pdf',
    },
    {
        key: 'submittingExpenseReport',
        name: 'Submitting an Expense Report',
        link: 'https://expense.microsoft.com/document/Submitting an Expense Report.pdf',
    },
    {
        key: 'addingUnreconciledExpensesToExpenseReport',
        name: 'Adding Unreconciled Expenses to an Expense Report',
        link: 'https://expense.microsoft.com/document/Adding unreconciled expenses to an expense report.pdf',
    },
    {
        key: 'addingGuestDetailsToExpenseReport',
        name: 'Adding Guest Details to Expense Report',
        link: 'https://expense.microsoft.com/document/Adding Guest Details to Expense Report.pdf',
    },
    {
        key: 'assigningAndChangingCostCenterOrIO',
        name: 'Assigning and Changing Cost Center or IO',
        link: 'https://expense.microsoft.com/document/Assigning and Changing Cost Center or IO.pdf',
    },
    {
        key: 'assigningAndChangingExpenseCategory',
        name: 'Assigning and Changing Expense Category',
        link: 'https://expense.microsoft.com/document/Assigning and Changing Expense Category.pdf',
    },

    {
        key: 'addingViewingDeletingReceipts',
        name: 'Adding Viewing and Deleting Receipts ',
        link: 'https://expense.microsoft.com/document/Adding Viewing and Deleting Receipts.pdf',
    },
];

export const OverviewList: Array<IFAQList> = [
    {
        title: 'MyExpense Overview',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=caa447d9-40ff-4eed-b4ae-1785b1651e4a&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:b:/t/ExpenseMicrosoft/EQ6LPBjNKyJDkOJDkOQkVoABdilZ7cNJWWBWty35Yrc1Jw?e=mXrXsS',
    },
    {
        title: 'MyExpense + Touchless Expense Readiness Session',
        text: '',
        videoUrl:
            '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:p:/t/ExpenseMicrosoft/EWo6xFnRIihAgHjMbUfHxM0BI0pmSFNqSSHffqtFLODPYQ?e=iAqj7l',
    },
];

export const CommonScenariosList: Array<IFAQList> = [
    {
        title: 'Navigating to the MyExpense tool',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=3874263e-5a07-4f27-b156-93d3d5dd4a4f&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EbKNKcJZVzRNiyd82io7_8YB89KGcd3AMvDtZVAvthMH-g?e=yrEYFp',
    },
    {
        title: 'Creating a new Expense Report',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=f94eb800-ee80-45e6-a553-73e192c49462&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EY_VVo5PWVVCrRQj0cs1hbQBndIEXVwsMj7eh6_HJEslaw?e=KxTc8O',
    },
    {
        title: 'Adding unreconciled expenses',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=ea686eca-8ed8-4fbf-95dd-24ceac8aabd7&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ERE1dbkmATZBu34K8DXqC2UBH7hsTcPUwbasfe4Mj3_8jw?e=4yL6oo',
    },
    {
        title: 'Adding cash expenses',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EddJ0xAVbI5Coa6ivW-3_58BdxQGwuEOXXBO7mZi_kcDCw?e=Qb1cWd',
    },
    {
        title: 'Adding Per Diem Expenses',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=1722fb6b-37d3-4d43-ab0f-afe6515235d5&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ETcIcXyA0X5Hk6_vEqZ4t5IBbumFFoMRB41K_mSm5CLQTQ?e=7t6xPT',
    },
    {
        title: 'Attaching, Viewing and Deleting Receipts',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=2093ff4b-049e-4504-a3fa-c02b1f55ed01&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EazOUOqGNxRKpJpDYdK8EXQBpzWQP0pHOvL1J_idZXAYmw?e=xpTZVm',
    },
    {
        title: 'Adding Guest Details',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=bab8d3b0-1a8c-47e8-b0b1-7b0a150083b3&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ETD7zKM7tNdPu12U4sgnYjQBiL7gT4V09h3jJsx_CbkkKg?e=zfSa36',
    },
    {
        title: 'Adding an Interim Approver',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=30b5e2a7-84cf-43c2-8a06-522d1e32b24c&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EWDYtqTR2FxLo6LUrqWfWakBJRqnfJJIsy_qGj7uE7nsNg?e=JcKWx9',
    },
    {
        title: 'Changing Final Approver',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=f3acabc4-68bd-4858-a036-e48816e6babd&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ETfZD4_FEZ5CmeECSuzGwZwBQ3SWmG6n5yRdnxwhafeYOQ?e=taTfFf',
    },
    {
        title: 'Changing an IO or Cost Center',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=de3327a1-ba4f-4b4a-972e-b85f37e584a0&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EW01NBJ2N4RFsFGRRBXxoi0BK3kYniaAaa7U_H2hIo_HYQ?e=R0SLLa',
    },
    {
        title: 'Itemizing Hotel Expenses',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=b67c6737-b1c1-4b03-a030-a1e425c288f5&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/Ef9yEjAowM5AsKPpgDPXqkcBsR6ofcWvA-RJeuaqaqcCFw?e=Jq5ckP',
    },
    {
        title: 'Splitting or Marking an entire expense into Personal',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=4be692ec-4ff7-4527-9365-7279cf3d4a4d&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ETzr3SCYaOlBq1vYlcTg6xYBBL6Dz0HCKk4HhoTU_0D9Zg?e=RIO1ec',
    },
    {
        title: 'Recurring Expenses - Using Copy Feature to create a new Expense Report',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=46e12b17-3fd8-44fb-9856-fdf403614256&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EXknlB5C9R5Jp-jVNbCEBIQBk27iGyHj6XRlLppCtB75Ew?e=W2zjUg',
    },
    {
        title: 'Assigning and Changing Expense Category',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EfqnQW4Lff1Ej-fKKEekyMUBfXcUmKjVHvCeXJhU-IWKtA?e=ehAf5h',
    },
    {
        title: 'Receipt Submission',
        text: '',
        videoUrl: 'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=3313a35d-49a1-4d8a-91e7-b58960fcfa79&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            '',
    },
    {
        title: 'Attaching Receipt to an Existing Report',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EU9hHPI703VOne7wal-21gsB-F0EOX8q26l6ocgAcE0cSQ?e=etTqAR',
    },
    {
        title: 'Recalling an Expense Report',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EQXABgJAyEZMtQTxuMBIlx8B3g_lWCDyEPTKDuSvSbt48w?e=Wfdjc2',
    },
];

export const DelegationList: Array<IFAQList> = [
    {
        title: 'Acting as a delegate',
        text: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/Ea9dRkNAnsJGlx0rA_9QmxgBz-JjgCveyNlLpNl0U9HnIw?e=jFYW2R',
    },
    {
        title: 'Adding expense submitter and final approver delegates',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=59d586bf-0705-4c24-b19a-61280b38f581&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/ET0-LlSiJxdPveFNNKkxXE8BgREnCw1M5Qz5gXX7O6-ZIg?e=E4DWWC',
    },
];

export const OccasionalScenariosList: Array<IFAQList> = [
    {
        title: 'Creating a Government Official Expense Report with pre-approved number',
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=21d0b97c-4387-4d4f-b451-04fb1e137ebb&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EcnhBVLQ6HBPj8fjxNxwCdQBsxSqqtQi0AjQOz6G7ucQxg?e=GCeqBw',
    },
    {
        title: "Distributing an expense into multiple IO's or split into multiple cost centers",
        text: '',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=cc86e85b-496b-4002-bb54-fda9d7276c8e&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EaJAEuaEg2dPiFCFTO_08iMB8plrYwwAzB8zkR8Vn7FyvA?e=js5jnd',
    },
    {
        title: 'Changing Foreign Currency Exchange Rate',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EcKk6WXm2T5KolglSPSPNIQB5C52nGkzHl8Ywhw-t3nNpg?e=YCGwVk',
    },
    {
        title: 'Checking Status of an Expense Report',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EShNj-5aBGlHqpymbWa2qI8BtNMxw5ZQsMWVrzd4feOXNw?e=14t9hb',
    },
    {
        title: 'Printing Expense Report Cover Page',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EVJTPSLqdsNOplwTi-E66HoBA7X04CQfr-qEmQ8XQwvTig?e=1NJfql',
    },
    {
        title: 'GitHub Employee acting as a Delegate in MyExpense',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EVZ2qJ79RnxPkno4tty9A6sBykIeOsTXjl2mbr2UVdwOhQ?e=F2Wgdd',
    },
];

export const OtherList: Array<IFAQList> = [
    {
        title: 'MyExpense Migration - Frequently asked questions',
        text: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/Ec8Teasq9xVChr3A5RG6cWEBeOsaBb80x9jeVYGaH8Qs5A?e=ljfusV',
    },
    {
        title: 'MyExpense China Migration - Frequently asked questions',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EaJRECDC9UxAqk_-zeoecLcBbEhI18woMX0x_5II8VsW-Q?e=olj5yT',
    },
    {
        title: 'MyExpense Known Accessibility Issues',
        text: '',
        videoUrl: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EV8UbSEKrPRIpCv28dmnQsEB1IE6-qAXxeenkPFg8k71Pw?e=ajtrkZ',
    },
    {
        title: 'MyExpense Categories',
        text: '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:x:/t/ExpenseMicrosoft/ESigAsOGGkNFtZ8UmepvnBUBWukFXhBTOZhomGLRfuc0Ng?e=H9bJEK',
    },
];

export const AutomationList: Array<IFAQList> = [
    {
        title: 'Touchless Expense Overview',
        text: '',
        videoWidth: '90%',
        videoHeight: '90%',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=c73ff92b-2844-4c84-ae84-7b88a5c63cc6&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EYUSuC_hTolHuV_LnS67SVMBRHILbSXxWYZFT2it59H-tQ?e=88gLU5',
    },
    {
        title: 'Updating User Preferences',
        text: '',
        videoWidth: '90%',
        videoHeight: '90%',
        videoUrl:
            'https://microsoft.sharepoint.com/teams/ExpenseMicrosoft/_layouts/15/embed.aspx?UniqueId=5809e789-5ef7-4f87-a9a4-03fdcad943ea&embed=%7B%22ust%22%3Atrue%2C%22hv%22%3A%22CopyEmbedCode%22%7D&referrer=StreamWebApp&referrerScenario=EmbedDialog.Create',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EbZxpviUGbtNihED0esNAqYBIuKwCMN9mf8lGsdiL_hxMQ?e=23Dom7',
    },
];

export const RiskScoreList: Array<IFAQList> = [
    {
        title: 'Risk Score Levels',
        text: '',
        videoUrl:
            '',
        videoWidth: '90%',
        videoHeight: '90%',
        textAsHeader: 'h4',
        isExpanded: false,
        fullScreen: true,
        documentUrl:
            'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EZrhn_6UBo5PoK19uNgG9mwB8e_sHx82F2-xKaxlerqZsg?e=cBfXiL',
    },
];

export const TrainingTopics = [
    { heading: 'MyExpense Overview', subtopics: OverviewList, id: 'MyExpenseOverview' },
    { heading: 'Creating an Expense Report', subtopics: CommonScenariosList, id: 'CommonScenarios' },
    { heading: 'Delegate Scenarios', subtopics: DelegationList, id: 'DelegateScenarios' },
    { heading: 'Occasional Scenarios', subtopics: OccasionalScenariosList, id: 'OccasionalScenarios' },
    { heading: 'Touchless Expense Automation', subtopics: AutomationList, id: 'Automation' },
    { heading: 'Risk Score Levels', subtopics: RiskScoreList, id: 'RiskScoreLevels' },
    { heading: 'Other Guides', subtopics: OtherList, id: 'Other' },
];
