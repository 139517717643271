export enum TextColors {
    darkPrimary = '#ffffff',
    lightPrimary = '#1b1a19',
    secondary = '#605e5c',
    disabled = '#c8c6c4',
  }
  
  export enum MessagingColors {
    errorBlockIcon = '#c5262c',
  }
  