import * as React from 'react';
import { IconButton } from '@fluentui/react';
import * as LayoutStyled from '../../../Shared/Styles/SharedLayout.styled';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { clearDocumentPreview, closeDocumentPreview, updateActionType } from '../Details.actions';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

function BackButton(): React.ReactElement {
    const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    return (
        <TooltipHost
            content="Back"
            calloutProps={LayoutStyled.tooltipCalloutProps}
            styles={LayoutStyled.tooltipHostContainer}
        >
            <IconButton
                iconProps={{ iconName: 'Back' }}
                title="Go Back"
                ariaLabel="Go Back"
                onClick={(): void => {
                    dispatch(clearDocumentPreview());
                    dispatch(closeDocumentPreview());
                    dispatch(updateActionType(null));
                }}
            />
        </TooltipHost>
    );
}

const connected = withContext(BackButton);
export { connected as BackButton };
