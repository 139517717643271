import { IStackTokens, IStackStyles } from '@fluentui/react/lib/Stack';

export const Container = styled.div`
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 2%;
    margin-right: 5%;
`;

export const PageContainer = styled.div`
    padding-top: 16px;
    margin: 0;

    @media only screen and (max-width: 480px) {
        padding-top: 0px !important;
    }
`;

export const ReceiptContainer = styled.div<{windowHeight: number}>`
    padding: 1% 2% 0 2%;
    height: ${props => props.windowHeight - 150}px;

    @media only screen and (max-device-width: 480px) {
        margin-left: 0;
    }
    @media only screen and (max-width: 320px) {
        height: ${props => props.windowHeight - 50}px;
    }
`;

export const ReceiptsTitle = styled.h1`
    padding-bottom: 4px;
`;

export const ReceiptNavStackStyles: IStackStyles = {
    root: {
        marginBottom: '1%',
        marginTop: '1%'
    }
};

export const ReceiptNavStackTokens: IStackTokens = {
    childrenGap: 15,
};

export const ReceiptTableContainer = styled.div<{windowHeight: number, windowWidth: number}>`
    position: relative;
    padding-right: 1%;
    padding-bottom: 5%;
    overflow-y: hidden;
    background-color: white;
    height: ${props => (props.windowWidth < 1024 ? props.windowHeight - 10 : props.windowHeight - 250)}px;

    @media only screen and (max-width: 320px) {
        overflow-y: initial;
    }
`;