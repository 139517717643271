export const ExpenseDashboardIcon = styled.div`
    width: 18px;
    height: 18px;
    background-image: url('/navIcons/ExpenseDashboardIcon.svg');
    background-size: 18px 18px;

    @media screen and (forced-colors: active) {
        background-image: url('/navIcons/ExpenseDashboardIconWhite.png');
    }
`;

export const TrainingIcon = styled.div`
    width: 18px;
    height: 18px;
    background-image: url('/navIcons/TrainingIcon.svg');
    background-size: 18px 18px;

    @media screen and (forced-colors: active) {
        background-image: url('/navIcons/TrainingIconWhite.png');
    }
`;
