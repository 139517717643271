import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { TextField } from '@fluentui/react/lib/TextField';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { HttpClient } from '@micro-frontend-react/employee-experience/lib/HttpClient';
import {
    Stack,
    DefaultButton,
    PrimaryButton,
    Dropdown,
    ChoiceGroup,
    TooltipHost,
    IChoiceGroupOption,
    IDropdownOption,
    NormalPeoplePicker,
    IPersonaProps,
    IBasePickerSuggestionsProps,
    IconButton,
    IIconProps,
    Spinner,
} from '@fluentui/react';
import * as React from 'react';
import {
    updateDropdownHasError,
    updateSettingsPageShowing,
    saveUserPreferences,
    requestUserPreferences,
    saveUserPreferencesSuccess,
} from '../../../../Shared/Store/Expense.actions';
import { expenseReducerName, expenseReducer } from '../../../../Shared/Store/Expense.reducer';
import * as SharedStyled from '../../../../Shared/Styles/SharedLayout.styled';
import * as Styled from './UserPreferences.styled';
import {
    getIsLoadingProfile,
    getIsLoadingUserPreferences,
    getIsSavingUserPreferences,
    getProfile,
    getSaveUserPreferencesHasError,
    getSaveUserPreferencesResponseMessages,
    getUserPreferences,
} from '../../../../Shared/Store/Expense.selectors';
import { useRef } from 'react';
import { sharedExpenseSagas } from '../../../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import SuccessViewBar from '../../../../Shared/Components/MessageBars/SuccessViewBar';
import ErrorView from '../../../../Shared/Components/MessageBars/ErrorView';
import * as Constants from './UserPreferenceConstants';

function UserPreferences(): React.ReactElement {
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas], false);
    const { useSelector, telemetryClient, dispatch, authClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const httpClient = new HttpClient(telemetryClient, authClient);

    const userPreferences = useSelector(getUserPreferences);
    const isLoadingUserPreferences = useSelector(getIsLoadingUserPreferences);
    const saveUserPreferencesHasError = useSelector(getSaveUserPreferencesHasError);
    const saveUserPreferencesResponseMessages = useSelector(getSaveUserPreferencesResponseMessages);
    const isSavingUserPreferences = useSelector(getIsSavingUserPreferences);
    const profile = useSelector(getProfile);
    const isLoadingProfile = useSelector(getIsLoadingProfile);

    const [localSubmitterDelegate, setLocalSubmitterDelegate] = React.useState<string[]>(null);
    const [localApproverDelegate, setLocalApproverDelegate] = React.useState<string[]>(null);
    const [localInterimApprovers, setLocalInterimApprovers] = React.useState<IPersonaProps[]>(null);
    const [localFinalApprover, setLocalFinalApprover] = React.useState<IPersonaProps[]>(null);
    const [localBundlingFrequency, setLocalBundlingFrequency] = React.useState('Weekly');
    const [localIsAutomationPaused, setLocalIsAutomationPaused] = React.useState('false');
    const [pauseReasonInputValue, setPauseReasonInputValue] = React.useState('');
    const [pauseReasonErrorMessage, setPauseReasonErrorMessage] = React.useState('');
    const [showUserPreferences, setShowUserPreferences] = React.useState(false);

    const displayPauseAutomationReason = localIsAutomationPaused === 'true';

    React.useEffect(() => {
        if (saveUserPreferencesHasError) {
            clearUserPrefState();
            resetUserPreferences();
        }
    }, [saveUserPreferencesHasError]);

    React.useEffect(() => {
        if (!isLoadingUserPreferences && userPreferences) {
            if (userPreferences.bundlingFrequency) {
                setLocalBundlingFrequency(userPreferences.bundlingFrequency);
            }
            if (
                typeof userPreferences.defaultFinalApprover === 'string' &&
                typeof userPreferences.defaultFinalApproverName === 'string'
            ) {
                if (userPreferences.defaultFinalApprover !== '' && userPreferences.defaultFinalApproverName !== '') {
                    setLocalFinalApprover([
                        {
                            text: userPreferences.defaultFinalApproverName,
                            secondaryText: userPreferences.defaultFinalApprover,
                        },
                    ]);
                } else {
                    setLocalFinalApprover([]);
                }
            }
            if (userPreferences.defaultInterimApprovers && userPreferences.defaultInterimApproverNames) {
                if (userPreferences.defaultInterimApproverNames.length > 0) {
                    const personaMappedInterimApprovers: IPersonaProps[] = userPreferences.defaultInterimApprovers.map(
                        (item, index) => {
                            return {
                                text: userPreferences.defaultInterimApproverNames[index],
                                secondaryText: item,
                            };
                        }
                    );
                    setLocalInterimApprovers(personaMappedInterimApprovers);
                } else {
                    setLocalInterimApprovers([]);
                }
            }
            if (typeof userPreferences.isAutomationDisabled === 'boolean') {
                setLocalIsAutomationPaused(userPreferences.isAutomationDisabled.toString());
            }
            if (userPreferences.isAutomationDisabled && typeof userPreferences.feedback === 'string') {
                setPauseReasonInputValue(userPreferences.feedback);
            }
        }
    }, [isLoadingUserPreferences]);

    React.useEffect(() => {
        const companyCode: string = profile ? profile.companyCode : "";
        if (__AUTOMATION_PREFERENCES_AVAILABLE__.includes(companyCode)) {
            dispatch(requestUserPreferences());
            setShowUserPreferences(true);
        }
        else {
            setShowUserPreferences(false);
        }
    }, [profile?.companyCode, isLoadingProfile]);

    const infoIcon: IIconProps = { iconName: 'InfoSolid' };

    const picker = React.useRef(null);

    const suggestionProps: IBasePickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested People',
        noResultsFoundText: 'No results found',
        loadingText: 'Loading',
    };

    const onFilterChanged = (filterText: string): IPersonaProps[] | Promise<IPersonaProps[]> => {
        if (filterText) {
            return new Promise((resolve) => {
                //calling graph api directly here instead of using sagas because api response value needs to be returned
                //TODO: add telemetry
                try {
                    httpClient
                        .request({
                            url: `${__GRAPH_BASE_URL__}users?$search="displayName:${filterText}" OR "userPrincipalName:${filterText}"`,
                            resource: __GRAPH_RESOURCE_URL__,
                            header: {"ConsistencyLevel": "eventual"}
                        })
                        .then((result) =>
                            resolve(
                                (result.data as any).value.map((item: any) => {
                                    console.log("RESULT", item)
                                    return {
                                        text: item.displayName,
                                        upn: item.userPrincipalName,
                                        secondaryText: item.userPrincipalName,
                                    };
                                })
                            )
                        );
                } catch {
                    resolve([]);
                }
            });
        } else {
            return [];
        }
    };

    function getTextFromItem(persona: IPersonaProps): string {
        return persona.text as string;
    }

    const isMounted = useRef(false);

    const frequencyChange = (ev: React.FormEvent<HTMLElement>, value: IDropdownOption) => {
        isMounted.current = true;
        setLocalBundlingFrequency(value.text);
        onDropdownSelectionChange(value);
    };

    function onExpenseAutomationChange(
        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
        option?: IChoiceGroupOption
    ) {
        if (option?.key == 'true') {
            setLocalIsAutomationPaused('true');
        } else if (option?.key == 'false' || option?.key == null) {
            setLocalIsAutomationPaused('false');
        }
    }

    function onExpenseAutomationFeedbackChange(name: string, value: string) {
        if (value?.length > 0) {
            setPauseReasonInputValue(value);
            if (pauseReasonErrorMessage) {
                setPauseReasonErrorMessage('');
            }
        } else {
            setPauseReasonInputValue('');
        }
    }

    function onDropdownSelectionChange(value: IDropdownOption): void {
        if (value.text !== 'Weekly' && value.text !== 'Every other Week' && value.text !== 'Monthly') {
            dispatch(updateDropdownHasError(true));
        } else if (value.text == 'Weekly' || value.text == 'Every other Week' || value.text == 'Monthly') {
            setLocalBundlingFrequency(value.text);
            dispatch(updateDropdownHasError(false));
        }
    }

    function onSubmitterChange(items: IPersonaProps[]): void {
        setLocalSubmitterDelegate(
            items?.map((item: IPersonaProps) => item.secondaryText?.replace('@microsoft.com', ''))
        );
    }

    function onApproverChange(items: IPersonaProps[]): void {
        setLocalApproverDelegate(
            items?.map((item: IPersonaProps) => item.secondaryText?.replace('@microsoft.com', ''))
        );
    }

    function onInterimApproverChange(items: IPersonaProps[]): void {
        setLocalInterimApprovers(items);
    }

    function onFinalApproverChange(items: IPersonaProps[]): void {
        setLocalFinalApprover(items);
    }

    const resetUserPreferences = (): void => {
        dispatch(requestUserPreferences());
    }

    const clearUserPrefState = (): void => {
        setPauseReasonInputValue('');
        setPauseReasonErrorMessage('');
    };

    const onBackButtonClicked = () => {
        dispatch(updateSettingsPageShowing('Settings'));
        dispatch(saveUserPreferencesSuccess(null));
        clearUserPrefState();
    };

    const onSaveButtonClicked = () => {
        let areInputsValid = localIsAutomationPaused === 'false';
        if (localIsAutomationPaused === 'true') {
            const pauseReasonWithoutWhitespace = pauseReasonInputValue?.replace(/\s/g, '');
            if (pauseReasonWithoutWhitespace && pauseReasonWithoutWhitespace.length > 0) {
                areInputsValid = true;
            } else {
                setPauseReasonErrorMessage('Please provide a reason for pausing automation');
            }
        }
        if (areInputsValid) {
            const userPrefPayload = {
                submitterDelegates: localSubmitterDelegate || [],
                approverDelegates: localApproverDelegate || [],
                interimApprovers:
                    localInterimApprovers?.map((item: IPersonaProps) =>
                        item.secondaryText?.replace('@microsoft.com', '')
                    ) || [],
                finalApprover: localFinalApprover?.[0]?.secondaryText?.replace('@microsoft.com', '') || '',
                interimApproverNames: localInterimApprovers?.map((item: IPersonaProps) => item.text) || [],
                finalApproverName: localFinalApprover?.[0]?.text || '',
                bundlingFrequency: localBundlingFrequency || '',
                pauseAutomation: localIsAutomationPaused === 'true',
                feedback: localIsAutomationPaused === 'true' ? pauseReasonInputValue : '',
            };
            dispatch(saveUserPreferences(userPrefPayload));
        } else {
            //clear previous api success/failure messages
            dispatch(saveUserPreferencesSuccess(null));
        }
    };

    // GENERAL SETTINGS SCREEN
    // TODO: make a state value that gets whether the user has enabled automation or not (change from true default)

    const frequencyMenuProps: IDropdownOption[] = [
        {
            key: 'Weekly',
            text: 'Weekly',
        },
        {
            key: 'Every other Week',
            text: 'Every other Week',
        },
        {
            key: 'Monthly',
            text: 'Monthly',
        },
    ];

    const radioButtonOptions: IChoiceGroupOption[] = [
        {
            key: 'true',
            text: 'Yes',
        },
        {
            key: 'false',
            text: 'No',
        },
    ];

    const getUserPreferencePanelContent = () => {
        return (
            <div>
                {!isLoadingProfile && !showUserPreferences && (
                    <Styled.PanelContent>{Constants.USER_PREFERENCES_NOT_AVAILABLE_INFO}</Styled.PanelContent>
                )}
                {!isLoadingProfile && showUserPreferences && (
                    <Styled.ItalicPanelContent>{Constants.USER_PREFERENCES_DISCLAIMER}</Styled.ItalicPanelContent>
                )}
                {(isLoadingUserPreferences || isLoadingProfile) && (
                    <Spinner label="Loading user preferences" />
                )}
                {!saveUserPreferencesHasError && saveUserPreferencesResponseMessages?.length > 0 && (
                    <SuccessViewBar successMessage={saveUserPreferencesResponseMessages[0]} />
                )}
                {saveUserPreferencesHasError && saveUserPreferencesResponseMessages?.length > 0 && (
                    <ErrorView
                        errorMessages={saveUserPreferencesResponseMessages}
                        customTitle="An error occurred. Please find details below:"
                    />
                )}
                {isSavingUserPreferences && (
                    <Spinner label="Saving preferences" aria-label="Saving user preferences" />
                )}
                {!isLoadingUserPreferences && !isLoadingProfile && !isSavingUserPreferences && showUserPreferences && (
                    <div>
                        <Stack tokens={Styled.subHeader}>
                            <Stack>
                                <Stack.Item>
                                    <Stack horizontal>
                                        <Stack.Item>
                                            <Styled.PreferenceHeading>Expense preferences</Styled.PreferenceHeading>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TooltipHost content={Constants.EXPENSE_PREFERENCES_INFO}>
                                                <IconButton
                                                    aria-label={Constants.EXPENSE_PREFERENCES_INFO}
                                                    iconProps={infoIcon}
                                                    style={Styled.iconStyle}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack>
                        <Stack>
                            <Styled.PanelContent>Managing submitter and approver delegate functionality has moved to the dedicated page located at <b><a href="/ManageDelegates"> Manage Delegates</a></b></Styled.PanelContent> 
                        </Stack>
                        
                        <SharedStyled.Divider />
                        <Stack tokens={Styled.subHeader}>
                            <Stack>
                                <Stack.Item>
                                    <Stack horizontal>
                                        <Stack.Item>
                                            <Styled.PreferenceHeading>Automation preferences</Styled.PreferenceHeading>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TooltipHost content={Constants.AUTOMATION_PREFERNCES_INFO}>
                                                <IconButton
                                                    aria-label={Constants.AUTOMATION_PREFERNCES_INFO}
                                                    iconProps={infoIcon}
                                                    style={Styled.iconStyle}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack>
                        </Stack>

                        <Stack tokens={Styled.secondGroup}>
                            <Stack>
                                <Stack.Item>
                                    <Stack horizontal>
                                        <Stack.Item>
                                            <span>
                                                <b style={{ fontWeight: 600 }}>Set interim approver</b> (optional)
                                            </span>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TooltipHost content={Constants.INTERIM_APPROVER_INFO}>
                                                <IconButton
                                                    aria-label={Constants.INTERIM_APPROVER_INFO}
                                                    iconProps={infoIcon}
                                                    style={Styled.iconStyle}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <NormalPeoplePicker
                                        onResolveSuggestions={onFilterChanged}
                                        getTextFromItem={getTextFromItem}
                                        componentRef={picker}
                                        pickerSuggestionsProps={suggestionProps}
                                        selectedItems={localInterimApprovers}
                                        onChange={onInterimApproverChange}
                                        itemLimit={1}
                                        removeButtonAriaLabel="Remove selection for interim approver"
                                        inputProps={{ 'aria-label': 'Set interim approver' }}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    <Stack horizontal>
                                        <Stack.Item>
                                            <span>
                                                <b style={{ fontWeight: 600 }}>Set final approver</b> (optional)
                                            </span>
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TooltipHost content={Constants.FINAL_APPROVER_INFO}>
                                                <IconButton
                                                    aria-label={Constants.FINAL_APPROVER_INFO}
                                                    iconProps={infoIcon}
                                                    style={Styled.iconStyle}
                                                />
                                            </TooltipHost>
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item>
                                    <NormalPeoplePicker
                                        onResolveSuggestions={onFilterChanged}
                                        getTextFromItem={getTextFromItem}
                                        componentRef={picker}
                                        pickerSuggestionsProps={suggestionProps}
                                        selectedItems={localFinalApprover}
                                        onChange={onFinalApproverChange}
                                        itemLimit={1}
                                        removeButtonAriaLabel="Remove selection for final approver"
                                        inputProps={{ 'aria-label': 'Set final approver' }}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack>
                                <Stack.Item>
                                    <Dropdown
                                        label="Set expense bundling frequency"
                                        placeholder="Select an option"
                                        options={frequencyMenuProps}
                                        onChange={frequencyChange}
                                        defaultSelectedKey={localBundlingFrequency || 'Weekly'}
                                        ariaLabel="Select expense bundling frequency"
                                    />
                                </Stack.Item>
                            </Stack>
                        </Stack>

                        <Stack tokens={Styled.secondGroup}>
                            <Stack horizontal horizontalAlign="start">
                                <Stack.Item>
                                    <ChoiceGroup
                                        label="Do you want to pause expense automation?"
                                        required
                                        options={radioButtonOptions}
                                        onChange={onExpenseAutomationChange}
                                        selectedKey={localIsAutomationPaused}
                                    />
                                </Stack.Item>
                            </Stack>
                            <Stack.Item>
                                {displayPauseAutomationReason && (
                                    <TextField
                                        label="Reason for pausing automation"
                                        required
                                        multiline
                                        rows={3}
                                        name={'Reason for pausing automation'}
                                        value={pauseReasonInputValue}
                                        usageEvent={{ feature: 'User preferences', subFeature: 'Pause automation reason' }}
                                        onChange={onExpenseAutomationFeedbackChange}
                                        errorMessage={pauseReasonErrorMessage}
                                    />
                                )}
                            </Stack.Item>
                        </Stack>
                        <SharedStyled.Divider />
                        <div className={Styled.footerStyle}>
                            <Stack horizontal horizontalAlign="start" verticalAlign="end" tokens={Styled.footer}>
                                <Stack.Item>
                                    <DefaultButton text="Cancel" title="Close user preferences" onClick={onBackButtonClicked} />
                                </Stack.Item>
                                <Stack.Item>
                                    <PrimaryButton text="Save" title="Save user preferences" onClick={onSaveButtonClicked} />
                                </Stack.Item>
                            </Stack>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className={Styled.PanelStyles.root}>
            {getUserPreferencePanelContent()}
        </div>
    );
}

const connected = withContext(UserPreferences);
export { connected as UserPreferences };
