import { DefaultButton, PrimaryButton } from '@fluentui/react';
import * as React from 'react';

const BasicButton = ({
    text = '',
    title = '',
    onClick = () => {},
    primary = false,
    componentRef = null,
    disabled = false,
    className = '',
    styles = {},
}) => {
    return primary ? (
        <PrimaryButton
            text={text}
            title={title}
            onClick={onClick}
            componentRef={componentRef}
            disabled={disabled}
            className={className}
            styles={styles}
        />
    ) : (
        <DefaultButton
            text={text}
            title={title}
            onClick={onClick}
            componentRef={componentRef}
            disabled={disabled}
            className={className}
            styles={styles}
        />
    );
};

export default BasicButton;
