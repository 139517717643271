import {
    DetailsActionType,
    IRequestDetailsAction,
    IReceiveDetailsAction,
    IFailedDetailsAction,
    IUpdateDetailsRequest,
    IRequestDocumentPreviewAction,
    IReceiveDocumentPreview,
    IToggleDetailPanelWidth,
    IExpenseSubmissionRequest,
    ISubmitExpenseDetails,
    ISubmitExpenseDetailsSuccess,
    ISubmitExpenseDetailsFailed,
    IUpdateActionType,
    IRequestStatFormExpenseDetails,
    IResponseStatFormExpenseDetails,
    IFailedStatFormExpenseDetails,
    IClearDocumentPreview,
    ICloseDocumentPreview,
    IReceiveUserImageAction,
    IFailedUserImageAction,
    IRequestDocumentStart,
    IRequestDocumentEnd,
    IRequestDocumentAction,
    IFailedDocumentDownloadAction,
    IFailedDocumentPreviewAction,
    IRequestAllDocumentsAction,
    IRequestAllDocumentsEnd,
    IRequestAllDocumentsStart,
    IFailedAllDocumentDownload
} from './Details.action-types';
import { IAttachment } from './Details.types';

export function toggleDetailPanelWidth(): IToggleDetailPanelWidth {
    return {
        type: DetailsActionType.TOGGLE_DETAIL_PANEL_WIDTH,
    };
}

export function requestDocumentPreview(attachmentId: string, isModal: boolean): IRequestDocumentPreviewAction {
    return {
        type: DetailsActionType.REQUEST_DOCUMENT_PREVIEW,
        attachmentId,
        isModal,
    };
}

export function receiveDocumentPreview(filePreview: string): IReceiveDocumentPreview {
    return {
        type: DetailsActionType.RECEIVE_DOCUMENT_PREVIEW,
        filePreview,
    };
}

export function requestMyDetails(expenseReportId: string, expenseStatus: string): IRequestDetailsAction {
    return {
        type: DetailsActionType.REQUEST_MY_DETAILS,
        expenseReportId,
        expenseStatus,
    };
}

export function failedDocumentPreview(documentPreviewErrorMessage: string): IFailedDocumentPreviewAction {
    return {
        type: DetailsActionType.FAILED_DOCUMENT_PREVIEW,
        documentPreviewErrorMessage,
    };
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function receiveMyExpenseDetails(adaptiveDetails: any): IReceiveDetailsAction {
    return {
        type: DetailsActionType.RECEIVE_MY_DETAILS,
        adaptiveDetails,
    };
}

export function failedDetails(errorMessage: string): IFailedDetailsAction {
    return {
        type: DetailsActionType.FAILED_DETAILS,
        errorMessage,
    };
}
export function updateMyRequest(
    expenseReportId: string,
    actionType: string,
    expenseStatus: string
): IUpdateDetailsRequest {
    return {
        type: DetailsActionType.UPDATE_REQUEST,
        expenseReportId,
        actionType,
        expenseStatus,
    };
}

export function updateActionType(actionType: string): IUpdateActionType {
    return {
        type: DetailsActionType.UPDATE_ACTION_TYPE,
        actionType,
    };
}

export function clearDocumentPreview(): IClearDocumentPreview {
    return {
        type: DetailsActionType.CLEAR_DOCUMENT_PREVIEW,
    };
}

export function closeDocumentPreview(): ICloseDocumentPreview {
    return {
        type: DetailsActionType.CLOSE_DOCUMENT_PREVIEW,
    };
}

export function submitExpenseDetails(
    expenseDetails: IExpenseSubmissionRequest,
    isDeleteRequest?: boolean
): ISubmitExpenseDetails {
    return {
        type: DetailsActionType.SUBMIT_EXPENSE_DETAILS,
        expenseDetails,
        isDeleteRequest,
    };
}

export function submitExpenseDetailsSuccess(message: string): ISubmitExpenseDetailsSuccess {
    return {
        type: DetailsActionType.SUBMIT_EXPENSE_DETAILS_SUCCESS,
        submitSuccessMessage: message,
    };
}

export function submitExpenseDetailsFailed(message: string): ISubmitExpenseDetailsFailed {
    return {
        type: DetailsActionType.SUBMIT_EXPENSE_DETAILS_FAILED,
        postActionErrorMessage: message,
    };
}

export function requestStatFormExpenseDetails(
    expenseReportId: string,
    selectedCountry: string
): IRequestStatFormExpenseDetails {
    return {
        type: DetailsActionType.STAT_FORM_REQUEST_EXPENSE_DETAILS,
        expenseReportId,
        selectedCountry,
    };
}

export function responseStatFormExpenseDetails(data: any): IResponseStatFormExpenseDetails {
    return {
        type: DetailsActionType.STAT_FORM_RESPONSE_EXPENSE_DETAILS,
        data,
    };
}

export function failedStatFormExpenseDetails(message: any): IFailedStatFormExpenseDetails {
    return {
        type: DetailsActionType.STAT_FORM_FAILED_EXPENSE_DETAILS,
        message,
    };
}

export function receiveUserImage(userImage: any): IReceiveUserImageAction {
    return {
        type: DetailsActionType.RECEIVE_USER_IMAGE,
        userImage,
    };
}

export function failedUserImage(userImageErrorMessage: string): IFailedUserImageAction {
    return {
        type: DetailsActionType.FAILED_USER_IMAGE,
        userImageErrorMessage,
    };
}

export function requestDocumentStart(docName: string): IRequestDocumentStart {
    return { type: DetailsActionType.REQUEST_DOCUMENT_START, docName };
}

export function requestDocumentEnd(): IRequestDocumentEnd {
    return { type: DetailsActionType.REQUEST_DOCUMENT_END };
}

export function requestDocument(
    actionId: string,
    attachmentName: string,
    attachmentId?: string
): IRequestDocumentAction {
    return {
        type: DetailsActionType.REQUEST_DOCUMENT,
        actionId,
        attachmentName,
        attachmentId,
    };
}

export function failedDocumentDownload(documentDownloadErrorMessage: string): IFailedDocumentDownloadAction {
    return {
        type: DetailsActionType.FAILED_DOCUMENT_DOWNLOAD,
        documentDownloadErrorMessage,
    };
}

export function requestAllDocumentsStart(): IRequestAllDocumentsStart {
    return { type: DetailsActionType.REQUEST_ALL_DOCUMENTS_START };
}

export function requestAllDocumentsEnd(): IRequestAllDocumentsEnd {
    return { type: DetailsActionType.REQUEST_ALL_DOCUMENTS_END };
}

export function requestAllDocuments(
    actionId: string,
    attachmentArray: IAttachment[]
): IRequestAllDocumentsAction {
    return {
        type: DetailsActionType.REQUEST_ALL_DOCUMENTS,
        actionId,
        attachmentArray
    };
}

export function failedAllDocumentDownload(documentDownloadErrorMessage: string): IFailedAllDocumentDownload {
    return {
        type: DetailsActionType.FAILED_ALL_DOCUMENT_DOWNLOAD,
        documentDownloadErrorMessage,
    };
}
