import * as React from 'react';
import * as Styled from './TrainingPagesStyles';
import { IFAQList } from './TrainingPage';
import { FontIcon, Stack, NeutralColors, Link } from '@fluentui/react';
import { Collapsible } from '@m365-admin/collapsible';

const VIDEO_HEIGHT = 360;
const VIDEO_WIDTH = 640;

const showIcon = (item: IFAQList) => {
    if (!item.videoUrl) {
        return <FontIcon iconName="PDF" style={{ paddingTop: '4px', fontSize: '16px' }} />;
    } else if (!item.documentUrl) {
        return (
            <>
                <FontIcon iconName="Video" style={{ paddingTop: '4px', fontSize: '16px' }} />
            </>
        );
    } else {
        return (
            <>
                <FontIcon iconName="PDF" style={{ paddingTop: '4px', fontSize: '16px' }} />
                <FontIcon iconName="Video" style={{ paddingTop: '4px', fontSize: '16px' }} />
            </>
        );
    }
};

export function TrainingSection(props: {
    content: { heading: string; subtopics: IFAQList[]; id: string };
}): React.ReactElement {
    const { content } = props;

    const [expandedState, setExpandedState] = React.useState(new Array(content?.subtopics?.length).fill(false));

    const renderQRGList = (FAQList: IFAQList[]): JSX.Element[] => {
        return FAQList.map((item: IFAQList, index: number) => (
            <Stack.Item styles={{ root: { marginBottom: '5px', width: '90%', paddingLeft: '10px' } }} key={index}>
                <Collapsible
                    defaultIsExpanded={item.isExpanded}
                    title={`${item.documentUrl ? 'Reference guide' : ''}${
                        item.videoUrl ? (item.documentUrl ? ' and video' : 'Video') : ''
                    } for ${item.title}`}
                    renderHeaderAs="div"
                    onToggle={(isExpanded: boolean) => {
                        const stateCopy = [...expandedState];
                        stateCopy[index] = isExpanded;
                        setExpandedState(stateCopy);
                    }}
                    styles={{
                        root: {
                            flexGrow: 0,
                            width: '100%',
                            borderBottom: `1px solid ${NeutralColors.gray50}`,
                            borderTop: 'none',
                        },
                        headerButton: { paddingBottom: '12px' },
                        content: { paddingBottom: '8px' },
                    }}
                    onRenderTitle={() => {
                        return (
                            <Stack horizontal tokens={{ childrenGap: 5 }}>
                                {showIcon(item)}
                                <h3 style={{ fontWeight: 500, fontSize: '14px' }}>{item.title}</h3>
                            </Stack>
                        );
                    }}
                    ariaLabel={`${expandedState[index] ? 'Collapse section with' : 'Expand section to view'} ${
                        item.documentUrl ? 'reference guide' : ''
                    }${item.videoUrl ? (item.documentUrl ? ' and video' : 'video') : ''} for ${item.title}`}
                >
                    <Stack tokens={{ childrenGap: 10 }}>
                        {item.text && <p style={{ textAlign: 'justify' }}>{item.text}</p>}
                        {item.documentUrl && (
                            <Link href={item.documentUrl} target="_blank">
                                {'View Reference Guide for this topic'}
                            </Link>
                        )}
                        {item.videoUrl && (
                            <div style={{ height: VIDEO_HEIGHT }}>
                                <iframe
                                    width={VIDEO_WIDTH}
                                    height={VIDEO_HEIGHT}
                                    src={item.videoUrl}
                                    allowFullScreen={item.fullScreen}
                                    title={item.title}
                                    style={{
                                        border: 'none',
                                    }}
                                    sandbox
                                ></iframe>
                            </div>
                        )}
                    </Stack>
                </Collapsible>
            </Stack.Item>
        ));
    };

    return (
        <Stack>
            <Stack.Item>
                <Styled.HeadingTitle id={content.id}>{content.heading}</Styled.HeadingTitle>
            </Stack.Item>
            <Stack.Item>{renderQRGList(content.subtopics)}</Stack.Item>
        </Stack>
    );
}
