import * as React from 'react';
import { Stack, IStackTokens } from '@fluentui/react/lib/Stack';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { detailsReducerName, detailsReducer, detailsInitialState } from './Details.reducer';
import { IAttachment, IDetailsAppState, IDetailsState } from './Details.types';
import { detailsSagas } from './Details.sagas';
import { Dispatch } from 'redux';
import { stockImage } from '../../Helpers/stockImage';
import {
    clearDocumentPreview,
    closeDocumentPreview,
    requestDocumentPreview,
    requestMyDetails,
    submitExpenseDetails,
    submitExpenseDetailsFailed,
    updateMyRequest,
    requestDocument,
    requestDocumentStart,
    requestAllDocuments,
    requestAllDocumentsStart,
} from './Details.actions';
import { IReduxContext } from '@micro-frontend-react/redux/lib/IReduxContext';
import { connect } from 'react-redux';
import { Adaptive } from '../../Helpers/AdaptiveCardHelpers/Adaptive';
import SubmittedDetailsAdaptiveCardConfig from './SubmittedDetailsAdaptiveCardConfig';
import DraftDetailsAdaptiveCardConfig from './DraftDetailsAdaptiveCardConfig';
import * as Styled from '../../Shared/Styles/SharedLayout.styled';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { clearReportURL, isMobileResolution, mapExpenseStatusIcon } from '../../Helpers/sharedHelpers';
import DocumentPreview from './DocumentPreview';
import { updatePanelState, updateSubmittedReports } from '../../Shared/Store/Expense.actions';
import { CloseButton } from '../../Shared/Components/CloseButton';
import { MaximizeButton } from './DetailsButton/MaximizeButton';
import { BackButton } from './DetailsButton/BackButton';
import { ApprovedIcon, DraftIcon, InReviewIcon, PendingIcon } from '../../Static/Icons';
import { IExpenseSubmissionRequest } from './Details.action-types';
import ErrorView from '../../Shared/Components/MessageBars/ErrorView';
import SuccessView from '../../Shared/Components/MessageBars/SuccessView';
import { trackBusinessProcessEvent, trackException, TrackingEventId } from '../../Helpers/telemetryHelpers';
import { getStateCommonTelemetryProperties } from '../../Shared/Store/Expense.selectors';
import Modal from '@fluentui/react/lib/Modal';
import { ContextualMenu, IconButton } from '@fluentui/react';

type IDetailsAdaptiveState = {
    actionCompleted: boolean;
    code: string;
    reasonCode: string;
    reasonText: string;
    displayNotesInput: boolean;
    justificationsList: null | object[];
    comments: string;
    isCommentMandatory: boolean;
    notesErrorMessage: string;
    charLimit: number | null;
    attachmentsArray: object[] | null;
    selectedAttachmentID: string | null;
    selectedAttachmentName: string | null;
    hideHeaderActionBar: boolean;
    isModalExpanded: boolean;
};

interface IDetailsAdaptiveDispatch {
    dispatchRequestMyDetails(expenseReportId: string, expenseStatus: string): void;
    dispatchUpdateMyRequest(expenseReportId: string, expenseStatus: string): void;
    dispatchRequestDocument(actionId: string, attachmentName: string, attachmentId?: string): void;
    dispatchRequestAllDocuments(actionId: string, attachmentArray: IAttachment[]): void;
    dispatchRequestDocumentPreview(attachmentId: string, isModal: boolean): void;
    dispatchClearDocumentPreview(): void;
    dispatchCloseDocumentPreview(): void;
    dispatchCloseDetailCard(): void;
    dispatchCancelAutomatedExpense(expenseDetails: IExpenseSubmissionRequest): void;
    dispatchSubmitExpenseDetails(expenseDetails: IExpenseSubmissionRequest): void;
    dispatchClearSubmitExpenseDetailsFailed(): void;
    dispatchUpdateSubmittedReports(expenseReportId: string): void;
}

interface IDetailsAdaptiveProps extends IDetailsState, IDetailsAdaptiveDispatch {
    componentContext: any;
    routerHistory: any;
    routerLocation: any;
}

class DetailsAdaptive extends React.Component<IDetailsAdaptiveProps, IDetailsAdaptiveState> {
    public static contextType: React.Context<IReduxContext> = Context;
    public context!: React.ContextType<typeof Context>;

    successTimeout?: any;
    constructor(props: IDetailsAdaptiveProps) {
        super(props);
        this.state = {
            actionCompleted: false,
            code: '',
            reasonCode: '',
            reasonText: '',
            displayNotesInput: false,
            justificationsList: null,
            comments: '',
            isCommentMandatory: false,
            notesErrorMessage: '',
            charLimit: null,
            attachmentsArray: null,
            selectedAttachmentID: null,
            hideHeaderActionBar: false,
            isModalExpanded: true,
            selectedAttachmentName: '',
        };
    }

    componentDidMount(): void {
        const { reducerRegistry, runSaga } = this.context;
        const { authClient, telemetryClient } = this.props.componentContext;

        if (!reducerRegistry.exists(detailsReducerName)) {
            reducerRegistry.registerDynamic(detailsReducerName, detailsReducer);
            runSaga(detailsSagas);
        }

        const { dispatchRequestMyDetails } = this.props;
        const { expenseReportId, expenseStatus, stateCommonProperties } = this.props;
        if (expenseReportId != '' && expenseReportId !== null) {
            dispatchRequestMyDetails(expenseReportId, expenseStatus);

            trackBusinessProcessEvent(
                authClient,
                telemetryClient,
                'Open Expense Details',
                'Expense-Web.GetDetails',
                TrackingEventId.ExpenseDetailsLoadInitiate,
                stateCommonProperties
            );
        }
    }

    private afterActionCompletion(): void {
        this.setState({
            actionCompleted: true,
        });

        //closes panel after 5 seconds automatically after a successful action and refreshes summary data
        if (!this.props.postActionHasError) {
            this.successTimeout = setTimeout(() => {
                this.setState({
                    actionCompleted: false,
                });
                this.props.dispatchCloseDetailCard();
                const { routerHistory, routerLocation } = this.props;
                clearReportURL(routerHistory, routerLocation);
            }, 5000);
        }
    }

    componentDidUpdate(prevProps: IDetailsAdaptiveProps): void {
        if (prevProps.isProcessingAction && !this.props.isProcessingAction) {
            this.afterActionCompletion();
        }
        if (this.props.expenseReportId != '' && prevProps.expenseReportId != this.props.expenseReportId) {
            this.setState({
                actionCompleted: false,
            });
        }
        if (prevProps.isProcessingAction && !this.props.isProcessingAction && !this.props.postActionHasError) {
            if (this.props.expenseReportId) {
                this.props.dispatchUpdateSubmittedReports(this.props.expenseReportId);
            }
        }
        if (
            prevProps.expenseReportId === null &&
            prevProps.expenseStatus === null &&
            this.props.expenseStatus &&
            this.props.expenseReportId &&
            this.props.routerLocation &&
            this.props.routerLocation?.pathname?.split('/')?.length > 2
        ) {
            this.props.dispatchRequestMyDetails(this.props.expenseReportId, this.props.expenseStatus);
        }
    }

    componentWillUnmount(): void {
        const { dispatchUpdateMyRequest, dispatchClearDocumentPreview, dispatchCloseDocumentPreview } = this.props;
        dispatchUpdateMyRequest('', this.props.expenseStatus);

        dispatchClearDocumentPreview();
        dispatchCloseDocumentPreview();
    }

    handleAttachmentSelect = (e: object, selection: IDropdownOption): void => {
        const { dispatchRequestDocumentPreview, windowWidth } = this.props;
        const selectedID = selection.key as string;
        const selectedName = selection.text as string;
        this.setState({
            selectedAttachmentID: selectedID,
            selectedAttachmentName: selectedName,
        });
        const isModal = !isMobileResolution(windowWidth);
        if (selectedID) {
            dispatchRequestDocumentPreview(selectedID, isModal);
        }
    };

    handleDownload = (): void => {
        const { selectedAttachmentID, selectedAttachmentName } = this.state;
        this.downloadSingleAttachment(selectedAttachmentName, selectedAttachmentID);
    };

    downloadAllAttachments = (attachments: any) => {
        const { dispatchRequestAllDocuments, stateCommonProperties } = this.props;
        const { authClient, telemetryClient } = this.props.componentContext;
        dispatchRequestAllDocuments('download', attachments);

        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Download all attachments',
            'Expense-Web.DownloadAllAttachment',
            TrackingEventId.DownloadAllAttachments,
            stateCommonProperties
        );
    };

    downloadSingleAttachment = (attachmentName: string, attachmentID: string) => {
        const { dispatchRequestDocument, stateCommonProperties } = this.props;
        const { authClient, telemetryClient } = this.props.componentContext;

        this.setState({
            selectedAttachmentID: attachmentID,
            selectedAttachmentName: attachmentName,
        });

        dispatchRequestDocument('download', attachmentName, attachmentID);

        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Download attachment',
            'Expense-Web.DownloadAttachment',
            TrackingEventId.DownloadAttachment,
            stateCommonProperties
        );
    };

    renderFilePreview = (
        isModal?: boolean,
        modalWidth?: number,
        modalHeight?: number,
        isModalExpanded?: boolean
    ): JSX.Element => {
        const { documentPreview, panelWidth, documentPreviewHasError, documentPreviewErrorMessage } = this.props;
        const { attachmentsArray, selectedAttachmentID } = this.state;

        const attachmentOptions: IDropdownOption[] = attachmentsArray.map((attachment: object) => {
            return { key: attachment.id, text: attachment.name };
        });

        return (
            <Stack tokens={Styled.DetailsFilePreviewStackTokens}>
                <Stack.Item>
                    <DocumentPreview
                        documentPreview={documentPreview}
                        documentPreviewHasError={documentPreviewHasError}
                        dropdownOnChange={this.handleAttachmentSelect}
                        dropdownSelectedKey={selectedAttachmentID}
                        dropdownOptions={attachmentOptions}
                        previewContainerInitialWidth={isModal ? modalWidth : panelWidth} //provide height minus footer height
                        footerHeight={this.props.footerHeight}
                        handleDownloadClick={this.handleDownload}
                        isModal={isModal}
                        isModalExpanded={isModalExpanded}
                        previewContainerInitialHeight={isModal ? modalHeight : null}
                    />
                </Stack.Item>
                {documentPreviewHasError && documentPreviewErrorMessage && (
                    <Stack.Item>
                        <ErrorView errorMessage="No content available" failureType={'Document preview'} />
                    </Stack.Item>
                )}
            </Stack>
        );
    };

    prepareSubmitOrCancelExpenseDetails = (data: any) => {
        const { dispatchClearSubmitExpenseDetailsFailed, adaptiveJSON } = this.props;

        dispatchClearSubmitExpenseDetailsFailed();

        const lineCount = adaptiveJSON.lines.length;
        var linesSubmitRequest: any = [];
        for (var i = 0; i < lineCount; i++) {
            linesSubmitRequest.push({
                costCenterNumber: data[`costCenterNumber${i}`],
                internalOrderNumber: data[`internalOrderNumber${i}`],
                isPersonalTransaction: data[`isPersonalTransaction${i}`],
                lineItemId: adaptiveJSON.lines[i].lineItemId,
            });
        }

        const submitRequest: IExpenseSubmissionRequest = {
            id: adaptiveJSON.id,
            header: {
                submitterComments: data.submitterComments,
                reportName: data.reportNameEntered,
                description: adaptiveJSON.header.description,
                interimApprovers: data.interimApproversAliases,
                isAntiCorruption: adaptiveJSON.header.isAntiCorruption,
                preApprovalNumber: adaptiveJSON.header.preApprovalNumber,
            },
            lines: linesSubmitRequest,
        };

        return submitRequest;
    };

    cancelAutomationDraftExpense = (expenseData: any) => {
        const { dispatchCancelAutomatedExpense, stateCommonProperties } = this.props;
        const { authClient, telemetryClient } = this.props.componentContext;

        const cancelRequest = this.prepareSubmitOrCancelExpenseDetails(expenseData);
        dispatchCancelAutomatedExpense(cancelRequest);
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Cancel Automated Expense',
            'Expense-Web.CancelAutomatedExpense',
            TrackingEventId.CancelAutomatedExpense,
            stateCommonProperties
        );
    };

    submitDraftExpense = (expenseData: any) => {
        const { dispatchSubmitExpenseDetails, stateCommonProperties } = this.props;
        const { authClient, telemetryClient } = this.props.componentContext;

        const submitRequest = this.prepareSubmitOrCancelExpenseDetails(expenseData);
        dispatchSubmitExpenseDetails(submitRequest);
        trackBusinessProcessEvent(
            authClient,
            telemetryClient,
            'Submit Draft',
            'Expense-Web.ExpenseDraftSubmitInitiate',
            TrackingEventId.ExpenseDraftSubmitInitiate,
            stateCommonProperties
        );
    };

    closeApiErrorMessage = () => {
        this.props.dispatchClearSubmitExpenseDetailsFailed();
    };

    handleAdaptiveCardSubmitAction = (id: string, data: any): void => {
        const { dispatchRequestDocumentPreview, stateCommonProperties, windowWidth } = this.props;
        const { authClient, telemetryClient } = this.props.componentContext;
        if (id == 'submitAction') {
            this.submitDraftExpense(data);
        } else if (id == 'cancelAutomationAction') {
            this.cancelAutomationDraftExpense(data);
        }
        if (id && id.includes('preview')) {
            const attachmentID = data[0].id;
            const attachmentName = data[0].name;
            this.setState({
                attachmentsArray: data,
                selectedAttachmentID: attachmentID,
                selectedAttachmentName: attachmentName,
            });
            const isModal = !isMobileResolution(windowWidth);
            dispatchRequestDocumentPreview(attachmentID, isModal);
            trackBusinessProcessEvent(
                authClient,
                telemetryClient,
                'View file preview',
                'Expense-Web.GetFilePreview',
                TrackingEventId.PreviewAttachments,
                stateCommonProperties
            );
        } else if (id && id === 'downloadAllAttachments') {
            this.downloadAllAttachments(data);
        } else if (id && id === 'downloadAnAttachment') {
            this.downloadSingleAttachment(data[0].name, data[0].id);
        }
    };

    detailCardHeaderActionBar = () => {
        return (
            <Stack.Item
                styles={{
                    ...Styled.docPreviewHeaderBarStyles,
                    root: {
                        ...(Styled.docPreviewHeaderBarStyles.root as any),
                        ...(Styled.StickyDetailsHeder.root as any),
                    },
                }}
            >
                {this.props.isPreviewOpen && <BackButton></BackButton>}
                {!this.props.isPreviewOpen && <div></div>}
                <Stack style={{ flexFlow: 'row' }}>
                    {this.props.isDocumentDownloading && this.props.adaptiveJSON && (
                        <Stack.Item>
                            <Spinner
                                label={`Downloading ${this.state.selectedAttachmentName}`}
                                labelPosition="left"
                                style={{ margin: '5px' }}
                            />
                        </Stack.Item>
                    )}
                    {this.props.isAllDocumentsDownloading && this.props.adaptiveJSON && (
                        <Stack.Item>
                            <Spinner
                                label={`Downloading all documents`}
                                labelPosition="left"
                                style={{ margin: '5px' }}
                            />
                        </Stack.Item>
                    )}
                    {this.props.documentDownloadHasError &&
                        this.props.documentDownloadErrorMessage &&
                        this.props.adaptiveJSON && (
                            <Stack.Item styles={Styled.HeaderActionBarMessageStyle}>
                                <Styled.ErrorText>Document download failed.</Styled.ErrorText>
                            </Stack.Item>
                        )}
                    <Stack.Item>
                        <MaximizeButton />
                    </Stack.Item>
                    <Stack.Item>
                        <CloseButton />
                    </Stack.Item>
                </Stack>
            </Stack.Item>
        );
    };

    replaceUserImage(template: any): void {
        let jsonString = JSON.stringify(template);

        if (!this.props.userImageHasError && this.props.userImage) {
            // replace with user image
            jsonString = jsonString.replace('#UserImage#', `data:image/png;base64,${this.props.userImage}`);
        }
        // image wasn't found
        else {
            // replace with stock image
            jsonString = jsonString.replace('#UserImage#', `data:image/png;base64,${stockImage}`);
        }

        return JSON.parse(jsonString);
    }

    handleActionResponse = (): JSX.Element => {
        const { postActionHasError, postActionErrorMessage, componentContext, stateCommonProperties } = this.props;
        const { telemetryClient, authClient } = componentContext;
        if (postActionHasError) {
            let errorMessage;
            try {
                const errorResponseObject = JSON.parse(postActionErrorMessage);
                errorMessage = errorResponseObject.ErrorMessage ?? postActionErrorMessage;
            } catch (ex) {
                errorMessage = postActionErrorMessage;
                const exception = ex.message ? new Error(ex.message) : ex;
                trackException(
                    authClient,
                    telemetryClient,
                    'Parse action error response - Failure',
                    'MSExpense.ParseActionErrorResponse.Failure',
                    TrackingEventId.ParseActionErrorResponseFailure,
                    stateCommonProperties,
                    exception
                );
            }
            return <ErrorView errorMessage={errorMessage} failureType={'Submit'} />;
        } else {
            return <SuccessView />;
        }
    };

    public render(): React.ReactElement {
        const stackTokens: IStackTokens = { childrenGap: 24 };

        const {
            isLoadingDetails,
            adaptiveJSON,
            hasError,
            isPreviewOpen,
            isLoadingPreview,
            errorMessage,
            isLoadingUserImage,
            expenseStatus,
            isProcessingAction,
            postActionHasError,
            isModalPreviewOpen,
            windowWidth,
            windowHeight,
        } = this.props;
        const { isModalExpanded } = this.state;

        const modalDimensions = Styled.getModalDimensions(isModalExpanded, windowWidth, windowHeight);
        if (adaptiveJSON) {
            adaptiveJSON.header.statusImg = mapExpenseStatusIcon(adaptiveJSON.header.approvalStatus);

            if (!adaptiveJSON.header.dateSubmitted) {
                adaptiveJSON.header.dateSubmitted = adaptiveJSON.header.dateCreated;
            }

            if (!adaptiveJSON.header.approvalStatus) {
                adaptiveJSON.header.approvalStatus = 'Draft';
            }

            if (adaptiveJSON.approvers && adaptiveJSON.approvers.length > 0) {
                adaptiveJSON.hasInterimApprover = adaptiveJSON.approvers.some(
                    (app) => app.type && app.type.toLowerCase() == 'interim'
                );
                adaptiveJSON.hasFinalApprover = adaptiveJSON.approvers.some(
                    (app) => app.type && app.type.toLowerCase() == 'final'
                );

                const currentApproverAlias = adaptiveJSON.header.currentApprover
                    ? adaptiveJSON.header.currentApprover.alias
                    : null;

                const currentApprover = currentApproverAlias
                    ? adaptiveJSON.approvers.filter((a) => a.approver.alias == currentApproverAlias)
                    : null;
                const currAprSeq =
                    currentApprover && currentApprover.length > 0 ? currentApprover[0].sequenceNumber : null;

                let approvalStatus: any = '';
                const expenseStatus =
                    adaptiveJSON.header.expenseStatus && adaptiveJSON.header.expenseStatus != ''
                        ? adaptiveJSON.header.expenseStatus.toLowerCase()
                        : 'draft';

                adaptiveJSON.approvers = adaptiveJSON.approvers.map((apr) => {
                    if (expenseStatus.toLowerCase() == 'draft') {
                        adaptiveJSON.header.statusImg = DraftIcon;
                    } else if (currAprSeq == null) {
                        approvalStatus = 'approved';
                    } else if (apr.sequenceNumber < currAprSeq) {
                        approvalStatus = 'approved';
                    } else if (apr.sequenceNumber == currAprSeq) {
                        approvalStatus = 'inReview';
                    } else if (apr.sequenceNumber > currAprSeq) {
                        approvalStatus = 'pending';
                    }
                    return { ...apr, approvalStatus };
                });
            }

            // get rid of duplicate receipts in adaptive json
            if (adaptiveJSON.receipts && adaptiveJSON.receipts.length > 0) {
                const mymap = new Map();

                const unique = adaptiveJSON.receipts.filter((el) => {
                    const val = mymap.get(el.name);
                    if (val) {
                        if (el.id < val) {
                            mymap.delete(el.name);
                            mymap.set(el.name, el.id);
                            return true;
                        } else {
                            return false;
                        }
                    }
                    mymap.set(el.name, el.id);
                    return true;
                });
                adaptiveJSON.receipts = unique;
            }

            if (adaptiveJSON.lines && adaptiveJSON.lines.length > 0) {
                adaptiveJSON.lines = adaptiveJSON.lines.map((l) => {
                    if (adaptiveJSON.receipts && adaptiveJSON.receipts.length > 0) {
                        if (!l.receiptIds || l.receiptIds.length == 0) {
                            return { ...l, receipts: adaptiveJSON.receipts };
                        } else if (l.receiptIds && l.receiptIds.length > 0) {
                            const receipts = adaptiveJSON.receipts.filter((r) => l.receiptIds.some((a) => a == r.id));
                            return { ...l, receipts };
                        }
                    }

                    return { ...l };
                });
            }
        }

        const adaptiveCardConfig =
            expenseStatus == 'Draft' ? DraftDetailsAdaptiveCardConfig : SubmittedDetailsAdaptiveCardConfig;

        return (
            <div>
                <Modal
                    titleAriaId={'documentPreviewModal'}
                    isOpen={isModalPreviewOpen}
                    isBlocking={false}
                    dragOptions={{ moveMenuItemText: 'Move', closeMenuItemText: 'Close', menu: ContextualMenu }}
                    onDismiss={(): void => {
                        this.props.dispatchCloseDocumentPreview();
                    }}
                    styles={{ main: { minWidth: modalDimensions.width, minHeight: modalDimensions.height } }}
                >
                    <Stack>
                        <Stack.Item align={'end'}>
                            <Stack horizontal>
                                <IconButton
                                    iconProps={
                                        isModalExpanded ? { iconName: 'BackToWindow' } : { iconName: 'FullScreen' }
                                    }
                                    ariaLabel={isModalExpanded ? 'Restore modal size' : 'Maximize modal'}
                                    title={isModalExpanded ? 'Resize' : 'Maximize'}
                                    onClick={(): void => {
                                        this.setState({ isModalExpanded: !isModalExpanded });
                                    }}
                                    styles={{ icon: { fontSize: 18 } }}
                                />
                                <IconButton
                                    iconProps={{ iconName: 'Cancel' }}
                                    ariaLabel="Close preview modal"
                                    title="Close"
                                    onClick={(): void => {
                                        this.props.dispatchCloseDocumentPreview();
                                    }}
                                    styles={{ icon: { fontSize: 18 } }}
                                />
                            </Stack>
                        </Stack.Item>
                        {isModalPreviewOpen && isLoadingPreview && (
                            <Stack.Item verticalFill={true}>
                                <Spinner label="Loading preview..." />
                            </Stack.Item>
                        )}
                        {isModalPreviewOpen &&
                            !isLoadingPreview &&
                            this.renderFilePreview(
                                true,
                                modalDimensions.width,
                                modalDimensions.height,
                                isModalExpanded
                            )}
                    </Stack>
                </Modal>
                <Stack tokens={stackTokens}>
                    {hasError && errorMessage && (
                        <Stack.Item styles={Styled.loadingSpinnerStyles}>
                            <ErrorView errorMessage={errorMessage} failureType="Loading details"></ErrorView>
                        </Stack.Item>
                    )}
                    {!hasError && isLoadingDetails && (
                        <Stack.Item styles={Styled.loadingSpinnerStyles}>
                            <div>
                                <Spinner label="Loading Expense details..." />
                            </div>
                        </Stack.Item>
                    )}
                    {!this.props.hideHeaderActionBar && !isProcessingAction && (
                        <Stack.Item>{this.detailCardHeaderActionBar()}</Stack.Item>
                    )}
                    {this.state.actionCompleted && !isProcessingAction && !isLoadingDetails && (
                        <Stack.Item styles={Styled.messageBarStyles}>{this.handleActionResponse()}</Stack.Item>
                    )}
                    {!isPreviewOpen &&
                        !hasError &&
                        !isLoadingDetails &&
                        !isLoadingUserImage &&
                        adaptiveJSON &&
                        !isProcessingAction &&
                        (postActionHasError || !this.state.actionCompleted) && (
                            <>
                                {
                                    <>
                                        <Stack.Item>
                                            <Adaptive
                                                template={this.replaceUserImage(adaptiveCardConfig)}
                                                dataPayload={adaptiveJSON}
                                                style={Styled.DetailsAdaptiveCard}
                                                onOpenURLActionExecuted={''}
                                                onSubmitActionExecuted={this.handleAdaptiveCardSubmitAction}
                                            />
                                        </Stack.Item>
                                    </>
                                }
                            </>
                        )}

                    {isPreviewOpen && isLoadingPreview && (
                        <Stack.Item styles={Styled.loadingSpinnerStyles}>
                            <div>
                                <Spinner label="Loading preview..." />
                            </div>
                        </Stack.Item>
                    )}

                    {!isLoadingDetails && isProcessingAction && !this.state.actionCompleted && (
                        <Stack.Item styles={Styled.loadingSpinnerStyles}>
                            <div>
                                <Spinner label="Processing..." />
                            </div>
                        </Stack.Item>
                    )}
                    {isPreviewOpen && !isLoadingPreview && <Stack.Item> {this.renderFilePreview()}</Stack.Item>}
                    <Styled.Space></Styled.Space>
                </Stack>
            </div>
        );
    }
}

const mapStateToProps = (state: IDetailsAppState): IDetailsState => {
    if (state.dynamic) {
        const dynamicState = JSON.parse(JSON.stringify(state.dynamic));
        const updatedState = dynamicState?.[detailsReducerName] || detailsInitialState;
        const stateCommonProperties = getStateCommonTelemetryProperties(state);
        updatedState.stateCommonProperties = stateCommonProperties;
        return updatedState;
    } else {
        return detailsInitialState;
    }
};

const mapDispatchToProps = (dispatch: Dispatch): IDetailsAdaptiveDispatch => ({
    dispatchRequestMyDetails: (expenseReportId: string, expenseStatus: string): void => {
        dispatch(requestMyDetails(expenseReportId, expenseStatus));
    },
    dispatchUpdateMyRequest: (expenseReportId: string, expenseStatus: string): void => {
        dispatch(updateMyRequest(expenseReportId, null, expenseStatus));
    },
    dispatchRequestDocumentPreview: (attachmentId: string, isModal: boolean): void => {
        dispatch(requestDocumentPreview(attachmentId, isModal));
    },
    dispatchRequestDocument: (actionId: string, attachmentName: string, attachmentId?: string): void => {
        dispatch(requestDocumentStart(attachmentName.split(' ')[1] ?? attachmentName));
        dispatch(requestDocument(actionId, attachmentName, attachmentId));
    },
    dispatchRequestAllDocuments: (actionId: string, attachmentArray: IAttachment[]): void => {
        dispatch(requestAllDocumentsStart());
        dispatch(requestAllDocuments(actionId, attachmentArray));
    },
    dispatchClearDocumentPreview: (): void => {
        dispatch(clearDocumentPreview());
    },
    dispatchCloseDocumentPreview: (): void => {
        dispatch(closeDocumentPreview());
    },
    dispatchCloseDetailCard: (): void => {
        dispatch(updatePanelState(false));
    },
    dispatchCancelAutomatedExpense: (expenseDetails: IExpenseSubmissionRequest): void => {
        dispatch(submitExpenseDetails(expenseDetails, true));
    },
    dispatchSubmitExpenseDetails: (expenseDetails: IExpenseSubmissionRequest): void => {
        dispatch(submitExpenseDetails(expenseDetails));
    },
    dispatchClearSubmitExpenseDetailsFailed: (): void => {
        dispatch(submitExpenseDetailsFailed(null));
    },
    dispatchUpdateSubmittedReports: (expenseReportId: string): void => {
        dispatch(updateSubmittedReports(expenseReportId));
    },
});

const connected = withContext(connect(mapStateToProps, mapDispatchToProps)(DetailsAdaptive));
export { connected as DetailsAdaptive };
