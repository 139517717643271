import * as React from 'react';

/**
 * Since the camera container resembles a licence card, the height must always be less than the
 * width (regardless of the resolution of the camera). This is achieved by calculating a ratio
 * that is always >= 1 by dividing by the largest dimension.
 **/
export function useCardRatio(initialParams: any) {
	const [aspectRatio, setAspectRatio] = React.useState(initialParams);

	const calculateRatio = React.useCallback((height, width) => {
		if (height && width) {
			const isLandscape = height <= width;
			const ratio = isLandscape ? width / height : height / width;

			setAspectRatio(ratio);
		}
	}, []);

	return [aspectRatio, calculateRatio];
}

/**
* In the event that the video (v) is larger than it's parent container (c), calculate offsets
* to center the container in the middle of the video.
**/
export function useOffsets(vWidth: number, vHeight: number, cWidth: number, cHeight: number) {
	const [offsets, setOffsets] = React.useState({ x: 0, y: 0 });
	React.useEffect(() => {
		setOffsets({x: 0, y: 0});
	}, [vWidth, vHeight, cWidth, cHeight]);
	
	return offsets;	
}


export function useUserMedia(requestedMedia: any) {
	const [mediaStream, setMediaStream] = React.useState(null);

	React.useEffect(() => {
		async function enableVideoStream() {
			try {
				const stream = await navigator.mediaDevices.getUserMedia(
					requestedMedia
				);
				setMediaStream(stream);
			} catch (err) {
				// Handle the error
			}
		}

		if (!mediaStream) {
			enableVideoStream();
		} else {
			return function cleanup() {
				mediaStream.getTracks().forEach(track => {
					track.stop();
				});
			};
		}
	}, [mediaStream, requestedMedia]);

	return mediaStream;
}
