import * as React from 'react';
import { Reducer } from 'redux';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseReducerName, expenseInitialState } from '../../../../Shared/Store/Expense.reducer';
import * as Styled from './CardHelpers/CardStyling.styled';
import * as SharedLayout from '../../../../Shared/Styles/SharedLayout.styled';
import * as CardHelpers from './CardHelpers/CardHelpers';
import { DefaultButton, Spinner } from '@fluentui/react';
import { IExpenseAppState } from '../../../../Shared/Store/Expense.types';
import { CountBanner } from './CardHelpers/CountBanner';
import { detailsReducer, detailsReducerName } from '../../../ExpenseDetails/Details.reducer';
import { detailsSagas } from '../../../ExpenseDetails/Details.sagas';
import {
    requestExpenseStatCounts,
    updateFilterValue,
    updateIsComingFromDashboard,
} from '../../../../Shared/Store/Expense.actions';
import { useHistory } from 'react-router-dom';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export function ProcessingExpensesCard(): React.ReactElement {
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(detailsReducerName, detailsReducer as Reducer, [detailsSagas]);
    const { isLoadingStats, expenseCounts } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    React.useEffect(() => {
        if (!expenseCounts && !isLoadingStats) {
            dispatch(requestExpenseStatCounts('Dashboard'));
        }
    }, [dispatch]);

    const history = useHistory();

    return (
        <Styled.SmallCard windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
            {CardHelpers.getHeader('Processing for Payment')}
            <SharedLayout.Divider />
            <Styled.CardBody windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
                {expenseCounts && !isLoadingStats && (
                    <CountBanner
                        countValue={expenseCounts.processingCount}
                        title="Expense reports processing"
                        imageUrl="/images/processing-expense-count.svg"
                    />
                )}
                {isLoadingStats && (
                    <SharedLayout.SpinnerContainer>
                        <Spinner label="Loading" />
                    </SharedLayout.SpinnerContainer>
                )}
                <Styled.CardFooter>
                    {expenseCounts && expenseCounts.processingCount > 0 && !isLoadingStats && (
                        <DefaultButton
                            title="View all processed reports"
                            text="View all processed reports"
                            onClick={(): void => {
                                dispatch(updateFilterValue('Processing Payment'));
                                dispatch(updateIsComingFromDashboard(true));
                                history.push('/ExpenseDashboard');
                            }}
                        />
                    )}
                </Styled.CardFooter>
            </Styled.CardBody>
        </Styled.SmallCard>
    );
}
