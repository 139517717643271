import * as React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import * as Styled from '../TrainingPagesStyles';
import { TrainingTopics } from './MyExpenseTrainingContent';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { updateShowSecondaryHeader } from '../../../../Shared/Store/Expense.actions';
import { TrainingSection } from '../TrainingSection';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export interface IFAQList {
    title: string;
    text: string;
    videoUrl?: string;
    documentUrl?: string;
    videoWidth: string;
    videoHeight: string;
    textAsHeader: any;
    isExpanded: boolean;
    fullScreen: boolean;
}

export function MyExpenseTraining(): React.ReactElement {
    const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        dispatch(updateShowSecondaryHeader(false));
    }, [dispatch]);

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }
        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderTopics = (): JSX.Element[] => {
        return TrainingTopics.map((item, index) => <TrainingSection content={item} key={index} />);
    };

    return (
        <Styled.FAQContainer windowHeight={dimensions.height} windowWidth={dimensions.width}>
            <Stack className="scroll-hidden v-scroll-auto custom-scrollbar">
                <Styled.FAQTitle>MyExpense Quick Reference Guides and Videos</Styled.FAQTitle>
                {renderTopics()}
            </Stack>
        </Styled.FAQContainer>
    );
}
