import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { Stack, ActionButton } from '@fluentui/react';
import * as React from 'react';
import { updateSettingsPageShowing, saveUserPreferencesSuccess } from '../../../Shared/Store/Expense.actions';
import { expenseReducerName, expenseReducer } from '../../../Shared/Store/Expense.reducer';
import * as SharedStyled from '../../../Shared/Styles/SharedLayout.styled';
import * as Styled from './SettingsPanel.styled';
import * as HeaderStyled from '../HeaderPanels.styled';
import { getSettingsPageShowing } from '../../../Shared/Store/Expense.selectors';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import * as Helpers from '../../../Helpers/sharedHelpers';
import { UserPreferences } from './UserPreferences/UserPreferences';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

function SettingsPanel(): React.ReactElement {
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas], false);

    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const settingsPageShowing = useSelector(getSettingsPageShowing);

    // TODO: on panel dismiss, need to reset the state to the main settings page

    React.useEffect(() => {
        return (): void => {
            //clearing error status and response messages
            dispatch(saveUserPreferencesSuccess(null));
            dispatch(updateSettingsPageShowing('Settings'));
        };
    }, []);

    // MAIN SETTINGS SCREEN
    const getMainPanelContent = () => {
        return (
            <div>
                <Styled.FirstGroupButtons>
                    <ActionButton iconProps={{ iconName: 'Settings' }} onClick={onGeneralButtonClicked}>
                        User Preferences
                    </ActionButton>
                </Styled.FirstGroupButtons>
                <SharedStyled.Divider />
                <Stack styles={HeaderStyled.ButtonsStackStyles}>
                    <ActionButton iconProps={{ iconName: 'Help' }} onClick={onGetHelpBtnClicked}>
                        Get help
                    </ActionButton>
                    <ActionButton
                        iconProps={{ iconName: 'Feedback' }}
                        href="https://eesideas.powerappsportals.com/d365community/forum/1714cd1e-9f22-ec11-b6e6-000d3a339bda"
                        target="_blank"
                    >
                        Give us your feedback
                    </ActionButton>
                </Stack>
            </div>
        );
    };

    const onGeneralButtonClicked = () => {
        dispatch(updateSettingsPageShowing('User Preferences'));
    };

    const onBackButtonClicked = () => {
        dispatch(updateSettingsPageShowing('Settings'));
        dispatch(saveUserPreferencesSuccess(null));
    };

    const onGetHelpBtnClicked = () => {
        Helpers.launchBot();
    };

    // OTHER SCREENS (this code is a temporary placeholder)
    const getOtherPanelContent = () => {
        return (
            <div>
                <ActionButton iconProps={{ iconName: 'NavigateBack' }} onClick={onBackButtonClicked}>
                    Go back
                </ActionButton>
            </div>
        );
    };

    return (
        <div className={HeaderStyled.PanelStyles.root}>
            {settingsPageShowing == 'Settings' && getMainPanelContent()}
            {settingsPageShowing == 'User Preferences' && <UserPreferences />}
            {settingsPageShowing != 'Settings' && settingsPageShowing != 'User Preferences' && getOtherPanelContent()}
        </div>
    );
}

const connected = withContext(SettingsPanel);
export { connected as SettingsPanel };
