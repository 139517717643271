import * as React from 'react';
import * as Styled from '../TrainingPagesStyles';
import { TrainingTopics } from './ConcurTrainingContent';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { updateShowSecondaryHeader } from '../../../../Shared/Store/Expense.actions';
import { TrainingSection } from '../TrainingSection';
import { Link, Stack } from '@fluentui/react';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export interface IFAQList {
    title: string;
    text: string;
    videoUrl?: string;
    documentUrl?: string;
    videoWidth: string;
    videoHeight: string;
    textAsHeader: any;
    isExpanded: boolean;
    fullScreen: boolean;
}

export function ConcurTraining(): React.ReactElement {
    const { dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [dimensions, setDimensions] = React.useState({
        height: window.innerHeight,
        width: window.innerWidth,
    });

    React.useEffect(() => {
        dispatch(updateShowSecondaryHeader(false));
    }, [dispatch]);

    React.useEffect(() => {
        function handleResize() {
            setDimensions({
                height: window.innerHeight,
                width: window.innerWidth,
            });
        }
        window.addEventListener('resize', handleResize);

        return (): void => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderTopics = (): JSX.Element[] => {
        return TrainingTopics.map((item, index) => <TrainingSection content={item} key={index} />);
    };

    const getContent = () => {
        return (
            <div className="container-fluid" id="concurTraining">
                <Stack
                    horizontal
                    horizontalAlign="space-evenly"
                    tokens={Styled.ConcurTextStackTokens}
                    styles={Styled.ConcurTextStackStyles}
                >
                    <Stack.Item styles={Styled.ConcurTextStackItemStyles}>
                        <Stack>
                            <Styled.ConcurContentHeader>
                                Make expense reporting simple fast and accurate.
                            </Styled.ConcurContentHeader>
                            <p>
                                Creating expense reports is simplified when charges from credit cards, select suppliers
                                and receipt photos pre‐populate in Concur Expense
                            </p>
                            <ul>
                                <li>&bull; Automatically categorize and map expense base on receipt images </li>
                                <li>&bull; Streamline expense management</li>
                                <li>&bull; Enforce policy compliance</li>
                                <li>&bull; Reimburse employees faster</li>
                            </ul>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={Styled.ConcurTextStackItemStyles}>
                        <Stack>
                            <Styled.ConcurContentHeader>
                                Concur Expense: Tell expense pain to hit the road.
                            </Styled.ConcurContentHeader>
                            <p>
                                Concur for Mobile complements our web based solution, allowing you to manage anything
                                expense and or travel related, wherever you are. From booking and managing itineraries
                                to capturing receipts and submitting expense reports, you can now do it all in one app
                                with Concur:
                            </p>
                            <ul>
                                <li> &bull; Capture receipts with your smartphone, then ditch the paper.</li>
                                <li> &bull; Upload IRS compliant images directly to expense reports.</li>
                                <li> &bull; Immediately approve or reject expense reports via manager access.</li>
                                <li> &bull; Add car mileage to an expense report.</li>
                            </ul>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item styles={Styled.ConcurTextStackItemStyles}>
                        <Stack tokens={Styled.ConcurLinkStackTokens}>
                            <Stack.Item>
                                <Stack>
                                    <Styled.ConcurContentHeader>Download Mobile Apps</Styled.ConcurContentHeader>
                                    <Link
                                        href="https://itunes.apple.com/us/app/sap-concur/id335023774?mt=8"
                                        target="_blank"
                                    >
                                        Apple Store
                                    </Link>
                                    <Link
                                        href="https://play.google.com/store/apps/details?id=com.concur.breeze"
                                        target="_blank"
                                    >
                                        Google Store
                                    </Link>
                                </Stack>
                            </Stack.Item>
                            <Stack.Item>
                                <Stack>
                                    <Styled.ConcurContentHeader>Login links</Styled.ConcurContentHeader>
                                    <Link href="https://aka.ms/Concur" target="_blank">
                                        FTE/V- Login
                                    </Link>
                                    <Link
                                        href="https://login.microsoftonline.com/a5f51bc5-4d47-4954-a546-bafe55e8db16/saml2?SAMLRequest=fZJLb8IwEIT%2fSuR7yIMYiEWQaDkUqQ8EtIdeqo2zFEuOTb0O0H%2ffBKjaqhLHlWa%2b8ex6TFDrnZg2fmuW%2bNEg%2bWBKhM4ra26toaZGt0K3VxKfl%2fcF23q%2fIxFFh8OhJ62RjSOrm05N7VxHq%2bnD%2fRIr5VB66y6jRLVH1wPaHlkwazOUgc7yg9P2XZleraSzZDfeGq0MnoDANzwpJQ%2bzKhuGWc6zEHg2CEvYIOc4qspkEHUtUhbMZwV7K2Uf%2bnE1kPlomAEkkCZ5Ivs5lOkok5C1MqIG54Y8GF%2bwNE7yMOZhOlynsYj7go9eWbBw1ltp9Y0ylTLvBWucERZIkTBQIwkvRVdOpL1YlGcRibv1ehEunlZrFrygo1PFVsCCY60NidOyr6N2l1w2GXdqcXqr%2b%2bW%2fbofv27HJ%2f0t1%2bxxHv7DnjJ14bDnz2cJqJT%2bDqdb2cOsQPBYsYUE0OXv%2b%2fpLJFw%3d%3d"
                                        target="_blank"
                                    >
                                        Subcontractor Login
                                    </Link>
                                </Stack>
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                </Stack>
            </div>
        );
    };

    return (
        <Styled.FAQContainer windowHeight={dimensions.height} windowWidth={dimensions.width}>
            <Stack className="scroll-hidden v-scroll-auto custom-scrollbar">
                <Styled.FAQTitle>Concur Quick Reference Guides and Videos</Styled.FAQTitle>
                {getContent()}
                {renderTopics()}
            </Stack>
        </Styled.FAQContainer>
    );
}
