import * as React from 'react';
//import { Persona } from '@fluentui/react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { getImageURLForAlias, getIsLoadingSubmitterImages, isAliasInSubmitters } from '../Store/Expense.selectors';
import { Persona } from '@micro-frontend-react/employee-experience/lib/Persona';
import { convertToUPN } from '../../Helpers/sharedHelpers';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

export const SubmitterPersona = (props: { emailAlias: string; size: number }): JSX.Element => {
    const { useSelector } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const emailAlias = convertToUPN(props.emailAlias);
    const isLoadingSubmitterImages = useSelector(getIsLoadingSubmitterImages);
    const savedURL = useSelector((state: any) => getImageURLForAlias(state, emailAlias));
    const isAliasInSubmittersState = useSelector((state: any) => isAliasInSubmitters(state, emailAlias));
    if (isLoadingSubmitterImages) {
        return React.createElement(Persona, Object.assign({}, props));
    } else {
        if (isAliasInSubmittersState) {
            return React.createElement(Persona, Object.assign({}, props, { imageUrl: savedURL }));
        } else {
            return <Persona emailAlias={props.emailAlias} size={props.size} />;
        }
    }
};
