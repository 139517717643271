import * as React from 'react';
import { CoherenceModal } from '@coherence-design-system/controls';
import { Stack } from '@fluentui/react/lib/Stack';
import { DefaultButton, PrimaryButton, TextField } from '@fluentui/react';
import { withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { Camera } from './Camera/Camera';
import * as Styled from './FilePictureUploadStyling.styled';

type IFilePictureUploadProps = {
    isOpen: boolean;
    onDismiss: () => void;
    callback: React.Dispatch<React.SetStateAction<File | undefined>>;
};

function FilePictureUpload(props: IFilePictureUploadProps): React.ReactElement {
    const [nameInput, setNameInput] = React.useState<String>(null);
    const [dataUri, setDataUri] = React.useState(null);
    const [isCameraOpen, setCameraOpen] = React.useState(false);

    React.useEffect(() => {
        if (props.isOpen) {
            setCameraOpen(true);
        } else {
            setCameraOpen(false);
            setDataUri(null);
        }
    }, [props.isOpen]);

    const nameInputOnChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue: String
    ): void => {
        setNameInput(newValue);
    };

    const handleTakePhoto = (dataUri: any): void => {
        setCameraOpen(false);
        setDataUri(dataUri);
    };

    const onConfirmClicked = (): void => {
        const fileType = dataUri.type.toString().substring(dataUri.type.toString().indexOf('/') + 1);
        const fileName = nameInput ? nameInput + '.' + fileType : 'Image' + '.' + fileType;
        const imageToUpload = new File([dataUri], fileName.toString(), { type: dataUri.type });
        props.callback(imageToUpload);
        setCameraOpen(false);
        props.onDismiss();
    };

    const onReTakePictureClicked = (): void => {
        setDataUri(null);
        setCameraOpen(true);
    };

    const onRenderFooter = () => {
        return (
            <div>
                {isCameraOpen && (
                    <Stack horizontal styles={Styled.ModalButtonGroup} tokens={Styled.ModalButtonGroupStackTokens}>
                        <Stack.Item>
                            <DefaultButton text="Cancel" title="Cancel" onClick={props.onDismiss} />
                        </Stack.Item>
                    </Stack>
                )}
                {!isCameraOpen && dataUri && (
                    <Stack horizontal styles={Styled.ModalButtonGroup} tokens={Styled.ModalButtonGroupStackTokens}>
                        <Stack.Item>
                            <PrimaryButton text="Confirm" title="Confirm" onClick={onConfirmClicked} />
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton
                                text="Re-take picture"
                                title="Re-take picture"
                                onClick={onReTakePictureClicked}
                            />
                        </Stack.Item>
                        <Stack.Item>
                            <DefaultButton text="Cancel" title="Cancel" onClick={props.onDismiss} />
                        </Stack.Item>
                    </Stack>
                )}
            </div>
        );
    };

    const getModalContent = () => {
        return (
            <div>
                {isCameraOpen && (
                    <Styled.Root>
                        <Styled.CameraWrapper>
                            <Camera onCapture={handleTakePhoto} onClear={() => setDataUri(undefined)} />
                        </Styled.CameraWrapper>
                    </Styled.Root>
                )}
                {!isCameraOpen && dataUri && (
                    <Stack tokens={Styled.TextFieldStackTokens}>
                        <Stack.Item>
                            <Styled.Root>
                                <Styled.Preview>
                                    <img src={dataUri && URL.createObjectURL(dataUri)} alt="Image Preview" />
                                </Styled.Preview>
                            </Styled.Root>
                        </Stack.Item>
                        <Stack.Item>
                            <TextField
                                label="File Name"
                                placeholder="Please enter file name here"
                                onChange={nameInputOnChange}
                                validateOnLoad={false}
                            />
                        </Stack.Item>
                    </Stack>
                )}
            </div>
        );
    };

    return (
        <CoherenceModal
            isOpen={props.isOpen}
            modalWidth={'medium'}
            height={'fixed'}
            onDismiss={props.onDismiss}
            title={'Take a picture of receipt'}
            onRenderFooter={onRenderFooter}
        >
            {getModalContent()}
        </CoherenceModal>
    );
}

const connected = withContext(FilePictureUpload);
export { connected as FilePictureUpload };
