import { detailsInitialState, detailsReducerName } from './Details.reducer';
import { IDetailsAppState } from './Details.types';
import { createSelector } from 'reselect';

export const getReportId = (state: IDetailsAppState): string => {
    return state.dynamic?.[detailsReducerName]?.expenseReportId || detailsInitialState.expenseReportId;
};

export const getPostActionErrorMessage = (state: IDetailsAppState): string | null => {
    return state.dynamic?.[detailsReducerName]?.postActionErrorMessage || detailsInitialState.postActionErrorMessage;
};

const getReportIdForMemo = (_: any, expenseReportId: string): string => expenseReportId;

export const isRequestCurrentlySelected = createSelector(
    getReportId,
    getReportIdForMemo,
    (expenseReportIdInState, expenseReportId) => {
        return expenseReportIdInState === expenseReportId;
    }
);
