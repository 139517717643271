import * as React from 'react';
import { Dialog, DialogFooter, DialogType, PrimaryButton } from '@fluentui/react';

type IManageDelegatesDialogProps = {
    showDialog: boolean;
    onDismiss: () => void;
    dialogSubject: string;
    dialogText: string;
    buttonText: string;
};

export function ManageDelegatesConfirmationDialog(props: IManageDelegatesDialogProps): React.ReactElement {
    return (
        <Dialog
            hidden={!props.showDialog}
            onDismiss={props.onDismiss}
            dialogContentProps={{
                type: DialogType.normal,
                title: props.dialogSubject,
            }}
        >
            {props.dialogText}
            <DialogFooter>
                <PrimaryButton
                    onClick={props.onDismiss}
                    text={props.buttonText}
                    title={props.buttonText}
                    ariaLabel={props.buttonText}
                />
            </DialogFooter>
        </Dialog>
    );
}
