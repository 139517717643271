export enum ReceiptBankActionType {
    UPDATE_UPLOAD_PANEL_STATE = 'UPDATE_UPLOAD_PANEL_STATE',
    UPLOAD_RECEIPT = 'UPLOAD_RECEIPT',
    UPLOAD_RECEIPT_SUCCESS = 'UPLOAD_RECEIPT_SUCCESS',
    FAILED_RECEIPT_UPLOAD = 'FAILED_RECEIPT_UPLOAD',
    RESET_RECEIPT_UPLOAD_STATE = 'RESET_RECEIPT_UPLOAD_STATE',
}

export type ReceiptBankAction =
    | IUpdateUploadPanelStateAction
    | IUploadReceiptAction
    | IUploadReceiptActionSuccess
    | IFailedReceiptUploadAction
    | IResetReceiptUploadStateAction;

export interface IUpdateUploadPanelStateAction {
    type: ReceiptBankActionType.UPDATE_UPLOAD_PANEL_STATE;
    isUploadPanelOpen: boolean;
}

export interface IUploadReceiptAction {
    type: ReceiptBankActionType.UPLOAD_RECEIPT;
    receiptToUpload: File;
    clientDevice: string;
    uploadToMyExpenseFlag: string;
    scanReceiptFlag: string;
    expenseId: string,
}

export interface IReceiptMetadata {
    user: IUser;
    receipt: IAttachment;
}

export interface IAttachment {
    base64Content: string;
    name: string;
    id: string;
}

export interface IUser {
    companyName: string;
    userPrincipalName: string;
}

export interface IUploadReceiptActionSuccess {
    type: ReceiptBankActionType.UPLOAD_RECEIPT_SUCCESS;
    responseMessage: string;
}

export interface IFailedReceiptUploadAction {
    type: ReceiptBankActionType.FAILED_RECEIPT_UPLOAD;
    errorMessage: string;
}

export interface IResetReceiptUploadStateAction {
    type: ReceiptBankActionType.RESET_RECEIPT_UPLOAD_STATE;
}
