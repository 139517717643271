import * as React from 'react';
import Measure from 'react-measure';
import { useUserMedia, useCardRatio, useOffsets } from './CameraHelpers';
import * as Styled from './CameraStyling.styled';
import { withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import { PrimaryButton } from '@fluentui/react';


const CAPTURE_OPTIONS = {
    audio: false,
    video: { facingMode: "environment" }
};

function Camera(props: { onCapture: any, onClear: any }): React.ReactElement {
    const canvasRef = React.useRef(null);
    const videoRef = React.useRef(null);

    const [container, setContainer] = React.useState({ width: 0, height: 0 });
    const [isVideoPlaying, setIsVideoPlaying] = React.useState(false);
    const [isFlashing, setIsFlashing] = React.useState(false);

    const mediaStream = useUserMedia(CAPTURE_OPTIONS);
    const [aspectRatio, calculateRatio] = useCardRatio(1.586);
    const offsets = useOffsets(
        videoRef.current && videoRef.current.videoWidth,
        videoRef.current && videoRef.current.videoHeight,
        container.width,
        container.height
    );

    if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
        videoRef.current.srcObject = mediaStream;
    }

    function handleResize(contentRect: any) {
        setContainer({
            width: contentRect.bounds.width,
            height: Math.round(contentRect.bounds.width / aspectRatio)
        });
    }

    function handleCanPlay() {
        calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
        setIsVideoPlaying(true);
        videoRef.current.play();
    }

    function handleCapture() {
        const context = canvasRef.current.getContext("2d");

        context.drawImage(
            videoRef.current,
            0,
            0,
            container.width,
            container.height
        );

        canvasRef.current.toBlob(blob => props.onCapture(blob), "image/jpeg", 1);
        setIsFlashing(true);
    }

    if (!mediaStream) {
        return null;
    }

    return (
        <Measure bounds onResize={handleResize}>
            {({ measureRef }) => (
                <Styled.Wrapper>
                    <Styled.Container
                        ref={measureRef}
                        maxHeight={videoRef.current && videoRef.current.videoHeight}
                        maxWidth={videoRef.current && videoRef.current.videoWidth}
                        style={{
                            height: `${container.height}px`
                        }}
                    >
                        <Styled.Video
                            ref={videoRef}
                            hidden={!isVideoPlaying}
                            onCanPlay={handleCanPlay}
                            autoPlay
                            playsInline
                            muted
                            style={{
                                top: `-${offsets.y}px`,
                                left: `-${offsets.x}px`
                            }}
                            width={container.width}
                            height={container.height}
                        />

                        <Styled.Canvas
                            ref={canvasRef}
                            width={container.width}
                            height={container.height}
                            top={offsets.y}
                            left={offsets.x}
                        />

                        <Styled.Flash
                            flash={isFlashing}
                            onAnimationEnd={() => setIsFlashing(false)}
                        />
                    </Styled.Container>

                    {isVideoPlaying && (
                        <Styled.ButtonStyling>
                            <PrimaryButton
                                text="Take a picture"
                                title="Take a picture"
                                onClick={handleCapture}
                            />
                        </Styled.ButtonStyling>
                    )}
                </Styled.Wrapper>
            )}
        </Measure>
    );
}

const connected = withContext(Camera);
export { connected as Camera };