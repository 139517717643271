export const MyExpenseQuickLinks = [
    {
        text: 'MyExpense Tool',
        link: 'https://aka.ms/myexpense',
        target: '_blank',
    },
    {
        text: 'MyExpense Category List',
        link: 'https://microsoft.sharepoint.com/:x:/t/ExpenseMicrosoft/ESigAsOGGkNFtZ8UmepvnBUBWukFXhBTOZhomGLRfuc0Ng?e=H9bJEK',
        target: '_blank',
    },
    {
        text: 'Manage MyExpense Delegates',
        link: '/ManageDelegates',
    },
    {
        text: 'Access Stat Forms (old Expense Site)',
        link: 'https://oneexpense.azurewebsites.net/',
        target: '_blank',
    },
    {
        text: 'Employee Expense Reimbursement Policy',
        link: 'https://microsoft.sharepoint.com/sites/mspolicy/SitePages/PolicyProcedure.aspx?policyprocedureid=mspolicy-2134984086-25',
        target: '_blank',
    },
    {
        text: 'Get Help',
    },
    {
        text: 'Known Accessibility Issues',
        link: 'https://microsoft.sharepoint.com/:w:/t/ExpenseMicrosoft/EV8UbSEKrPRIpCv28dmnQsEB1IE6-qAXxeenkPFg8k71Pw?e=ajtrkZ',
        target: '_blank',
    },
    {
        text: 'Accessibility Shortcuts',
        link: 'https://docs.microsoft.com/en-us/dynamics365/fin-ops-core/fin-ops/get-started/shortcut-keys',
        target: '_blank',
    },
    {
        text: 'MS Approvals',
        link: 'https://msapprovals.microsoft.com/',
        target: '_blank',
    },
];

export const ConcurQuickLinks = [
    {
        text: 'Concur Tool',
        link: 'http://aka.ms/concur',
        target: '_blank',
    },
    {
        text: 'Concur Trainings',
        link: 'https://oneexpense.azurewebsites.net/',
        target: '_blank',
    },
    {
        text: 'Concur FAQ',
        link: 'https://oneexpense.azurewebsites.net/document/Concur%20FAQs.pdf',
        target: '_blank',
    },
    {
        text: 'Employee Expense Reimbursement Policy',
        link: 'https://microsoft.sharepoint.com/sites/mspolicy/SitePages/PolicyProcedure.aspx?policyprocedureid=mspolicy-2134984086-25',
        target: '_blank',
    },
    {
        text: 'Get Help',
    },
];

export const MSExpense2QuickLinks = [
    {
        text: 'MS Expense 2.0 Tool',
        link: 'https://aka.ms/msexpense2',
        target: '_blank',
    },
    {
        text: 'Access Stat Forms',
        link: 'https://oneexpense.azurewebsites.net/',
        target: '_blank',
    },
    {
        text: 'MS Expense 2.0 Trainings',
        link: 'https://oneexpense.azurewebsites.net/',
        target: '_blank',
    },
    {
        text: 'Employee Expense Reimbursement Policy',
        link: 'https://microsoft.sharepoint.com/sites/mspolicy/SitePages/PolicyProcedure.aspx?policyprocedureid=mspolicy-2134984086-25',
        target: '_blank',
    },
    {
        text: 'Get Help',
    },
];
