export const EXPENSE_PREFERENCES_INFO =
    'Expense Preferences settings will allow you to change the delegates for both the MyExpense tool and the automated e-mail expense report creation and submission process.';

export const SUBMITTER_DELEGATE_INFO =
    'Add a default submitter delegate in MyExpense and for automated e-mail expense report creation and submission. Delegates will be able to review your expenses and process the workflows on your behalf.';

export const APPROVER_DELEGATE_INFO =
    'Add a default approver delegate in MyExpense and for automated e-mail expense report creation and submission. Delegates will be able to review expense reports and approve them on your behalf.';

export const AUTOMATION_PREFERNCES_INFO =
    'Automation Preferences settings will allow you to customize various attributes related to the automated e-mail expense report creation and submission process.';

export const INTERIM_APPROVER_INFO =
    'Set a default interim approver for the automated e-mail expense report creation and submission. Default interim approvers will be able to review your auto-submitted expense reports prior to the final approver review.';

export const FINAL_APPROVER_INFO =
    'If your final approver should be someone outside of your direct reporting chain, change the default final approver for the automated e-mail expense report creation and submission process.';

export const USER_PREFERENCES_NOT_AVAILABLE_INFO = 
    'User Preferences are not available for your company code at this time.';

export const USER_PREFERENCES_DISCLAIMER = 
    'Users can modify their default settings for expense automation (Touchless Expense) using the fields below. Any user not currently enrolled in automation can update their settings now and they will take effect once the user is onboarded.  For more information on this feature, view the training materials on this site.';
