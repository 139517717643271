import * as React from 'react';
import * as Styled from '../../Shared/Styles/SharedLayout.styled';
import { ExpensePageMainContainer } from '../../Shared/Components/Card';
import { Context, withContext } from '@micro-frontend-react/core/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseReducerName, expenseReducer, expenseInitialState } from '../../Shared/Store/Expense.reducer';
import { IExpenseAppState } from '../../Shared/Store/Expense.types';
import { sharedExpenseSagas } from '../../Shared/Store/Expense.sagas';
import { Reducer } from 'redux';
import {
    requestMySummary,
    updateFilterValue,
    updateIsComingFromDashboard,
    updatePanelState,
} from '../../Shared/Store/Expense.actions';
import {
    getExpenseDataNotJustSubmitted,
    getFilterValue,
    getSummaryGroupedBy,
    getGroupedBySummaryMemo,
} from '../../Shared/Store/Expense.selectors';
import { DetailsAdaptive } from '../ExpenseDetails/DetailsAdaptive';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { ExpenseCards } from './ExpenseCard/ExpenseCards';
import { DetailCardContainer } from '../../Shared/Components/Card';
import * as _ from 'lodash';
import { DetailsPanel } from '../ExpenseDetails/DetailsPanel';
import { FLYOUT_VIEW } from '../../Shared/SharedConstants';
import { GroupingBy } from '../../Shared/GroupingBy';
import { requestMyDetails, updateMyRequest } from '../ExpenseDetails/Details.actions';
import { IconButton, PrimaryButton } from '@fluentui/react';
import { Spinner } from '@fluentui/react/lib/Spinner';
import { IGrouping } from '../../Helpers/groupingSummary';
import { createSelector } from 'reselect';

interface IExpensePageProps {
    winHeight: number;
    queryStatus: string;
    queryReportId: string;
    routerHistory: any;
    routerLocation: any;
    windowWidth: number;
}

function ExpensePage(props: IExpensePageProps): React.ReactElement {
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const {
        isLoadingSummary,
        isPanelOpen,
        hasError,
        summaryErrorMessage,
        detailsDefaultView,
        maxDetailsScreen,
        continuationToken,
        continuationTokenDraft,
        isComingFromDashboard,
    } = useSelector((state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState);

    const { winHeight, queryStatus, queryReportId, routerHistory, routerLocation, windowWidth } = props;

    const summaryGroupedBy = useSelector(getSummaryGroupedBy);
    const filterValue = useSelector(getFilterValue);
    const expenseSummaryData = useSelector(getExpenseDataNotJustSubmitted);

    // removes the submitted expense reports
    const groupedSummaryDataSelector = createSelector(
        [getExpenseDataNotJustSubmitted, getSummaryGroupedBy],
        getGroupedBySummaryMemo
    );
    const groupedSummaryData: any = useSelector((state: IExpenseAppState) => groupedSummaryDataSelector(state));

    const defaultExpanded = [
        {
            key: 'Draft',
            expanded: true,
        },
        {
            key: 'In Review',
            expanded: true,
        },
    ];

    const [expandedList, setExpandedList] = React.useState(defaultExpanded);

    React.useEffect(() => {
        if (isComingFromDashboard) {
            dispatch(updateIsComingFromDashboard(false));
        } else {
            dispatch(updateFilterValue('All'));
        }
    }, [routerHistory, routerLocation]);

    React.useEffect(() => {
        if (!expenseSummaryData) {
            dispatch(requestMySummary(null, null, true, 'Expenses'));
        }
    }, [dispatch]);

    function filterSummary(organizedSummary: any): IGrouping[] {
        if (filterValue === 'All') {
            return organizedSummary;
        } else {
            return organizedSummary.filter(
                (summaryItem: { displayValue: any }) => summaryItem.displayValue === filterValue
            );
        }
    }

    React.useEffect(() => {
        if (queryReportId && queryReportId !== '') {
            const actionType = queryStatus == 'Draft' ? 'editAction' : 'view';
            dispatch(updateMyRequest(queryReportId, actionType, queryStatus));
            dispatch(updatePanelState(true));
            dispatch(requestMyDetails(queryReportId, queryStatus));
        }
    }, [dispatch, queryStatus, queryReportId]);

    React.useEffect(() => {
        setExpandedList(defaultExpanded);
    }, [expenseSummaryData]);

    function viewMoreExpenses() {
        dispatch(requestMySummary(continuationToken, continuationTokenDraft, false, 'Expenses'));
    }

    React.useEffect(() => {
        if (!isExpanded(filterValue)) {
            handleToggleAccordon(filterValue);
        }
    }, [filterValue]);

    function handleToggleAccordon(key: any) {
        let newList = [];
        if (isKeyExistsInExpanded(key)) {
            newList = expandedList.map((l) => {
                if (key === l.key) {
                    l.expanded = !l.expanded;
                }
                return l;
            });
        } else {
            newList = [...expandedList];
            newList.push({
                key,
                expanded: true,
            });
        }

        setExpandedList(newList);
    }

    function isKeyExistsInExpanded(key: any) {
        return expandedList.some((l) => l.key == key);
    }

    function isExpanded(key: any) {
        return expandedList.some((l) => l.key == key && l.expanded);
    }

    function renderSummary() {
        const organizedSummaryData = filterSummary(groupedSummaryData);
        switch (summaryGroupedBy) {
            case GroupingBy.Status:
                return (
                    <div className="ms-Grid" dir="ltr">
                        {organizedSummaryData.map((g) => {
                            const accessibleLabel = `${isExpanded(g.key) ? 'Collapse' : 'Expand'} ${
                                g.displayValue
                            } section`;
                            return (
                                <React.Fragment key={g.key}>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <Styled.SectionTitle>
                                                <IconButton
                                                    iconProps={{
                                                        iconName: isExpanded(g.key)
                                                            ? 'ChevronDownMed'
                                                            : 'ChevronRightMed',
                                                    }}
                                                    title={accessibleLabel}
                                                    ariaLabel={accessibleLabel}
                                                    onClick={(): void => {
                                                        handleToggleAccordon(g.key);
                                                    }}
                                                />
                                                {g.displayValue}
                                            </Styled.SectionTitle>
                                        </div>
                                    </div>
                                    {isExpanded(g.key) && (
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <ExpenseCards
                                                    componentContext={Context}
                                                    expenseData={g.grouping}
                                                ></ExpenseCards>
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                );
                break;
            case GroupingBy.Date:
                return (
                    <div className="ms-Grid" dir="ltr">
                        {organizedSummaryData.map((g) => {
                            return (
                                <React.Fragment key={g.displayValue}>
                                    <div className="ms-Grid-col">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <Styled.SectionTitle>
                                                {g.displayValue &&
                                                    g.displayValue.replace(/\w+/g, function (currExpStatus) {
                                                        return (
                                                            currExpStatus[0].toUpperCase() +
                                                            currExpStatus.slice(1).toLowerCase()
                                                        );
                                                    })}
                                                {!g.displayValue && <div>N/A</div>}
                                            </Styled.SectionTitle>
                                        </div>
                                    </div>
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                            <ExpenseCards
                                                componentContext={Context}
                                                expenseData={g.grouping}
                                            ></ExpenseCards>
                                        </div>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                    </div>
                );
        }
    }
    return (
        <div>
            <div className="ms-Grid" dir="ltr">
                <div className="ms-Grid-row">
                    <div
                        className={
                            'ms-Grid-col ' +
                            (isPanelOpen && detailsDefaultView !== FLYOUT_VIEW
                                ? maxDetailsScreen
                                    ? ' ms-sm4 ms-xl4 ms-xxl4 ms-xxxl4 ms-hiddenLgDown horizontal-separator'
                                    : ' ms-sm7 ms-hiddenLgDown horizontal-separator'
                                : ' ms-sm12 ')
                        }
                    >
                        <Styled.ExpensePageContainer>
                            {isLoadingSummary && (
                                <Styled.SpinnerContainer>
                                    <Spinner label="Loading expenses..." />
                                </Styled.SpinnerContainer>
                            )}
                            {!isLoadingSummary && groupedSummaryData && groupedSummaryData.length > 0 && (
                                <ExpensePageMainContainer
                                    winHeight={winHeight}
                                    className="scroll-hidden v-scroll-auto custom-scrollbar"
                                >
                                    {renderSummary()}
                                    {(continuationToken || continuationTokenDraft) && (
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12">
                                                <Styled.ViewMoreContainer>
                                                    <PrimaryButton
                                                        text="Load More Expenses"
                                                        title="Load More Expenses"
                                                        onClick={viewMoreExpenses}
                                                    />
                                                </Styled.ViewMoreContainer>
                                            </div>
                                        </div>
                                    )}
                                </ExpensePageMainContainer>
                            )}
                            {!isLoadingSummary &&
                                !hasError &&
                                (!groupedSummaryData || groupedSummaryData.length == 0) && (
                                    <Styled.NoDataFoundMessage>No expense records found</Styled.NoDataFoundMessage>
                                )}
                            {!isLoadingSummary && hasError && summaryErrorMessage && (
                                <Styled.ApiErrorMessage>{summaryErrorMessage}</Styled.ApiErrorMessage>
                            )}
                        </Styled.ExpensePageContainer>
                    </div>

                    {isPanelOpen && detailsDefaultView !== FLYOUT_VIEW && (
                        <div
                            className={
                                ' ms-Grid-col ms-sm12 ms-md12 ms-lg12' +
                                (maxDetailsScreen ? ' ms-xl8 ms-xxl8 ms-xxxl8' : ' ms-xl5')
                            }
                        >
                            <DetailCardContainer
                                winHeight={winHeight}
                                className="scroll-hidden v-scroll-auto custom-scrollbar"
                            >
                                <DetailsAdaptive
                                    componentContext={Context}
                                    routerHistory={routerHistory}
                                    routerLocation={routerLocation}
                                    windowWidth={windowWidth}
                                    windowHeight={winHeight}
                                ></DetailsAdaptive>
                            </DetailCardContainer>
                        </div>
                    )}
                    {detailsDefaultView === FLYOUT_VIEW && <DetailsPanel />}
                </div>
            </div>
        </div>
    );
}

const connected = withContext(ExpensePage);
export { connected as ExpensePage };
