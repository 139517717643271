import * as React from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Reducer } from 'redux';
import { Context } from '@micro-frontend-react/core/lib/Context';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { expenseReducerName, expenseReducer, expenseInitialState } from '../../../../Shared/Store/Expense.reducer';
import * as Styled from './CardHelpers/CardStyling.styled';
import * as SharedLayout from '../../../../Shared/Styles/SharedLayout.styled';
import * as CardHelpers from './CardHelpers/CardHelpers';
import { Dialog, DialogFooter, IconButton, DefaultButton, DialogType, Spinner, Text } from '@fluentui/react';
import { IExpenseAppState } from '../../../../Shared/Store/Expense.types';
import { submitExpenseDetails } from '../../../ExpenseDetails/Details.actions';
import { IExpenseSubmissionRequest } from '../../../ExpenseDetails/Details.action-types';
import { CountBanner } from './CardHelpers/CountBanner';
import { detailsReducer, detailsReducerName } from '../../../ExpenseDetails/Details.reducer';
import { detailsSagas } from '../../../ExpenseDetails/Details.sagas';
import { getFilteredDrafts } from '../../../../Shared/Store/Expense.selectors';
import {
    requestMySummary,
    updateDeletedReports,
    updateFilterValue,
    updateIsComingFromDashboard,
} from '../../../../Shared/Store/Expense.actions';
import { useHistory } from 'react-router-dom';
import ErrorResult from '../../../../Shared/Components/ErrorResult';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

const dialogContentProps: { type: number; title: string; subText?: string } = {
    type: DialogType.normal,
    title: 'Delete draft',
};

const NUM_TO_DISPLAY = 4;

export function AutomatedExpensesCard(): React.ReactElement {
    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    useDynamicReducer(detailsReducerName, detailsReducer as Reducer, [detailsSagas]);
    const { isLoadingSummary, isLoadingStats, expenseCounts, summaryErrorMessage } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );
    const filteredDrafts = useSelector(getFilteredDrafts);
    const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
    const [selectedDraft, setSelectedDraft] = React.useState(null);

    React.useEffect(() => {
        if (!filteredDrafts) {
            dispatch(requestMySummary(null, null, true, 'Dashboard'));
        }
    }, [dispatch]);

    const history = useHistory();

    const deleteRow = (header: any) => {
        const submitRequest: IExpenseSubmissionRequest = {
            id: header.reportId,
            header: {
                submitterComments: header.submitterComments,
                reportName: header.reportName,
                description: header.description,
                interimApprovers: '',
                isAntiCorruption: header.isAntiCorruption,
                preApprovalNumber: header.preApprovalNumber,
            },
            lines: [],
        };
        if (!submitRequest?.header?.reportName) {
            dialogContentProps.subText = 'Are you sure you would like to delete this draft expense?';
        }
        setSelectedDraft(submitRequest);
        setShowDeleteDialog(true);
    };

    const editRow = (header: any) => {
        const id = header.reportId;
        history.push(`/ExpenseDashboard/Draft/${id}`);
    };

    const renderRow = (header: any) => {
        return (
            <Stack.Item>
                <Styled.ExpenseTileRow windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
                    <Styled.ReportColumn>
                        <Styled.TitleText>{header.reportName}</Styled.TitleText>
                        <Styled.UnitValueRow>
                            {header.totalAmount && <Styled.UnitValue>{header.totalAmount}</Styled.UnitValue>}
                            {header.transactionCurrency && (
                                <Styled.UnitofMeasure>{header.transactionCurrency}</Styled.UnitofMeasure>
                            )}
                        </Styled.UnitValueRow>
                    </Styled.ReportColumn>

                    <Styled.IconSection>
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            onClick={() => {
                                editRow(header);
                            }}
                            ariaLabel={`Edit report named ${header.reportName}`}
                        />
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            onClick={() => {
                                deleteRow(header);
                            }}
                            ariaLabel={`Delete report named ${header.reportName}`}
                        />
                    </Styled.IconSection>
                </Styled.ExpenseTileRow>
            </Stack.Item>
        );
    };

    const deleteExpense = (data: any) => {
        dispatch(submitExpenseDetails(data, true));
        if (data?.id) {
            dispatch(updateDeletedReports(data.id));
        }
        setShowDeleteDialog(false);
    };

    const renderDrafts = () => {
        return (
            <Stack tokens={{ childrenGap: 10 }}>
                {filteredDrafts?.slice(0, NUM_TO_DISPLAY)?.map((item: any) => renderRow(item))}
            </Stack>
        );
    };

    const retryGettingExpenses = () => {
        dispatch(requestMySummary(null, null, true));
    };

    return (
        <Styled.LargeCard windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
            {CardHelpers.getHeader('Automated Expenses')}
            <SharedLayout.Divider />
            <Styled.CardBody windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
                {expenseCounts && !isLoadingStats && (
                    <CountBanner
                        countValue={expenseCounts.draftCount}
                        title="Automated expense reports"
                        imageUrl="/images/automated-expense.svg"
                    />
                )}
                <Styled.AfterBanner windowHeight={window.innerHeight} windowWidth={window.innerWidth}>
                    {filteredDrafts && !isLoadingSummary && expenseCounts && expenseCounts.draftCount > 0 && (
                        <Styled.AutomatedExpenseContainer>{renderDrafts()}</Styled.AutomatedExpenseContainer>
                    )}
                    {filteredDrafts && !isLoadingSummary && expenseCounts && expenseCounts.draftCount == 0 && (
                        <Styled.NoExpensesText>You don't have any expense reports yet</Styled.NoExpensesText>
                    )}
                    {isLoadingSummary && (
                        <SharedLayout.SpinnerContainer>
                            <Spinner label="Loading expenses" />
                        </SharedLayout.SpinnerContainer>
                    )}
                    {summaryErrorMessage && !isLoadingSummary && (
                        <Styled.ErrorInAutomatedCardContainer
                            windowHeight={window.innerHeight}
                            windowWidth={window.innerWidth}
                        >
                            <ErrorResult message="An error occured when fetching expenses" />
                            <DefaultButton
                                style={{ marginTop: '14px' }}
                                onClick={() => retryGettingExpenses()}
                                text="Retry"
                            />
                        </Styled.ErrorInAutomatedCardContainer>
                    )}
                </Styled.AfterBanner>
            </Styled.CardBody>
            <Styled.CardFooter>
                {!isLoadingSummary &&
                    !summaryErrorMessage &&
                    expenseCounts &&
                    expenseCounts.draftCount > 0 &&
                    !isLoadingStats && (
                        <DefaultButton
                            title="View all draft reports"
                            text="View all draft reports"
                            onClick={(): void => {
                                dispatch(updateFilterValue('Draft'));
                                dispatch(updateIsComingFromDashboard(true));
                                history.push('/ExpenseDashboard');
                            }}
                        />
                    )}
            </Styled.CardFooter>
            <Dialog
                hidden={!showDeleteDialog}
                onDismiss={() => setShowDeleteDialog(false)}
                dialogContentProps={dialogContentProps}
            >
                {selectedDraft?.header?.reportName && (
                    <Text>
                        Are you sure you would like to delete the draft expense named{' '}
                        <b>{selectedDraft.header.reportName}</b>?
                    </Text>
                )}
                <DialogFooter>
                    <DefaultButton
                        onClick={() => {
                            deleteExpense(selectedDraft);
                        }}
                        text="Delete"
                        title="Delete"
                        ariaLabel="Delete report"
                        styles={{ root: { backgroundColor: '#0078D4' }, rootHovered: { backgroundColor: '#0078D4' } }}
                        primary
                    />
                    <DefaultButton
                        onClick={() => setShowDeleteDialog(false)}
                        text="Cancel"
                        title="Cancel"
                        ariaLabel="Cancel"
                    />
                </DialogFooter>
            </Dialog>
        </Styled.LargeCard>
    );
}
