import { Depths, FontSizes, FontWeights } from '@fluentui/react';
import { mergeStyleSets } from '@fluentui/react';
import { minWidth } from '../../../Styles/Media';

export const TextContainer = styled.div`
    height: 100%;
    margin-left: 12px;
    margin-right: 12px;
    padding-top: 1%;
    padding-bottom: 1%
    position: relative;
`;

export const Date = styled.div`
    font-size: ${FontSizes.size12};
`;

export const DateRow = styled.div`
    height: 22.5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5%;
`;
export const UnitValueRow = styled.div`
    height: 37.5%;
    padding-top: 1%;
    display: flex;
    flex-direction: row;
    align-items: top;
`;

export const UnitValue = styled.div`
    font-size: 26px;
    font-weight: ${FontWeights.regular};
    padding-top: 0.5%;
    padding-bottom: 0.5%;
    margin-right: 5px;
    max-width: 70%;
`;

export const UnitofMeasure = styled.div`
    font-size: ${FontSizes.size14};
    padding-top: 3%;
    max-width: 30%;
`;

export const DisplayDocumentNumber = styled.div`
    height: 15%;
    padding-top: 0.5%;
    font-size: ${FontSizes.size14};
    font-weight: ${FontWeights.regular};
`;

export const Header = styled.div`
    padding-top: 2%;
    padding-bottom: 0%;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
`;

export const HeaderIcons = styled.div`
    align-items: center;
    display: flex;
    flex-direction row;
    justify-content: flex-end;
    width: 15%;
`;

export const Styles = mergeStyleSets({
    text: {
        fontWeight: 'bold',
        textOverflow: 'ellipsis',
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden'
    }
});

export const failedIconStyle = {
    color: '#A80000'
};

export const Footer = styled.div`
    padding-top: 2%;
    padding-bottom: 2%;
    flex-direction: row;
    display: flex;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

export const StatusImageIconStyle = {
    height: '18px',
    width: '18px'
};

// Originally from Card.ts
export const CardContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
    
    margin-right: calc(3vw);
    margin-bottom: calc(0vw);

    ${minWidth.xl} {
        
        margin-right: calc(4vw - 32px);
    }
`;

export const Card = styled.div`
    background-color: #ffffff;
    border-radius: 2px;
    box-shadow: ${Depths.depth4};
    margin-bottom: 16px;
    margin-left: 16px;
    margin-right: 16px;
    padding-right: 20px;
    width: 100%;
    height: 20%;
    transition: box-shadow 0.3s;
    &:hover {
        box-shadow: ${Depths.depth64};
    }
    max-width: 300px;
    min-width: 250px;
    cursor: pointer;
    box-shadow: ${props => (props.isSelected ? Depths.depth64 : 'none')};

    ${minWidth.xl} {
        margin-bottom: 24px;
        margin-left: 12px;
        margin-right: 12px;
        padding-right: 0px !important;
    }
    height: 150px;
`;

export const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    height: 25%;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 12px;
`;

export const CardBody = styled.div`
    height: 75%;
`;

export const HeaderTitleContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction row;
    justify-content: flex-start;
    width: 85%;
    font-weight: normal;
`;

export const Title = styled.div`
    height: 15%;
    font-weight: ${FontWeights.semibold};
    padding-bottom: 0.5%;
`;

export const StrongHeaderTitle = styled.strong`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;