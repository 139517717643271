import * as React from 'react';
import { Reducer } from 'redux';
import { IconButton } from '@fluentui/react';
import { useDynamicReducer } from '@micro-frontend-react/redux/lib/useDynamicReducer';
import { Context, withContext } from '@micro-frontend-react/employee-experience/lib/Context';
import * as Styled from './DetailsButtonsStyled';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { expenseInitialState, expenseReducer, expenseReducerName } from '../../../Shared/Store/Expense.reducer';
import { IExpenseAppState } from '../../../Shared/Store/Expense.types';
import { sharedExpenseSagas } from '../../../Shared/Store/Expense.sagas';
import { ToggleDetailsScreenMax } from '../../../Shared/Store/Expense.actions';
import { detailsInitialState, detailsReducerName } from '../Details.reducer';
import { IDetailsAppState } from '../Details.types';
import { breakpointMap } from '../../../Styles/Media';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';

function MaximizeButton(props: { callbackOnMaximizeToggle?(): void }): React.ReactElement {
    useDynamicReducer(expenseReducerName, expenseReducer as Reducer, [sharedExpenseSagas]);

    const { useSelector, dispatch } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const { maxDetailsScreen } = useSelector(
        (state: IExpenseAppState) => state.dynamic?.[expenseReducerName] || expenseInitialState
    );

    const { expenseReportId } = useSelector(
        (state: IDetailsAppState) => state.dynamic?.[detailsReducerName] || detailsInitialState
    );

    let maximizeButtonRef: any = null;

    React.useEffect(() => {
        if (maximizeButtonRef && expenseReportId && window.innerWidth > breakpointMap.xl) {
            maximizeButtonRef.focus();
        }
    }, [expenseReportId, maximizeButtonRef]);

    const iconName = maxDetailsScreen ? 'ChromeRestore' : 'FullScreen';
    const title = maxDetailsScreen ? 'Restore' : 'Maximize';

    return (
        <TooltipHost
            content={`${title}`}
            calloutProps={Styled.tooltipCalloutProps}
            styles={Styled.tooltipHostContainer}
        >
            <IconButton
                className="ms-hiddenLgDown"
                iconProps={{ iconName }}
                ariaLabel={`Click here to ${title} the details screen`}
                componentRef={(input) => (maximizeButtonRef = input)}
                style={Styled.DetailActionIcon}
                onClick={(): void => {
                    dispatch(ToggleDetailsScreenMax());
                    if (props.callbackOnMaximizeToggle) {
                        props.callbackOnMaximizeToggle();
                    }
                }}
            />
        </TooltipHost>
    );
}

const connected = withContext(MaximizeButton);
export { connected as MaximizeButton };
